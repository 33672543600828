import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';

export const getAlerts = (params) => {
  return axios(`${API_URL}/admin/employee_alerts`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getUnreadedAlerts = () => {
  return axios(`${API_URL}/admin/employee_alerts_unread`).catch((error) =>
    errorHandler(error),
  );
};

export const updateAlert = (id, data) => {
  return axios.put(`${API_URL}/admin/employee_alerts/read_flag/${id}`, data);
};
