import React from 'react';
import { Input, Row, Col, Divider, Tooltip } from 'antd';
import SearchOptions from 'src/utils/SearchOptions';
import { useStorage } from 'src/hooks/useStorage';
import DropdownSelect from 'src/lib/DropdownSelect';
import { RBAC, ROLES } from 'src/lib/rbac';

const tw_type_options = [
  { value: '', label: 'Svi' },
  { value: 'regular', label: 'Redovan' },
  { value: 'irregular', label: 'Vanredan' },
];

const tw_status_options = [
  { value: '', label: 'Svi' },
  { value: 'pending', label: 'Nov' },
  { value: 'approved', label: 'Odobren' },
  { value: 'disapproved', label: 'Odbijen' },
  { value: 'canceled', label: 'Poništen' },
  { value: 'in_progress', label: 'Aktivan' },
  { value: 'finished', label: 'Završen' },
];

const SearchFilters = ({ setTerm, setPage }) => {
  const {
    storage,
    searchTermExist,
    travelWarrantsTerm,
    initialTerm,
  } = useStorage();
  const current_page = parseInt(storage.get('page')) || 1;
  const [data, setData] = React.useState(initialTerm);

  const checkIfEmpty = (data) => {
    let val = true;
    Object.values(data).map((item) => {
      if (item) val = false;
    });
    return val;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkIfEmpty(data)) return;

    setPage(1);
    setTerm({ ...data });
    storage.set('search', JSON.stringify({ ...data }));
  };

  const handleChange = (obj_1, obj_2) => {
    let name;
    let value;

    if (obj_1.hasOwnProperty('target')) {
      name = obj_1.target.name;
      value = obj_1.target.value;
    } else {
      name = obj_2.name;
      value = obj_1.value;
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const clearInputs = () => {
    if (!searchTermExist) return;
    setTerm(travelWarrantsTerm);
    setData(travelWarrantsTerm);
    setPage(current_page);
    storage.remove('search');
  };

  const formName = 'travelWarrantsSearch';

  return (
    <form id={formName} onSubmit={handleSubmit} className="w-full lg:w-auto">
      {/* reqular form insted of antd form due to double requests on page 2 */}
      <Row gutter={[8, 8]} justify="center" align="middle">
        <Col md={5} sm={12} xs={12}>
          <Tooltip title="Ime i prezime vozača" className="w-full" color="#666" >
            <Input
              name="driver_name"
              onChange={handleChange}
              placeholder="Unesite ime i prezime"
              value={data.driver_name || ''}
            />
          </Tooltip>
        </Col>

        <Col md={5} sm={12} xs={12}>
          <Tooltip title="Registracija vozila" className="w-full" color="#666" >
            <Input
              name="vehicle_name"
              onChange={handleChange}
              placeholder="Unesite registraciju"
              value={data.vehicle_name || ''}
            />
          </Tooltip>
        </Col>

        <Col md={5} sm={24} xs={24}>
          <Tooltip title="Broj naloga" className="w-full" color="#666" >
            <Input
              name="travel_warrant_id"
              onChange={handleChange}
              placeholder="Unesite broj naloga"
              value={data.travel_warrant_id || ''}
            />
          </Tooltip>
        </Col>

        <Col md={3} sm={12} xs={12}>
          <Tooltip title="Tip" className="w-full" color="#666" >
            <div className="dropdown-select-wrapper">
              <DropdownSelect
                name="travel_warrant_type"
                onChange={handleChange}
                value={tw_type_options.filter((option) => {
                  return option.value === data.travel_warrant_type;
                })}
                options={tw_type_options}
                className="hover:border-red-500"
              />
            </div>
          </Tooltip>
        </Col>
        <RBAC allowedRoles={[ROLES.ADMIN_JKP]} >
          <Col md={3} sm={12} xs={12}>
            <Tooltip title="Status" className="w-full" color="#666" >
              <div className="dropdown-select-wrapper">
                <DropdownSelect
                  name="travel_warrant_status"
                  onChange={handleChange}
                  value={tw_status_options.filter((option) => {
                    return option.value === data.travel_warrant_status;
                  })}
                  options={tw_status_options}
                />
              </div>
            </Tooltip>
          </Col>
        </RBAC>
        <Col xl={3} sm={24} xs={24} className="flex justify-center">
          <SearchOptions formName={formName} onClear={clearInputs} />
        </Col>
        <Col xl={0} sm={24} xs={24}>
          <Divider />
        </Col>
      </Row>
    </form>
  );
};

export default SearchFilters;
