import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getUsers = (params) => {
  return axios(`${API_URL}/admin/users`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getUser = (id) => {
  return axios(`${API_URL}/admin/users/${id}`).catch((error) => errorHandler(error));
};

export const createUser = (data) => {
  return axios.post(`${API_URL}/admin/users`, data);
};

export const updateUser = ({ id, data }) => {
  return axios.put(`${API_URL}/admin/users/${id}`, data);
};

export const deleteUser = (id) => {
  return axios.delete(`${API_URL}/admin/users/${id}`);
};
