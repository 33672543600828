import React, { useState, useEffect } from 'react';

const DashboardContext = React.createContext();

const DashboardProvider = ({ children, menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const collapseMenu = () => {
    if (window.innerWidth < 1024) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', collapseMenu);

    return () => {
      window.removeEventListener('resize', collapseMenu);
    };
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        toggleMenu,
        isMenuOpen,
        menuItems,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => {
  const ctx = React.useContext(DashboardContext);

  if (!ctx)
    throw Error('The useDashboard hook is being used outside its provider.');

  return ctx;
};

export { DashboardProvider, useDashboard };
