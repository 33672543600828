import React from 'react';
import { Table, Collapse } from 'antd';
import { options } from 'src/utils/tableConfig';
import HistoryForm from './HistoryForm';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const columns = [{ title: 'Registracija', dataIndex: 'licence_plate' }];

const InfoBox = ({ data, vehicle, ids, setIds, setHistory, clearMap }) => {
  console.log('Infobox ', data);
  return (
    <div className="map-table absolute top-2 left-2 z-10 bg-black bg-opacity-10 p-2 w-full max-w-xs">
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{ maxHeight: '85vh', overflowY: 'auto' }}>
        <Panel header="Izaberi vozilo">
          <Table
            {...options}
            scroll={false}
            rowSelection={{
              onChange: (key, record) => setIds(record.map((r) => r.id)),
              selectedRowKeys: ids, // deselect vehicle
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />

          {vehicle.length > 0 && (
            <HistoryForm
              vehicle={vehicle}
              setHistory={setHistory}
              clearMap={clearMap}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default InfoBox;
