import { Button } from 'antd';
import React from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { SelectElement as Select } from 'src/components/Form/Select';
import { DateInput } from 'src/components/Form/DateInput';
import { queryClient } from 'src/lib/react-query';
import { createVehicle } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import {
  errorClassNames,
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const CreateVehicleForm = () => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(createVehicle);
  const formMethods = useForm();
  const [error, setError] = React.useState([]);

  const handleSubmit = async (data) => {
    const firstRegistration = data.first_registration ? moment(data.first_registration).format('YYYY-MM-DD') : '';
    const registrationEnd = data.registration_end ? moment(data.registration_end).format('YYYY-MM-DD') : '';

    data.first_registration = firstRegistration;
    data.registration_end = registrationEnd;
    
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries('vehicles');
      formMethods.reset();
      openNotificationWithIcon('success');
      setError('');
    } catch (error) {
      errorHandler(error, (err) => setError(err));
    }
  };

  const formName = 'createVehicle';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={
        <Button className="text-white bg-blue-500 xs:w-full sm:w-full lg:w-full">Dodaj vozilo</Button>
      }
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Dodaj
        </Button>
      }
      title="Dodaj vozilo">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="unique_code"
          label={<>{requiredFieldIcon} Garažni broj</>}
          type="number"
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
          className={error.includes('unique_code') ? errorClassNames : ''}
        />

        <Input
          name="licence_plate"
          label={<>{requiredFieldIcon} Registarski broj</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
          className={error.includes('licence_plate') ? errorClassNames : ''}
        />

        <Input name="type" label="Tip vozila" />
        <Input name="purpose" label="Namena vozila" />
        <Input name="manufacturer" label="Marka vozila" />
        <Select name="office"
                label={<>{requiredFieldIcon} Pripadnost direkciji</>}
                options={[{label: 'AB', value: 'ab'}, {label: 'MK', value: 'mk'}]}
                rules={{
                  required: {
                    value: true,
                    message: 'Obavezno polje',
                  },
                }}
                labelInValue/>
        <DateInput name="first_registration" 
                   defaultTime={false} 
                   showTime={false}
                   label="Datum prve registracije"
                   format="YYYY-MM-DD"
                   enableDates={true}/>
        <DateInput name="registration_end" 
                   defaultTime={false} 
                   showTime={false}
                   label="Datum isteka registracije"
                   format="YYYY-MM-DD"
                   enableDates={true}/>
        <Input name="net_weight" label="Masa vozila" />
        <Input name="load_capacity" label="Dozvoljena nosivost" />

        <Input
          name="gps_device_id"
          label="ID GPS uređaja"
          type="number"
          className={error.includes('gps_device_id') ? errorClassNames : ''}
        />
      </Form>
    </FormDrawer>
  );
};

export default CreateVehicleForm;
