import { initReactQueryAuth } from 'react-query-auth';
import openNotificationWithIcon from 'src/utils/notification';
import Loader from 'src/utils/Loader';

import {
  loginWithEmailAndPassword,
  getUserProfile,
  registerWithEmailAndPassword,
} from 'src/features/auth/api';

import storage from 'src/utils/storage';

async function handleUserResponse(data) {
  const { jwt, ...user } = data;
  storage.setToken(jwt);
  return user;
}

async function loadUser() {
  let user = null;

  if (storage.getToken()) {
    const data = await getUserProfile();
    user = data.user;
  }
  return user;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  openNotificationWithIcon('success', { message: 'Uspešno ste se ulogovali.' });
  return user;
}

async function registerFn(data) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  await storage.clearToken();
  window.sessionStorage.clear();
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  renderLoader: () => <Loader />,
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
