import React from 'react';

export const ListHeading = ({ left, right, title, classNames}) => {
  return (
    <div className={classNames && classNames.main ? classNames.main : "flex justify-between items-center mb-2"}>
      <div className={classNames && classNames.leftContainer ? classNames.leftContainer : "md:flex"}>
        <div className={classNames && classNames.title ? classNames.title : "text-2xl"}>{title}</div>
        {left && <div className={classNames && classNames.left ? classNames.left : "md:ml-5 md:my-0 my-2"}>{left}</div>}
      </div>

      {right && <div className={classNames && classNames.right ? classNames.right : ""}>{right}</div>}
    </div>
  );
};
