import { Button } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import { createUser } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import { SelectElement } from 'src/components/Form/Select';
import {
  errorClassNames,
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const CreateUserForm = ({oldData}) => {
  const {mutateAsync, isLoading, isSuccess } = useMutation(createUser);
  const formMethods = useForm();
  const [error, setError] = React.useState([]);

  const handleSubmit = async (data) => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries('users');
      formMethods.reset();
      openNotificationWithIcon('success');
      setError('');
    } catch (error) {
      errorHandler(error, (err) => setError(err));
    } 
  };

  const roleOptions = [
    { value: 'admin_jkp', label: 'Admin' },
    { value: 'controller', label: 'Kontrolor' },
    // { value: 'service', label: 'Serviser' },
    // { value: 'driver', label: 'Vozač' },
  ];

  const formName = 'createUser';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={
        <Button className="text-white bg-blue-500 xs:w-full sm:w-full">Dodaj korisnika</Button>
      }
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Dodaj
        </Button>
      }
      title="Dodaj korisnika">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="name"
          label={<>{requiredFieldIcon} Ime i prezime</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input
          name="email"
          type="email"
          label={<>{requiredFieldIcon} Email</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Email nije validan!',
            },
          }}
          className={error.includes('email') ? errorClassNames : ''}
        />

        <Input
          name="password"
          label={<>{requiredFieldIcon} Šifra</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
            minLength: {
              value: 8,
              message: 'Broj karaktera ne sme biti manji od 8!',
            },
          }}
          type="password"
        />

        <Input
          name="password_confirmation"
          label={<>{requiredFieldIcon} Potvrdi šifru</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
            validate: (value) =>
              value === formMethods.watch('password') ||
              'Lozinke se ne poklapaju!',
          }}
          type="password"
        />

        <SelectElement
          name="role"
          label={<>{requiredFieldIcon} Rola</>}
          options={roleOptions}
          labelInValue
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />
      </Form>
    </FormDrawer>
  );
};

export default CreateUserForm;
