import React from 'react';
import { Tag } from 'antd';

const ActionStatus = (props) => {
  let action = '';

  switch (props.action) {
    case 'CREATE':
      action = <Tag className="bg-green-300">Kreiranje</Tag>;
      break;
    case 'UPDATE':
      action = <Tag className="bg-blue-300">Izmena</Tag>;
      break;
    case 'DELETE':
      action = <Tag className="bg-red-300">Brisanje</Tag>;
      break;
    default:
      action = '';
  }

  return action;
};

export default ActionStatus;
