import { Button } from 'antd';
import React from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { SelectElement as Select } from 'src/components/Form/Select';
import { DateInput } from 'src/components/Form/DateInput';
import { queryClient } from 'src/lib/react-query';
import { updateVehicle } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import {
  errorClassNames,
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const UpdateVehicleForm = ({ vehicle }) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(updateVehicle);
  const formMethods = useForm();
  const [error, setError] = React.useState([]);

  // React.useEffect(() => {
  //   formMethods.reset({...vehicle, registration_end: moment(vehicle.registration_end).format('YYYY-MM-DD'), first_registration: moment(vehicle.first_registration).format('YYYY-MM-DD')});
  // }, [vehicle]);

  const handleSubmit = async (data) => {
    const firstRegistration = data.first_registration ? moment(data.first_registration).format('YYYY-MM-DD') : '';
    const registrationEnd = data.registration_end ? moment(data.registration_end).format('YYYY-MM-DD') : '';

    data.first_registration = firstRegistration;
    data.registration_end = registrationEnd;
    
    try {
      await mutateAsync({ data, id: vehicle.id });
      queryClient.invalidateQueries('vehicles');
      queryClient.invalidateQueries('vehicle');
      formMethods.reset();
      openNotificationWithIcon('success');
      setError('');
    } catch (error) {
      errorHandler(error, (err) => setError(err));
    }
  };

  const formName = 'updateVehicle';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={<Button className="text-white bg-blue-500 xs:w-full sm:w-full">Izmeni</Button>}
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Izmeni
        </Button>
      }
      title="Izmeni podatke">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="unique_code"
          label={<>{requiredFieldIcon} Garažni broj</>}
          type="number"
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
            maxLength: {
              value: 11,
              message: 'Broj karaktera ne sme biti veći od 11!',
            },
          }}
          className={error.includes('unique_code') ? errorClassNames : ''}
          defaultValue={vehicle.unique_code && vehicle.unique_code}
        />

        <Input
          name="licence_plate"
          label={<>{requiredFieldIcon} Registarski broj</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
          className={error.includes('licence_plate') ? errorClassNames : ''}
          defaultValue={vehicle.licence_plate && vehicle.licence_plate}
        />
        <Input 
          name="svo_validated"
          disabled={true}
          label={<>{requiredFieldIcon} Validiran</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
          defaultValue={parseInt(vehicle.svo_validated, 10) === 1 ? "Da" : parseInt(vehicle.svo_validated, 10) === 0 ? "Ne" : "Došlo je do greške, svo_validated broj nije ispravan"}
        />
        <Input name="type" label="Tip vozila" defaultValue={vehicle.type && vehicle.type} />
        <Input name="purpose" label="Namena vozila" defaultValue={vehicle.purpose && vehicle.purpose} />
        <Input name="manufacturer" label="Marka vozila" defaultValue={vehicle.manufacturer && vehicle.manufacturer} />
        <Select name="office"
                label={<>{requiredFieldIcon} Pripadnost direkciji</>}
                options={[{label: 'AB', value: 'ab'}, {label: 'MK', value: 'mk'}]}
                defaultValue={vehicle.office && vehicle.office}
                rules={{
                  required: {
                    value: true,
                    message: 'Obavezno polje',
                  },
                }}
                labelInValue/>
        <DateInput name="first_registration" 
                   showTime={false}
                   label="Datum prve registracije"
                   format='YYYY-MM-DD'
                   enableDates={true}
                   defaultValue={vehicle.first_registration && moment(vehicle.first_registration, 'YYYY-MM-DD')}
                   />
                   
        <DateInput name="registration_end" 
                   showTime={false} 
                   label="Datum isteka registracije"
                   format='YYYY-MM-DD'
                   enableDates={true}
                   defaultValue={vehicle.registration_end && moment(vehicle.registration_end, 'YYYY-MM-DD')}
                   /> 
        <Input name="net_weight" label="Masa vozila" defaultValue={vehicle.net_weight && vehicle.net_weight} />
        <Input name="load_capacity" label="Dozvoljena nosivost" defaultValue={vehicle.load_capacity && vehicle.load_capacity} />

        <Input
          name="gps_device_id"
          label="ID GPS uređaja"
          type="number"
          className={error.includes('gps_device_id') ? errorClassNames : ''}
          defaultValue={vehicle.gps_device_id && vehicle.gps_device_id}
        />
      </Form>
    </FormDrawer>
  );
};

export default UpdateVehicleForm;
