import { axios } from 'src/lib/axios';
import { API_URL } from 'src/config';


export const loginWithEmailAndPassword = (data) => {
  return axios.post(`${API_URL}/account/login`, data);
};

export const registerWithEmailAndPassword = (data) => {
  return axios.post(`${API_URL}/account/register`, data);
};

export const getUserProfile = () => {
  return axios.get(`${API_URL}/account/me`);
};
