import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const handleResetPsw = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="bg-gray-100 min-h-screen flex">
      <div className="m-auto max-w-sm w-full relative">
        <div className="text-center absolute bottom-full left-1/2 transform -translate-x-1/2">
          <Image
            width={80}
            preview={false}
            src="../../belgrade_logo.png"
          />
        </div>

        <div className="bg-white p-5 m-3 border border-gray-300">
          <form onSubmit={handleResetPsw}>
            <p className="text-center text-gray-500 mb-4">
              Zaboravili ste lozinku? Ukucajte email adresu na kojoj želite da
              dobijete novu lozinku.
            </p>

            <div className="mb-4">
              <label htmlFor="email" className="block relative">
                <span className="absolute top-0 right-0 text-lg text-gray-500 inline-block p-3">
                  <FaEnvelope />
                </span>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="w-full border border-gray-300 rounded focus:ring-blue-100 pr-10 p-2"
                  required
                />
              </label>
              {/* error  */}
            </div>

            <div className="mb-4">
              <button
                className="bg-blue-600 text-white w-full md:px-6 px-3 py-1.5 rounded hover:bg-blue-500 focus:outline-none"
                type="submit">
                Pošalji
              </button>
            </div>

            <p className="mb-2">
              <Link to="/" className="text-blue-600 hover:text-opacity-60">
                Uloguj se
              </Link>
            </p>
            <p>
              <Link
                to="/register"
                className="text-blue-600 hover:text-opacity-60">
                Registruj se
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
