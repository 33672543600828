import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Vehicles from './Vehicles';
import VehicleDetails from './VehicleDetails';
import VehicleReports from './VehicleReports';

const VehiclesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Vehicles />
      </Route>
      
      <Route path={`${path}/reports`}>
          <VehicleReports />
      </Route>

      <Route path={`${path}/:id`}>
        <VehicleDetails />
      </Route>
     
    </Switch>
  );
};

export default VehiclesRoutes;
