import React from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import { getVehicle } from '../api';
import UpdateVehicleForm from '../components/UpdateVehicleForm';
import DeleteVehicle from '../components/DeleteVehicle';
import { useParams } from 'react-router-dom';
import Loader from 'src/utils/Loader';
import { Divider, Row, Col } from 'antd';
import Table from 'src/utils/Table';
import { RBAC, ROLES } from 'src/lib/rbac';

const columns = [
  { title: 'Garažni broj', dataIndex: 'unique_code', width: '5%' },
  { title: 'Registracioni broj', dataIndex: 'licence_plate' },
  { title: 'Tip vozila', dataIndex: 'type' },
  { title: 'Namena vozila', dataIndex: 'purpose' },
  { title: 'Marka vozila', dataIndex: 'manufacturer' },
  { title: 'Pripadnost direkciji', dataIndex: 'office' },
  { title: 'Datum prve registracije', dataIndex: 'first_registration' },
  { title: 'Datum isteka registracije', dataIndex: 'registration_end' },
  { title: 'Masa vozila', dataIndex: 'net_weight' },
  { title: 'Dozvoljena nosivost', dataIndex: 'load_capacity' },
  { title: 'Validiran', dataIndex: 'svo_validated', render: (entry) => (
    parseInt(entry, 10) === 1 ? "Da" : parseInt(entry, 10) === 0 ? "Ne" : "Došlo je do greške, svo_validated broj nije ispravan"
  ) }
];

const VehicleDetails = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(['vehicle', id], () => getVehicle(id), {
    select: (res) => res.data,
  });

  if(data) {
    data.first_registration = data.first_registration ? moment(data.first_registration).format('YYYY-MM-DD') : '';
    data.registration_end = data.registration_end ? moment(data.registration_end).format('YYYY-MM-DD') : '';
  }

  const listHeadingClasses = {
    main: "flex flex-col mb-2 md:flex-row xl:justify-between md:items-center",
    leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
    right: "flex justify-center md:justify-end w-full",
    left: "mt-3 md:ml-4 xl:mt-0 w-full"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        title={"Vozilo " + data.licence_plate}
        classNames={listHeadingClasses}
        right={
          <RBAC allowedRoles={[ROLES.ADMIN_JKP]}>
            <Row gutter={[8,4]} className="w-full" justify="end">
              <Col md={8} sm={12} xs={12}>
                <DeleteVehicle id={id} />
              </Col>

              <Col md={8} sm={12} xs={12}>
                <UpdateVehicleForm vehicle={data} />
              </Col>
            </Row>
          </RBAC>
        }
      />

      <Table columns={columns} data={data} />

      <Divider orientation="left">Napomene</Divider>
      <p>{data.note}</p>
    </>
  );
};

export default VehicleDetails;
