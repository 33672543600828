import React from 'react';
import { Modal, Button } from 'antd';
import { useMutation } from 'react-query';
import { deleteTravelWarrant } from '../api';
import { useHistory } from 'react-router-dom';
import { queryClient } from 'src/lib/react-query';
import openNotificationWithIcon from 'src/utils/notification';
import { errorHandler } from 'src/utils/Error';

function DeleteTravelWarrant({ id }) {
  const history = useHistory();
  const { mutateAsync, isLoading } = useMutation(deleteTravelWarrant);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await mutateAsync(id);
      queryClient.invalidateQueries('travel_warrants');
      setIsModalVisible(false);
      openNotificationWithIcon('success');
      history.replace('/travel-warrants');
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        className="text-white bg-red-500 mt-2 xs:w-full xs:order-4 sm:w-full sm:order-4 md:mt-0 md:order-1 md:w-auto "
        danger="true"
        onClick={showModal}>
        {/* Obriši */}
        Poništi
      </Button>

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          className: 'text-white bg-red-500',
          danger: true,
          type: 'default',
        }}
        cancelButtonProps={{
          className: 'text-white bg-blue-500',
        }}
        okText="Potvrdi"
        cancelText="Otkaži">
        <span className="text-lg">
          Da li ste sigurni da želite da poništite nalog?
        </span>
      </Modal>
    </>
  );
}

export default DeleteTravelWarrant;
