import React from 'react';
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import { getPoint } from '../api';
import UpdatePointForm from '../components/UpdatePointForm';
import DeletePoint from '../components/DeletePoint';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import Loader from 'src/utils/Loader';
import Table from 'src/utils/Table';

const columns = [
  { title: 'Redni br.', dataIndex: 'point_number' },
  { title: 'Šifra opštine', dataIndex: 'municipality_code' },
  { title: 'Ulica', dataIndex: 'street' },
  { title: 'Šifra ulice', dataIndex: 'street_code' },
  { title: 'Kućni br.', dataIndex: 'street_number' },
  { title: 'Šifra sabirnog mesta', dataIndex: 'collective_point_code' },
  { title: 'Bliže odredište', dataIndex: 'near_destination' },
  { title: 'Met.', dataIndex: 'metal' },
  { title: 'Pl.', dataIndex: 'plastic' },
  { title: 'Šifra kontejnera', dataIndex: 'container_code' },
  { title: 'Šifra zapremine', dataIndex: 'volume_code' },
  { title: 'Šifra otpada', dataIndex: 'waste_code' },
  { title: 'Šifra za ERP', dataIndex: 'erp_code' },
  { title: 'Geografska dužina', dataIndex: 'longitude' },
  { title: 'Geografska širina', dataIndex: 'latitude' },
];

const PointDetails = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(['point', id], () => getPoint(id), {
    select: (res) => res.data,
  });

  const listHeadingClasses = {
    main: "flex flex-col mb-2 md:flex-row xl:justify-between md:items-center",
    leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
    right: "flex justify-center md:justify-end w-full",
    left: "mt-3 md:ml-4 xl:mt-0 w-full"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        title={"Punkt broj: " + data.point_number}
        classNames={listHeadingClasses}
        right={
          <Row gutter={[8,4]} className="w-full" justify="end">
            <Col md={8} sm={12} xs={12}>
              <DeletePoint id={id} />
            </Col>
            <Col md={8} sm={12} xs={12}>
              <UpdatePointForm point={data} />
            </Col>
          </Row>
        }
      />

      <Table columns={columns} data={data} />
    </>
  );
};

export default PointDetails;
