import { useQuery } from 'react-query';
import { getAllPoints } from 'src/features/points/api';

export const useRouteFormData = () => {
  const { data: points } = useQuery('all_points', () => getAllPoints(), {
    select: (res) => res.data,
    initialData: { data: [] },
  });

  const pointsOptions = points?.map((p) => ({
    value: p.id,
    label: p.container_code,
  }));

  return {
    pointsOptions,
  };
};
