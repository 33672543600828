import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getEmployees = (params) => {
  return axios(`${API_URL}/admin/employees`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getEmployee = (id) => {
  return axios(`${API_URL}/admin/employees/${id}`).catch((error) => errorHandler(error));
};

export const createEmployee = (data) => {
  return axios.post(`${API_URL}/admin/employees`, data);
};

export const updateEmployee = ({ id, data }) => {
  return axios.put(`${API_URL}/admin/employees/${id}`, data);
};

export const deleteEmployee = (id) => {
  return axios.delete(`${API_URL}/admin/employees/${id}`);
};

// select dropdown
export const getLoggedUsers = () => {
  return axios(`${API_URL}/admin/employees_logged`).catch((error) => errorHandler(error));
};

export const getAllRoutes = () => {
  return axios(`${API_URL}/admin/routes_all`).catch((error) => errorHandler(error));
};
