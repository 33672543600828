import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Reports from './Reports';

function ReportsRoutes() {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Reports />
            </Route>
        </Switch>
    )
}

export default ReportsRoutes
