import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Table, Input, Row, Col, Divider, Tooltip } from 'antd';
import { getAllRoutes, getEmployees } from '../api';
import { Link } from 'react-router-dom';
import CreateEmployeeForm from '../components/CreateEmployeeForm';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import usePagination from 'src/hooks/usePagination';
import { options } from 'src/utils/tableConfig';
import CreateNotificationForm from '../../notifications/components/CreateNotificationForm';
import { SearchOutlined } from '@ant-design/icons';
import DropdownSelect from 'src/lib/DropdownSelect';
import { useStorage } from 'src/hooks/useStorage';
import SearchOptions from 'src/utils/SearchOptions';

const employeeTypeOptions = [
  {
    label: "Vozač",
    value: "driver"
  },
  {
    label: "Komunalni Radnik",
    value: "employee"
  }
]

const employeeValidatedOptions = [
  {
    label: "Svi",
    value: ""
  },
  {
    label: "Validiran",
    value: "1"
  },
  {
    label: "Nije Validiran",
    value: "0"
  }
]

const Employees = () => {
  const {
    page,
    setPage,
    term,
    setTerm,
    paginate,
    getPaginationProps,
  } = usePagination();

  const listParams = { page, paginate, ...term };
  const {storage, employeesTerm} = useStorage()
  const [filterData, setFilterData] = useState(employeesTerm)
  const { data, isLoading } = useQuery(
    ['employees', listParams],
    () => getEmployees(listParams),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );
  const employessFilterExist = storage.get('employessFilter')
  const { data: routes, isLoading: isLoadingRoutes } = useQuery(
    'all_routes',
    () => getAllRoutes(),
    {
      select: (res) => res.data,
      initialData: { data: [] },
    },
  );
  const columns = [
    { title: 'Obračunski br.', dataIndex: 'employee_code' },
    { title: 'Ime', dataIndex: 'first_name' },
    { title: 'Ime roditelja', dataIndex: 'parent_name' },
    { title: 'Prezime', dataIndex: 'last_name' },
    { title: 'Tip', dataIndex: 'employee_type', render: (entry) => (
      entry === "driver" ? "Vozač" : entry === "employee" ? "Komunalni Radnik" : "Nepoznat tip vozača!"
    )},
    { title: 'Pogon', dataIndex: 'route_id', render: (entry) => {
      const selectedRoute = routes?.map(route => entry === route.id ? route.name : "")
      return selectedRoute
    }},
    { title: 'Radno mesto', dataIndex: 'workplace' },
    { title: 'Mesto prebivališta', dataIndex: 'residence' },
    { title: 'Opština prebivališta', dataIndex: 'residence_municipality' },
    { title: 'Naselje prebivališta', dataIndex: 'residence_settlement' },
    { title: 'Br. KKV', dataIndex: 'kkv_number', width: 60 },
    {
      title: 'HSE obuka',
      render: (item) => (item.hse_training === '1' ? 'Da' : 'Ne'),
      width: 60,
    },
    { title: 'Validiran', dataIndex: 'svo_validated', render: (entry) => (
      parseInt(entry, 10) === 1 ? "Da" : parseInt(entry, 10) === 0 ? "Ne" : "Došlo je do greške, svo_validated broj nije ispravan"
    ) },
    {
      title: '',
      render: (entry) => (
        <Link
          className="align-middle inline-block p-1 text-gray-500"
          to={`/employees/${entry.id}`}>
          <SearchOutlined />
        </Link>
      ),
      width: 50,
    },
  ];


  const handleInputChange = function(e) {
    setFilterData({...filterData, [e.target.name]:e.target.value})
  }

  const handleSelectChange = function(value, event) {
    setFilterData({...filterData, [event.name] : value.value})
  }
  const handleFormSubmit = function(e) {
    e.preventDefault()
    setPage(1);
    setTerm(filterData);
    storage.set("employessFilter", JSON.stringify({ ...filterData }))
  }

  const clearFilterData = function() {
    if (!employessFilterExist) return
    setPage(1);
    setTerm(employeesTerm);
    setFilterData(employeesTerm);
    storage.remove("employessFilter")
  }

  useEffect(() => {
    if (employessFilterExist && !isLoading) {
      const existingFilter = JSON.parse(storage.get("employessFilter"))
      setTerm(existingFilter);
      setFilterData(existingFilter);
    }
  },[isLoading])

  const listHeadingClasses = {
    main: "flex flex-col mb-2 xl:flex-row xl:justify-between xl:items-center",
    leftContainer: "flex mb-2 w-full xl:mb-0 flex-col xl:flex-row xl:items-center xl:w-3/5",
    right: "flex justify-center w-full xl:justify-end xl:w-2/5",
    left: "mt-3 xl:ml-4 xl:mt-0 w-full"
  }

  if (isLoading || isLoadingRoutes) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        title="Zaposleni"
        classNames={listHeadingClasses}
        left={
          <form onSubmit={handleFormSubmit} className="w-full">
            <Row gutter={[8,4]} className="w-full" justify="center">
              <Col xl={7} sm={8} xs={24}>
                <Tooltip title="Ime i prezime" className="w-full" color="#666" >
                  <Input
                  name="term"
                  placeholder="Unesite ime i prezime"
                  value={filterData.term}
                  onChange={handleInputChange}
                  />
                </Tooltip>
              </Col>

              <Col xl={7} sm={8} xs={24}>
                <Tooltip title="Tip zaposlenog" className="w-full" color="#666" >
                  <div className="dropdown-select-wrapper">
                    <DropdownSelect 
                    name="employee_type"
                    placeholder="Izaberite tip..."
                    value={employeeTypeOptions.filter((option) => {
                      return option.value === filterData.employee_type
                    })}
                    options={employeeTypeOptions}
                    onChange={handleSelectChange}
                    />
                  </div>
                </Tooltip>
              </Col>
              
              <Col xl={6} sm={8} xs={24}>
                <Tooltip title="Validan ili nevalidan" className="w-full" color="#666" >
                  <div className="dropdown-select-wrapper">
                    <DropdownSelect 
                    name="svo_validated"
                    placeholder="Izaberi status validnosti..."
                    value={employeeValidatedOptions.filter((option) => {
                      return option.value === filterData.svo_validated
                    })}
                    options={employeeValidatedOptions}
                    onChange={handleSelectChange}
                    />
                  </div>
                </Tooltip>
              </Col>

              <Col xl={4} sm={24} xs={24} className="flex justify-center xl:justify-start">
                  <SearchOptions onClear={clearFilterData} />
              </Col>

              <Col xl={0} sm={24} xs={24}>
                  <Divider />
              </Col>
            </Row>
          </form>
       
        }
        right={
          <Row gutter={[8,4]} justify="end" className="w-full mb-5 xl:mb-0">
            <Col xl={10} sm={12} xs={24}>
              <CreateNotificationForm />
            </Col>
            <Col xl={8} sm={12} xs={24}>
              <CreateEmployeeForm />
            </Col>
          </Row>
        }
      />

      <Table
        {...options}
        columns={columns}
        dataSource={data.data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default Employees;
