import { Button } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import { updateRoute } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import { SelectElement } from 'src/components/Form/Select';
import { useRouteFormData } from '../hooks/useRouteFormData';
import {
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const UpdateRoutePageForm = ({ route }) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(updateRoute);
  const formMethods = useForm();

  React.useEffect(() => {
    formMethods.reset({
      ...route,
      point_ids: route.points.map((p) => p.id),
    });
  }, [route]);

  const { pointsOptions } = useRouteFormData();

  const handleSubmit = async (data) => {
    try {
      await mutateAsync({ data, id: route.id });
      queryClient.invalidateQueries('routes');
      queryClient.invalidateQueries('route');
      queryClient.invalidateQueries('all_routes')
      formMethods.reset();
      openNotificationWithIcon('success');
    } catch (error) {
      errorHandler(error);
    }
  };

  const formName = 'updateRoute';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={<Button className="text-white bg-blue-500 xs:w-full sm:w-full">Izmeni</Button>}
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Izmeni
        </Button>
      }
      title="Izmeni podatke">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="name"
          label={<>{requiredFieldIcon} Ime</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input
          name="description"
          label={<>Opis</>}
        />

        <Input name="son_code" label={<>Son Šifra</>} />

        <SelectElement
          name="point_ids"
          label={<> Punktovi</>}
          options={pointsOptions}
          labelInValue
          mode="multiple"
          onFocus={() => {
            if (pointsOptions.length < 1) {
              openNotificationWithIcon('info', {
                message: 'Napravite punkt u sekciji "Punktovi"!',
              });
            }
          }}
        />
      </Form>
    </FormDrawer>
  );
};

export default UpdateRoutePageForm;
