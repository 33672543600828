import React from 'react'
import { useQuery } from 'react-query';
import { getLegalEntities, importLegalEntities } from '../api';
import usePagination from 'src/hooks/usePagination';
import Loader from 'src/utils/Loader';
import { Link } from 'react-router-dom';
import { ListHeading } from 'src/components/ListHeading'
import { Button, Row, Col, Table, Upload, Divider, Tooltip } from 'antd';
import { options } from 'src/utils/tableConfig';
import { SearchOutlined } from '@ant-design/icons';
import { SearchInput as Search } from 'src/utils/Search';
import { useMutation } from 'react-query';
import CreateLegalEntityForm from '../components/CreateLegalEntityForm';
import { errorHandler } from 'src/utils/Error';
import openNotificationWithIcon from 'src/utils/notification';
import { queryClient } from 'src/lib/react-query';

const columns = [
    {title: 'Naziv', dataIndex: 'name', width: '12%'},
    {title: 'PIB', dataIndex: 'pib'},
    {title: 'MB', dataIndex: 'mb'},
    {title:'Kupac', dataIndex: 'client'},
    {title:'Adresa Prostora', dataIndex: 'address', width: '12%'},
    {title:'Adresa Dostave Računa', dataIndex: 'billing_address', width: '12%'},
    {title:'Ukupna Površina', dataIndex: 'area'},
    {title: 'Šifra Opštine SON', dataIndex: 'son_municipality_code', width: '8%'},
    {title: 'Šifra Ulice SON', dataIndex: 'son_street_code'},
    {title:'Imanje Za Fakturu', dataIndex: 'invoice_property'},
    {title: 'Broj Fakture', dataIndex: 'invoice_number'},
    {title:'Šifra Imanja', dataIndex: 'property_code'},
    {title: 'Cenovna Grupa', dataIndex: 'price_group'},
    {
        title: '',
        render: (entry) => (
          <Link
            className="align-middle inline-block p-1 text-gray-500"
            to={`/legal_entities/${entry.id}`}>
            <SearchOutlined />
          </Link>
        ),
        width: 50,
      },
]

function LegalEntities() {
    const {
        page,
        setPage,
        term,
        setTerm,
        paginate,
        getPaginationProps,
      } = usePagination();

    const listParams = { page, paginate, term };
    const {mutateAsync, isLoading:isUploading } = useMutation(importLegalEntities);
    
    const { data, isLoading } = useQuery(
        ['legal-entities', listParams],
        () => getLegalEntities(listParams),
        {
          select: (res) => res.data,
          onSuccess: ({ current_page, last_page } = {}) =>
            current_page > last_page ? setPage(last_page) : 1,
        },
      );

    const customUploadRequest = async ({onSuccess, onError, file}) => {
      let formData = new FormData();
          
      formData.append("import_file", file);
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries('legal-entities');
        openNotificationWithIcon('success');
      } catch (error) {
        errorHandler(error);
      } 
    }

    const listHeadingClasses = {
      main: "flex flex-col mb-2 lg:flex-row lg:justify-between lg:items-center",
      leftContainer: "flex mb-2 lg:mb-0 flex-col lg:flex-row lg:items-center w-full",
      right: "flex justify-center lg:justify-end w-full ",
      left: "mt-3 lg:ml-4 lg:mt-0 w-full",
      title: "text-2xl min-w-max"
    }
  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

    return (
        <>
            <ListHeading 
             title="Pravna Lica"
             classNames={listHeadingClasses}
             left={
                <Search
                  setPage={setPage}
                  setTerm={setTerm}
                  placeholder="Unesite naziv"
                  tooltip="Naziv"
                />
              }
              right={
              <Row gutter={[8,4]} className="w-full mb-5 lg:mb-0" justify="end">
                <Col lg={0} sm={24} xs={24}>
                  <Divider />
                </Col>
                <Col lg={8} sm={12} xs={24} className="ant-upload-wrapper">
               
                    <Upload name="file" multiple={false} showUploadList={false} customRequest={customUploadRequest} className="xs:w-full sm:w-full block first:w-full first:block ">
                      <Button className="text-white bg-blue-500 xs:w-full sm:w-full" loading={isUploading}>Importuj Pravna Lica</Button>
                    </Upload>
                 
                </Col>
                <Col lg={8} sm={12} xs={24}>
                  <CreateLegalEntityForm />
                </Col>
              </Row>
              }
            />

            <Table 
                {...options}
                columns={columns}
                dataSource={data.data}
                pagination={getPaginationProps({ total: data.total })}
            />
        </>
    )
}

export default LegalEntities
