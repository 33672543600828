import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getLogs = (params) => {
  return axios(`${API_URL}/admin/logs`, { params }).catch((error) => errorHandler(error));
};

export const getLog = (id) => {
  return axios(`${API_URL}/admin/logs/${id}`).catch((error) => errorHandler(error));
};
