import { notification } from 'antd';

const openNotificationWithIcon = (
  type,
  { message = 'Uspešno izvršeno.', description = '', duration = 3 } = {},
) =>
  notification[type]({
    placement: 'topLeft',
    message: message,
    description: description,
    duration: duration,
    className: 'w-auto max-w-xl',
  });

export default openNotificationWithIcon;
