import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Register from './Register';
import Login from './Login';
import ResetPassword from './ResetPassword';

export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>

      <Route path="/register">
        <Register />
      </Route>

      <Route path="/reset-password">
        <ResetPassword />
      </Route>

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
