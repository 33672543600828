import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import storage from 'src/utils/storage';
import { API_URL } from 'src/config';
import openNotificationWithIcon from 'src/utils/notification';

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

const refreshAuthLogic = () => {
  const token = storage.getToken();
  if (!token) return;

  return axios
    .post(`${API_URL}/account/refresh`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((tokenRefreshResponse) => {
      storage.setToken(tokenRefreshResponse?.access_token);
      return Promise.resolve();
    })
    .catch((err) => {
      storage.clearToken();
      openNotificationWithIcon('info', { message: 'Vaša sesija je istekla.' });
      window.location.assign(window.location);
      return Promise.reject(err);
    });
};

// export const authResponseInterceptors = [
//   function (response) {
//     return response.data;
//   },
//   function (error) {
//     if (error?.response?.status === 401 && storage.getToken()) {
//       storage.clearToken();
//       openNotificationWithIcon('info', 'Vaša sesija je istekla.');
//       window.location.assign(window.location);
//       return Promise.reject(error);
//     }

//     const errorMessage = error?.response?.data.errors;

//     errorMessage &&
//       Object.values(errorMessage).forEach((err) =>
//         openNotificationWithIcon('error', err.toString()),
//       );

//     return Promise.reject(error);
//   },
// ];

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(function (response) {
  return response.data;
});
createAuthRefreshInterceptor(axios, refreshAuthLogic);
