import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';

// import axios from 'axios';
// const token =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLXZpbmNhLnF1YW50b3guZGV2XC9hcGlcL2FjY291bnRcL3JlZnJlc2giLCJpYXQiOjE2MjM3NTEyMTcsImV4cCI6MTYyMzc1OTQ2OSwibmJmIjoxNjIzNzU1ODY5LCJqdGkiOiJ4YWNsNlZ3enM1Rm9ZWHdDIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3IiwiZXhwaXJlc19hdCI6IjIwMjEtMDYtMTVUMTI6MTc6NDkuNTMyNzY2WiIsInJlZnJlc2hfZXhwaXJlc19hdCI6IjIwMjEtMDYtMTZUMTA6MDA6MTcuNzc2MzkxWiJ9.yt-t1SWe1evtPA7XPVXG8XzsZv2S7IYL2MJXU4HQVjQ';

// export const getVehiclesGPS = () => {
//   return axios('https://api-vinca.quantox.dev/api/admin/vehicle_params', {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'content-type': 'application/json',
//     },
//   }).catch((error) => errorHandler(error));
// };

// export const getVehicleHistoryGPS = ({ id, data }) => {
//   return axios.post(
//     `https://api-vinca.quantox.dev/api/admin/vehicle_params/${id}`,
//     data,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'content-type': 'application/json',
//       },
//     },
//   );
// };

export const getVehiclesGPS = () => {
  return axios(
    'https://api-vinca.quantox.dev/api/admin/vehicle_params',
  ).catch((error) => errorHandler(error));
};

export const getVehicleHistoryGPS = ({ id, data }) => {
  return axios.post(
    `https://api-vinca.quantox.dev/api/admin/vehicle_params/${id}`,
    data,
  );
};
