import { Button, Image } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useAuth } from 'src/lib/auth';
import { Form, Input } from 'src/components/Form';

const Login = () => {
  const { login } = useAuth();
  const [error, setError] = React.useState(null);

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch (error) {
      setError(error);
    }
  };

  const formMethods = useForm();

  return (
    <div className="bg-gray-100 min-h-screen flex">
      <div className="m-auto max-w-sm w-full relative">
        <div className="text-center absolute bottom-full left-1/2 transform -translate-x-1/2">
          <Image
            width={80}
            preview={false}
            src="../../belgrade_logo.png"
          />
        </div>

        <div className="bg-white p-5 m-3 mt-0 border border-gray-300">
          <h2 className="text-center text-3xl text-gray-600 mb-3">Uloguj se</h2>
          <Form formMethods={formMethods} onSubmit={onSubmit}>
            <Input
              rules={{
                required: {
                  value: true,
                  message: 'Obavezno polje',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Email nije validan!',
                },
              }}
              type="email"
              label="Email"
              name="email"
              suffix={<FaEnvelope />}
            />
            <Input
              rules={{
                required: {
                  value: true,
                  message: 'Obavezno polje',
                },
              }}
              type="password"
              label="Lozinka"
              name="password"
              suffix={<FaLock />}
            />
            <div className="text-right">
              <Button
                className="bg-blue-500 text-white rounded"
                htmlType="submit">
                Pošalji
              </Button>
            </div>
          </Form>

          {error && (
            <div className="text-red-500 font-semibold">
              Email ili lozinka nisu validni.
            </div>
          )}

          {/* <p className="mb-2">
            <Link
              to="/reset-password"
              className="text-blue-600 hover:text-opacity-60">
              Zaboravljena lozinka
            </Link>
          </p>
          <p>
            <Link
              to="/register"
              className="text-blue-600 hover:text-opacity-60">
              Registruj se
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
