import { Button } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import { updateUser } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import { SelectElement } from 'src/components/Form/Select';
import {
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const UpdateUserForm = ({ user }) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(updateUser);
  const formMethods = useForm();

  React.useEffect(() => {
    formMethods.reset({ ...user, role: user.roles.map((r) => r.name) });
  }, [user]);

  const handleSubmit = async (data) => {
    try {
      await mutateAsync({ data, id: user.id });
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('user');
      formMethods.reset();
      openNotificationWithIcon('success');
    } catch (error) {
      errorHandler(error);
    }
  };

  const roleOptions = [
    { label: 'Admin', value: 'admin_jkp' },
    { label: 'Kontrolor', value: 'controller' },
    { label: 'Serviser', value: 'service' },
  ];

  const formName = 'updateUser';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={<Button className="text-white bg-blue-500 xs:w-full sm:w-full">Izmeni</Button>}
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Izmeni
        </Button>
      }
      title="Izmeni podatke">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="name"
          label={<>{requiredFieldIcon} Ime i prezime</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input name="email" type="email" label="Email" disabled={true} />

        <Input
          name="password"
          label="Šifra"
          rules={{
            minLength: {
              value: 8,
              message: 'Broj karaktera ne sme biti manji od 8!',
            },
          }}
          type="password"
        />

        <Input
          name="password_confirmation"
          label="Potvrdi šifru"
          rules={{
            validate: (value) =>
              value === formMethods.watch('password') ||
              'Lozinke se ne poklapaju!',
          }}
          type="password"
        />

        <SelectElement
          name="role"
          label={<>{requiredFieldIcon} Rola</>}
          options={roleOptions}
          labelInValue
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />
      </Form>
    </FormDrawer>
  );
};

export default UpdateUserForm;
