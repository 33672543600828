import React from 'react'
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { deleteLegalEntity } from '../api';
import { queryClient } from 'src/lib/react-query';
import openNotificationWithIcon from 'src/utils/notification';
import { errorHandler } from 'src/utils/Error';
import { useMutation } from 'react-query';

function DeleteLegalEntity({ id }) {
    const history = useHistory();
    const { mutateAsync, isLoading } = useMutation(deleteLegalEntity);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
          await mutateAsync(id);
          queryClient.invalidateQueries('legal-entities');
          setIsModalVisible(false);
          openNotificationWithIcon('success');
          history.replace('/legal_entities');
        } catch (error) {
          errorHandler(error);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button
                className="text-white bg-red-500 xs:w-full sm:w-full"
                danger="true"
                onClick={showModal}>
                Obriši
            </Button>

            <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                loading: isLoading,
                className: 'text-white bg-red-500',
                danger: true,
                type: 'default',
                }}
                cancelButtonProps={{
                className: 'text-white bg-blue-500',
                }}
                okText="Potvrdi"
                cancelText="Otkaži">
                <span className="text-lg">
                Da li ste sigurni da želite da izvršite akciju brisanja?
                </span>
            </Modal>
        </>
    )
}

export default DeleteLegalEntity
