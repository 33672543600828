import React, {useState, useEffect} from 'react';
import { Router } from 'react-router-dom';
import { Anchor, Card } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './lib/react-query';
import AppRoutes from './routes/AppRoutes';
import history from './lib/history';
import { AuthProvider } from 'src/lib/auth';
import Loader from './utils/Loader';
import { Error } from './utils/Error';

function ErrorFallback() {
  return <Error />;
}

const appOrigin = "https://bgdotpad.rs"

export function AppProviders({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <React.Suspense fallback={<Loader />}>
            <AuthProvider>{children}</AuthProvider>
          </React.Suspense>
        </QueryClientProvider>
      </Router>
    </ErrorBoundary>
  );
}

const App = () => {
  // const [timer, setTimer] = useState(10)
  // useEffect(() => {
  //     if (window.location.origin !== appOrigin) {
  //       let intervalTimer
  //       if (timer > 0) {
  //         intervalTimer = setTimeout(() => {
  //           setTimer(timer - 1)
  //         }, 1000)
  //       } else {
  //         window.location.replace(appOrigin)
  //       }
      
  //       return () => {clearTimeout(intervalTimer)}
  //     }
  // }, [timer])
  return (
    // window.location.origin !== appOrigin ?
    // <div className='flex flex-col justify-center align-cener w-100 h-screen px-3'>
    //   <Card className='lg:w-1/2 sm:w-full xs:w-full border-4 border-red-500 mx-auto rounded text-center'>
    //     Aplikacija se sada nalazi na novoj adresi. Bićete prebačeni na novu adresu za {timer} sekundi, ili kliknite na link: <br/> <a className='text-blue-500 hover:text-blue-800 hover:underline' href={appOrigin}>{appOrigin}</a> 
    //   </Card>
    // </div>
    // :
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export default App;
