import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { queryClient } from 'src/lib/react-query';
import { updateAlert, getUnreadedAlerts } from '../api';
import { Switch, Tooltip } from 'antd';
import { useAuth } from 'src/lib/auth';
import { errorHandler } from 'src/utils/Error';
import openNotificationWithIcon from 'src/utils/notification';

export const useGetAlerts = () => {
  const { user } = useAuth();
  const ifAdminRunQuery = user?.role === 'admin_jkp';

  const [totalUnreadAlerts, setTotalUnreadAlerts] = React.useState(0);
  const [refetchOnUpdate, setRefetchOnUpdate] = React.useState(false);

  const { mutateAsync } = useMutation((data) =>
    updateAlert(data.id, { read: data.read }),
  );

  const unreadAlerts = useQuery(
    ['unreadAlerts', refetchOnUpdate],
    getUnreadedAlerts,
    {
      // refetchInterval: 5000,
      // refetchIntervalInBackground: true,
      select: (res) => res.data,
      enabled: ifAdminRunQuery,
      onSuccess: ({ unread_alerts } = {}) => {
        return setRefetchOnUpdate(false), setTotalUnreadAlerts(unread_alerts);
      },
    },
  );

  const handleChecked = async (data) => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries('alerts');
      openNotificationWithIcon('success');
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleToogle = (data) => {
    const isRead = data.read ? 0 : 1; // reversed
    const infoText = `Označi kao ${isRead ? 'pročitano' : 'nepročitano'}`;

    return (
      <Tooltip title={infoText} mouseEnterDelay={0} color="#666">
        <Switch
          defaultChecked={isRead}
          size="small"
          onChange={(val) => (
            setRefetchOnUpdate(true),
            handleChecked({ id: data.id, read: val ? 0 : 1 })
          )}></Switch>
      </Tooltip>
    );
  };

  return {
    totalUnreadAlerts,
    handleToogle,
    ifAdminRunQuery,
  };
};
