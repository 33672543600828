import React from 'react';
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import { getNotification } from '../api';
import DeleteNotification from '../components/DeleteNotification';
import { useParams } from 'react-router-dom';
import Loader from 'src/utils/Loader';
import { Table } from 'antd';
import { options } from 'src/utils/tableConfig';

const columns = [
  {
    title: 'Vozači',
    render: (item) =>
      item
        ? item.first_name + '  ' + item.last_name + ' - ' + item.employee_code
        : '',
  },
];

const NotificationDetails = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ['notification', id],
    () => getNotification(id),
    {
      select: (res) => res.data,
    },
  );

  const listHeadingClasses = {
    main: "flex flex-col mb-2 sm:flex-row md:justify-between sm:items-center",
    leftContainer: "flex mb-2 sm:mb-0 flex-col sm:flex-row sm:items-center w-full",
    right: "flex justify-center sm:justify-end w-full sm:w-6/12 lg:w-4/12 ",
    left: "mt-3 sm:ml-4 sm:mt-0 w-full"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading classNames={listHeadingClasses} title={data.title} right={<DeleteNotification id={id} />} />

      <p className="text-base mb-3">{data.body}</p>

      <Table
        {...options}
        columns={columns}
        dataSource={data.employees}
        pagination={false}
      />
    </>
  );
};

export default NotificationDetails;
