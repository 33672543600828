import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { Form, DatePicker } from 'antd';
import { FieldWrapper } from './FieldWrapper';
import sr from 'antd/lib/locale/sr_RS';
import 'moment/locale/sr';

export const DateInput = ({
  name,
  label,
  description,
  placeholder,
  type,
  rules,
  showTime = { format: 'HH:mm' },
  defaultTime = '00:00',
  format="YYYY-MM-DD HH:mm",
  enableDates,
  ...props
}) => {
  const { control, errors } = useFormContext();

  function disabledDate(current) {
    return enableDates ? false : current && current > moment().endOf('day');
  }

  return (
    <FieldWrapper
      label={label}
      description={description}
      error={errors[name]?.message}>
      <Form.Item className="m-0">
        <Controller
          rules={rules}
          type={type}
          name={name}
          defaultValue=""
          label={label}
          control={control}
          placeholder={placeholder}
          render={(props) => (
            <DatePicker
              locale={sr}
              placeholder={placeholder}
              showTime={showTime}
              showNow={false}
              showTime={showTime === false ? false : { defaultValue: moment(defaultTime, 'HH:mm') }}
              value={props.value && moment(props.value)}
              disabledDate={disabledDate}
              className="w-full"
              size="small"
              format={format ? format : "YYYY-MM-DD HH:mm"}
              {...props}
            />
          )}
          {...props}
        />
      </Form.Item>
    </FieldWrapper>
  );
};
