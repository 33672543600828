import { Button } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { Checkbox } from 'src/components/Form/Checkbox';
import { queryClient } from 'src/lib/react-query';
import { createEmployee, getAllRoutes } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import { SelectElement as Select } from 'src/components/Form/Select';
import {
  errorClassNames,
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const CreateEmployeeForm = () => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(createEmployee);
  const formMethods = useForm();
  const [error, setError] = React.useState([]);
  const { data: routes, isLoading: isLoadingRoutes } = useQuery(
    'all_routes',
    () => getAllRoutes(),
    {
      select: (res) => res.data,
      initialData: { data: [] },
    },
  );

  const routesOptions = routes?.map((route) => ({
    label: route.name,
    value: route.id,
  }));

  const handleSubmit = async (data) => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries('employees');
      formMethods.reset();
      openNotificationWithIcon('success');
      setError('');
    } catch (error) {
      errorHandler(error, (err) => setError(err));
    }
  };

  const employeeTypeOptions = [
    {
      label: "Vozač",
      value: "driver"
    },
    {
      label: "Komunalni Radnik",
      value: "employee"
    }
  ]
  
  const formName = 'createEmployee';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={
        <Button className="text-white bg-blue-500 xs:w-full sm:w-full">Dodaj zaposlenog</Button>
      }
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Dodaj
        </Button>
      }
      title="Dodaj vozača">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="employee_code"
          label={<>{requiredFieldIcon} Obračunski broj</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
          className={error.includes('employee_code') ? errorClassNames : ''}
        />

        <Input
          name="first_name"
          label={<>{requiredFieldIcon} Ime</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input
          name="parent_name"
          label={<>{requiredFieldIcon} Ime roditelja</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input
          name="last_name"
          label={<>{requiredFieldIcon} Prezime</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />
        <div className="dropdown-select-wrapper my-2">
          <Select label={<>{requiredFieldIcon} Tip Zaposlenog</>} name="employee_type" options={employeeTypeOptions}
           defaultValue="driver"
           onFocus={() => {
            if (routesOptions && routesOptions.length < 1) {
              openNotificationWithIcon('info', {
                message: 'Napravite relaciju u sekciji "Relacije"!',
              });
            }
          }}
            />
        </div>
        <div className="dropdown-select-wrapper my-2">
          <Select 
            options={routesOptions} 
            loading={isLoadingRoutes} 
            name="route_id" 
            label={<>{requiredFieldIcon} Pogon</>}    
            onFocus={() => {
                    if (routesOptions.length < 1) {
                      openNotificationWithIcon('info', {
                        message: 'Napravite relaciju u sekciji "Relacije"!',
                      });
                    }
            }} 
          />
        </div>
        <Input name="workplace" label="Radno mesto" />
        <Input name="residence" label="Mesto prebivališta" />
        <Input name="residence_municipality" label="Opština prebivališta" />
        <Input name="residence_settlement" label="Naselje prebivališta" />
        <Input name="kkv_number" label="Broj KKV" />
        <Checkbox name="hse_training" label="HSE obuka" type="checkbox" />
      </Form>
    </FormDrawer>
  );
};

export default CreateEmployeeForm;
