import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Select } from 'antd';
import { FieldWrapper } from './FieldWrapper';

const { Option } = Select;

export const SelectElement = ({
  name,
  label,
  description,
  placeholder,
  mode,
  allowClear = true,
  options,
  rules,
  showSearch,
  onFocus,
  onSearch,
  onInputKeyDown,
  notFoundContent,
  selectDisabled,
  disabled = [],
  ...props
}) => {
  const { control, errors } = useFormContext();

  const getDisabled = value => {
    return disabled.includes(value)
  };

  return (
    <FieldWrapper
      label={label}
      description={description}
      error={errors[name]?.message}>
      <Form.Item className="m-0">
        <Controller
          rules={rules}
          name={name}
          label={label}
          control={control}
          defaultValue={mode === 'multiple' ? [] : ''}
          render={(props) => (
            <Select
              mode={mode}
              allowClear={allowClear}
              showSearch
              optionFilterProp="children"
              placeholder={placeholder}
              onFocus={onFocus}
              onSearch={onSearch}
              onInputKeyDown= {onInputKeyDown}
              notFoundContent={notFoundContent}
              disabled={selectDisabled}
              {...props}>
              {options?.map(({ value, label }) => (
                <Option key={value + '-' + label} value={value} disabled={getDisabled(value)}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
          {...props}
        />
      </Form.Item>
    </FieldWrapper>
  );
};
