import React from 'react';
import { FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from 'src/lib/auth';
import { Form, Input } from 'src/components/Form';
import { Button, Image } from 'antd';

const Register = () => {
  const history = useHistory();
  const { register } = useAuth();
  const formMethods = useForm();

  const onSubmit = async (data) => {
    try {
      await register(data);
      history.push('/dashboard');
    } catch (error) {
      if (error?.response?.status === 401) {
        formMethods.setError('email', {
          type: 'manual',
          message: 'Niste ovlašćeni za pristup ovoj aplikaciji!',
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex">
      <div className="m-auto max-w-sm w-full relative">
        <div className="text-center absolute bottom-full left-1/2 transform -translate-x-1/2">
          <Image
            width={80}
            preview={false}
            src="../../belgrade_logo.png"
          />
        </div>

        <div className="bg-white p-5 m-3 border border-gray-300">
          <h2 className="text-center text-3xl text-gray-600 mb-3">
            Registruj se
          </h2>
          <Form formMethods={formMethods} onSubmit={onSubmit}>
            <Input
              rules={{
                required: {
                  value: true,
                  message: 'Obavezno polje',
                },
              }}
              type="text"
              label="Ime i prezime"
              name="name"
              suffix={<FaUser />}
            />
            <Input
              rules={{
                required: {
                  value: true,
                  message: 'Obavezno polje',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Mora biti email',
                },
              }}
              type="email"
              label="Email adresa"
              name="email"
              suffix={<FaEnvelope />}
            />
            <Input
              rules={{
                required: {
                  value: true,
                  message: 'Obavezno polje',
                },
              }}
              type="password"
              label="Lozinka"
              name="password"
              suffix={<FaLock />}
            />
            <div className="text-right">
              <Button
                className="bg-blue-500 text-white rounded"
                htmlType="submit">
                Pošalji
              </Button>
            </div>
          </Form>

          <p>
            <Link to="/" className="text-blue-600 hover:text-opacity-60">
              Već imam nalog
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
