import React from 'react';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <div className="fixed w-screen h-screen  top-0 left-0 z-10 flex">
      <Spin className="m-auto text-lg" tip="Učitavanje..." size="large" />
    </div>
  );
};

export default Loader;
