import React from 'react';
import DashboardShell from './DashboardShell';
import { DashboardProvider } from './DashboardProvider';

const Dashboard = ({ children, menuItems }) => {
  return (
    <DashboardProvider menuItems={menuItems}>
      <DashboardShell>{children}</DashboardShell>
    </DashboardProvider>
  );
};

export default Dashboard;
