import React from 'react'
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import openNotificationWithIcon from 'src/utils/notification';
import {
    errorHandler,
    requiredFieldIcon,
    requiredFieldInfo,
  } from 'src/utils/Error';
import { createLegalEntity } from '../api';

function CreateLegalEntityForm() {
    const {mutateAsync, isLoading, isSuccess } = useMutation(createLegalEntity);
    const formMethods = useForm();

    const handleSubmit = async (data) => {
        try {
          await mutateAsync(data);
          queryClient.invalidateQueries('legal-entities');
          formMethods.reset();
          openNotificationWithIcon('success');
        } catch (error) {
          errorHandler(error);
        } 
    };

    const formName = 'createLegalEntity';
    
    return (
        <>
            <FormDrawer 
                isDone={isSuccess}
                width="90%"
                triggerButton={
                <Button className="text-white bg-blue-500 xs:w-full sm:w-full">Dodaj Pravno Lice</Button>
                }
                submitButton={
                <Button
                    form={formName}
                    key="submit"
                    htmlType="submit"
                    loading={isLoading}
                    className="text-white bg-blue-500">
                    Dodaj
                </Button>
                }
                title="Dodaj Pravno Lice"
            >
            <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
            {requiredFieldInfo}

                <Input
                name="name"
                label={<>Naziv</>}
                />
                <Input
                name="pib"
                label={<>PIB</>}
                />
                <Input
                name="mb"
                label={<>MB</>}
                />
                <Input
                name="client"
                label={<>Kupac</>}
                />
                <Input
                name="address"
                label={<>Adresa Prostora</>}
                />
                <Input
                name="billing_address"
                label={<>Adresa Dostave Računa</>}
                />
                <Input
                name="area"
                label={<>Ukupna Površina</>}
                />
                <Input
                name="son_municipality_code"
                label={<>{requiredFieldIcon} Šifra Opštine SON</>}
                rules={{
                    required: {
                    value: true,
                    message: 'Obavezno polje',
                    },
                }}
                />
                <Input
                name="son_street_code"
                label={<>Šifra Ulice SON</>}
                />
                <Input
                name="invoice_property"
                label={<>Imanje Za Fakturu</>}
                />
                <Input
                name="invoice_number"
                label={<>Broj Fakture</>}
                />
                <Input
                name="property_code"
                label={<>Šifra Imanja</>}
                />
                <Input
                name="price_group"
                label={<>Cenovna Grupa</>}
                />
            </Form>
            </FormDrawer>
        </>
    )
}

export default CreateLegalEntityForm
