import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Loader from 'src/utils/Loader';

// lib - https://react-google-maps-api-docs.netlify.app/

const apiKey = 'AIzaSyDYjXI3Ek4hKW2Kd6OpDBgmYc6dcncUzmQ';

const MapShell = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    language: 'sr',
  });

  const renderMap = () => {
    return (
      <div style={{ height: '90vh' }} className="relative">
        <GoogleMap {...options}>{children}</GoogleMap>
      </div>
    );
  };

  if (loadError) {
    return <div>Došlo je do greške, nije moguće učitati mapu.</div>;
  }

  return isLoaded ? renderMap() : <Loader />;
};

const options = {
  mapContainerStyle: {
    width: '100%',
    height: '100%',
  },
  center: {
    lat: 44.786568, // Belgrade
    lng: 20.448921,
  },
  zoom: 10,
};

export default MapShell;
