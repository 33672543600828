import React, { Component } from 'react'
import { Image } from 'antd'

export class TravelWarrantDetailsPrint extends Component {
    render() {
        const {data} = this.props
        const { employee, qr_code, transportation_type, vehicle, municipality } = data
        
        const blankLineStyle = {
            "borderBottom":"2px solid black", 
            "width":"100%", 
            "marginTop":"35px"
        }
        return (
            <div className="w-full text-lg p-4 flex flex-col">
                <div className="w-full my-5 flex justify-center">
                <Image
                preview={false}
                alt="barcode"
                src={`data:image/png;base64,${qr_code}`}
                />
            </div>
            <p>Obračunski Broj: <span className="font-semibold">{employee.employee_code}</span></p>
            <p>Ime i Prezime: <span className="font-semibold">{employee.first_name} {employee.last_name}</span></p>
            <p>Opština: <span className="font-semibold">{municipality ? municipality.name ? municipality.name : "" : ""  }</span></p>
            <p>Tip Prevoza: <span className="font-semibold">{transportation_type}</span></p>
            <p>Registarski Broj: <span className="font-semibold">{vehicle.licence_plate}</span></p>
            <div className="w-full flex justify-between mt-5">
                <div className="flex flex-col w-1/2 pr-2">Početna Kilometraža: <span style={blankLineStyle}></span></div>
                <div className="flex flex-col w-1/2 pl-2">Krajnja Kilometraža: <span style={blankLineStyle}></span></div>
            </div>
            <div className="w-full flex justify-between mt-3">
                <div className="flex flex-col w-1/2 pr-2">Radni Sati Nadogradnje: <span style={blankLineStyle}></span></div>
                <div className="flex flex-col w-1/2 pl-2">Radni Sati Nadogradnje Motora: <span style={blankLineStyle}></span></div>
            </div>
            <div className="w-full flex justify-between mt-12">
                <div className="flex flex-col w-1/2 pr-2">Potpis Vozača: <span style={blankLineStyle}></span></div>
            </div>
        </div>
        )
    }
}

export default TravelWarrantDetailsPrint
