import React from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { ListHeading } from 'src/components/ListHeading';
import usePagination from 'src/hooks/usePagination';
import { SearchInput as Search } from 'src/utils/Search';
import TravelWarrantDkoDetails from './TravelWarrantDkoDetails'
import { options } from 'src/utils/tableConfig';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table } from "antd"
import { useQuery } from 'react-query';
import { getTravelWarrantDko } from '../api';
import Loader from 'src/utils/Loader';


function TravelWarrantDko({warrant}) {
    const { path } = useRouteMatch();
    const { pathname } = useLocation()

    const {
        page,
        setPage,
        term,
        setTerm,
        paginate,
        getPaginationProps,
      } = usePagination();

      const listParams = { page, paginate, term };

      const {data, isLoading} = useQuery(["travel-warrant-all-dko", listParams], () => getTravelWarrantDko(warrant.id, listParams))

      const listHeadingClasses = {
        main: "flex flex-col mb-2 md:flex-row md:justify-between md:items-center",
        leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
        right: "flex justify-center md:justify-end w-full ",
        left: "mt-3 md:ml-4 md:mt-0 w-full"
      }

      const columns = [
          {
              title: "Naziv Pravnog Lica",
              dataIndex: "legal_entity_name",
              width: "95%"
          },
          {
              title: "",
              render: (entry) => (
                  <Link 
                  className="align-middle inline-block p-1 text-gray-500"
                  to={`${pathname}/${entry.id}`}>
                      <SearchOutlined />
                  </Link>
              ),
          }
      ]

      
    if (isLoading) return <Loader />
    if(data.data.length < 1) return <Redirect to={`${pathname.replace("/dko", "/dko_create")}`} />
    if (!data) return <Redirect to={`${pathname.replace("/dko", "/dko_create")}`} />
    
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <ListHeading 
                    classNames={listHeadingClasses}
                    title="Dokumenti o Kretanju Otpada"
                    left={
                        <Search setPage={setPage} setTerm={setTerm} placeholder="Unesite naziv pravnog lica" />
                    }
                    right = {
                        <Link to={`${pathname.replace("/dko", "/dko_create")}`} >
                            <Button className="text-white bg-blue-500">Napravi novi dokument</Button>
                        </Link>
                    }
                />

                <Table    
                    {...options}
                    columns={columns}
                    dataSource={data.data}
                    pagination={getPaginationProps({ total: data.total })} 
                />
            </Route>
            <Route path={`${path}/:id`}>
                <TravelWarrantDkoDetails warrant={warrant} />
            </Route>
            
        </Switch>
    )
}

export default TravelWarrantDko
