import React from 'react';
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import { getAllRoutes, getEmployee } from '../api';
import UpdateEmployeeForm from '../components/UpdateEmployeeForm';
import DeleteEmployee from '../components/DeleteEmployee';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import Loader from 'src/utils/Loader';
import CreateNotificationForm from '../../notifications/components/CreateNotificationForm';
import Table from 'src/utils/Table';


const EmployeeDetails = () => {

  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ['employee', id],
    () => getEmployee(id),
    {
      select: (res) => res.data,
    },
  );

  const { data: routes, isLoading: isLoadingRoutes } = useQuery(
    'all_routes',
    () => getAllRoutes(),
    {
      select: (res) => res.data,
      initialData: { data: [] },
    },
  );

  const columns = [
    { title: 'Obračunski br.', dataIndex: 'employee_code' },
    { title: 'Ime', dataIndex: 'first_name' },
    { title: 'Ime roditelja', dataIndex: 'parent_name' },
    { title: 'Prezime', dataIndex: 'last_name' },
    { title: 'Tip', dataIndex: 'employee_type', render: (entry) => (
      entry === "driver" ? "Vozač" : entry === "employee" ? "Komunalni Radnik" : "Nepoznat tip vozača!"
    )},
    { title: 'Pogon', dataIndex: 'route_id', render: (entry) => {
      const selectedRoute = routes?.map(route => entry === route.id ? route.name : "")
      return selectedRoute
    }},
    { title: 'Radno mesto', dataIndex: 'workplace' },
    { title: 'Mesto prebivališta', dataIndex: 'residence' },
    { title: 'Opština prebivališta', dataIndex: 'residence_municipality' },
    { title: 'Naselje prebivališta', dataIndex: 'residence_settlement' },
    { title: 'Br. KKV', dataIndex: 'kkv_number' },
    { title: 'HSE obuka', dataIndex: 'hse_training', render: (entry) => (
      entry === '1' ? 'Da' : 'Ne'
    ) },
    { title: 'Validiran', dataIndex: 'svo_validated', render: (entry) => (
      parseInt(entry, 10) === 1 ? "Da" : parseInt(entry, 10) === 0 ? "Ne" : "Došlo je do greške, svo_validated broj nije ispravan"
    ) }
  ];

  const listHeadingClasses = {
    main: "flex flex-col mb-2 md:flex-row xl:justify-between md:items-center",
    leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
    right: "flex justify-center md:justify-end w-full",
    left: "mt-3 md:ml-4 xl:mt-0 w-full"
  }

  if (isLoading || isLoadingRoutes) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;
  
  return (
    <>
      <ListHeading
        title={
          <>
            {data.first_name}
            <span className="text-gray-500 text-base">
              {' '}
              {data.parent_name}{' '}
            </span>
            {data.last_name}
          </>
        }
        classNames={listHeadingClasses}
        right={
          <Row gutter={[8,4]} className="w-full" justify="end">
            <Col lg={10} md={12} sm={24} xs={24}>
              <CreateNotificationForm employeeID={id} />
            </Col>

            <Col md={5} sm={12} xs={12}>
              <DeleteEmployee id={id} />
            </Col>

            <Col md={5} sm={12} xs={12}>
              <UpdateEmployeeForm employee={data} />
            </Col>
          </Row>
        }
      />
        <Table
          columns={columns}
          data={data}
        />
    </>
  );
};

export default EmployeeDetails;
