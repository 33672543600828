import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Employees from './Employees';
import EmployeeDetails from './EmployeeDetails';

const EmployeesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Employees />
      </Route>

      <Route path={`${path}/:id`}>
        <EmployeeDetails />
      </Route>
    </Switch>
  );
};

export default EmployeesRoutes;
