import React from 'react';
import { useAuth } from 'src/lib/auth';
import AuthRoutes from 'src/features/auth/routes';
import DashboardRoutes from './DashboardRoutes';

const AppRoutes = () => {
  const { user } = useAuth();

  const Routes = user ? DashboardRoutes : AuthRoutes;

  return <Routes />;
};

export default AppRoutes;
