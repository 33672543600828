import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import {
  getTravelWarrant,
  getControllerTravelWarrant,
  getServiceTravelWarrant,
} from '../api';
import UpdateTravelWarrantForm from '../components/UpdateTravelWarrantForm';
import DeleteTravelWarrant from '../components/DeleteTravelWarrant';
import { useParams, useLocation, Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { Space, Image, List, Divider, Card, Row, Col, Button } from 'antd';
import Loader from 'src/utils/Loader';
import { useTravelWarrantsUsers } from '../hooks/useTravelWarrantsUsers';
import { RBAC, ROLES } from 'src/lib/rbac';
import StatusModal from '../components/StatusModal';
import moment from 'moment';
import TravelWarrantDetailsPrint from '../components/TravelWarrantDetailsPrint';
import TravelWarrantDko from './TravelWarrantDko';
import TravelWarrantDkoDetails from './TravelWarrantDkoDetails';
import CreateTravelWarrantDko from '../components/CreateTravelWarrantDko';

const TravelWarrantDetails = () => {
  const componentRef = useRef();
  const { state } = useLocation();
  const { path } = useRouteMatch();
  const [printLoading, setPrintLoading] = useState(false)
  const {
    handleStatusSelect,
    getStatus,
    getType,
    getDataByUserRole,
    isModalVisible,
    isStatusLoading,
    handleCancel,
    handleOk,
    status,
    showActions,
  } = useTravelWarrantsUsers(state && state.openUpdateModal);

  const oilOptions = [
    { label: 'Motorno ulje', value: 'motor_oil' },
    { label: 'Hidrol ulje', value: 'hydrol_oil' },
    { label: 'Rashladna tečnost', value: 'cooling_liquid' },
    { label: 'ADBlue', value: 'adblue' },
    { label: 'Benzin', value: 'petrol' },
    { label: 'Dizel', value: 'diesel' },
    { label: 'CNG', value: 'cng' },
    { label: 'Drugo gorivo', value: 'other_fuel' }
  ];

  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ['travel_warrant', id],
    () =>
      getDataByUserRole(
        id,
        getTravelWarrant,
        getControllerTravelWarrant,
        getServiceTravelWarrant,
      ),
    {
      select: (res) => res.data,
    },
  );

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  const { employee, vehicle, crew, route, protocols, vehicle_liquids } = data;
  const time = () => {
    const date = new Date(data.created_at)
    const year = date.getFullYear()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let hours = date.getHours()
    let minutes = date.getMinutes()
    day = ("0" + day).slice(-2)
    month = ("0"+month).slice(-2)
    hours = ("0" + hours).slice(-2);
    minutes = ("0" + minutes).slice(-2);
    return `${day}.${month}.${year} - ${hours}:${minutes}`
  }
  const employeeData = [
    {
      title: 'Ime i prezime vozača: ',
      content: employee && employee.first_name + ' ' + employee.last_name,
    },
    {
      title: 'Ime i prezime ostalih članova postave: ',
      content: crew?.map(
        (item, index) =>
          (index ? ', ' : '') + item.first_name + ' ' + item.last_name,
      ),
    },
    {
      title: 'Relacija: ',
      content: route && route.description ? route.name +  ' - '  + route.description : route.name,
    },
    {
      title: 'Vreme: ',
      content: time()
    },
    {
      title: 'Tip prevoza: ',
      content: data.transportation_type,
    },
    {
      title: 'Broj bloka: ',
      content: data.block_number,
    },
    {
      title: 'Opština: ',
      content: data.municipality ? data.municipality.name ? data.municipality.name : "" : "" 
    },
    {
      title: 'Proizvođač otpada: ',
      content: data.waste_producer ? data.waste_producer.name ? data.waste_producer.name : "" : ""
    },
    {
      title: 'Tip otapda: ',
      content: data.waste_type ? data.waste_type.name ? data.waste_type.name : "" : ""
    },
    {
      title: 'Kilometraža početno stanje: ',
      content: data.start_mileage,
    },
    {
      title: 'Kilomteraža završno stanje: ',
      content: data.end_mileage,
    },
    {
      title: 'Radni sati početno stanje: ',
      content: data.start_operating_hours,
    },
    {
      title: 'Radni sati završno stanje: ',
      content: data.end_operating_hours,
    },
    {
      title: 'Radni sati nadogradnje početno stanje: ',
      content: data.start_upgrade_hours,
    },
    {
      title: 'Radni sati nadogradnje završno stanje: ',
      content: data.end_upgrade_hours,
    }
  ];

  const vehicleData = [
    {
      title: 'Šifra',
      content: vehicle?.unique_code,
    },
    {
      title: 'Registarski broj',
      content: vehicle?.licence_plate,
    },
    {
      title: 'Pogon',
      content: vehicle?.wheel_drive,
    },
    {
      title: 'Vrednost dimenzije',
      content: vehicle?.connected_dimensions,
    },
  ];

  const getLiquidTitle = type => {
    const liquid = oilOptions.filter(option => {
      return option.value === type;
    })

    return liquid[0].label;
  };
  let vehicleLiquidsData
  if (vehicle_liquids) {
    vehicleLiquidsData = vehicle_liquids.map(item => {
      const {type, quantity} = item;

      return {
        title: getLiquidTitle(type),
        content: quantity
      };
    });
  }


  const cardStyle = {
    head: {
      fontWeight: 'normal',
      fontSize: 14,
      padding: '0 10px',
      background: '#f9fafb',
    },
    body: {
      padding: '8px 10px',
      fontWeight: 600,
    },
  };

  const getLiqudData = vehicleLiquids => {
    let liquid_type = [];
    let quantity = [];
    let liquid_id = [];
    if (vehicleLiquids) {
      vehicleLiquids.map((liquid) => {
        liquid_type.push(liquid.type);
        quantity.push(liquid.quantity);
        liquid_id.push(liquid.id);
      });
    }

    return {liquid_type, quantity, liquid_id}
  };
 
  const prepareData = data => {
    const {vehicle_liquids} = data;

    return {
      ...data, 
      ...getLiqudData(vehicle_liquids)
    }
  };

  const listHeadingClasses = {
    main: "flex flex-col mb-2 md:flex-row md:justify-between md:items-center",
    right: "flex flex-wrap justify-center md:justify-end md:space-x-2",
  }
  console.log(data)
  return (
    <>
    <Switch>
      <Route exact path={`${path}`}>
      <div className="hidden">
        <TravelWarrantDetailsPrint ref={componentRef} data={data} />
      </div>
      <RBAC allowedRoles={[ROLES.CONTROLLER]}>
        <StatusModal
          isModalVisible={isModalVisible}
          isLoading={isStatusLoading}
          handleCancel={handleCancel}
          handleOk={handleOk}
          status={status}
        />
      </RBAC>

      <ListHeading
        classNames={listHeadingClasses}
        left={
          <Space>
            <div className="flex">
              <span className="mr-2">Tip:</span>
              {getType(data.type)}
            </div>
            /
            <div className="flex">
              <span className="mr-2">Status:</span>
              {getStatus(data.status)}
            </div>
          </Space>
        }
        right={
          <>
            {data.status === 'pending' ? (
              <RBAC allowedRoles={[ROLES.CONTROLLER]}>
                {handleStatusSelect(id)}
              </RBAC>
            ) : (
              ''
            )}

            <RBAC allowedRoles={[ROLES.ADMIN_JKP]}>
                {showActions(
                  ['disapproved', 'canceled', 'finished'],
                  data.status,
                ) && <DeleteTravelWarrant id={id} />}
                <Link to={`/travel-warrants/${id}/dko`} className="order-1 md:order-2 xs:w-2/6 sm:w-2/6 md:w-auto">
                  <Button className="text-white bg-blue-500 xs:w-full sm:w-full md:w-auto">DKO</Button>
                </Link>
                <ReactToPrint
                  trigger={() => <Button loading={printLoading} className="text-white bg-blue-500 xs:w-2/6 sm:w-2/6 md:w-auto order-2 md:order-3">Štampaj!</Button>}
                  content={() => componentRef.current}
                  onBeforeGetContent={() => {setPrintLoading(true)}}
                  onAfterPrint={() => {setPrintLoading(false)}}
                />
                {showActions(['canceled', 'finished'], data.status) && (
                  <UpdateTravelWarrantForm travelWarrant={prepareData(data)} 
                                           showModal={state && state.openUpdateModal}/>
                )}
            </RBAC>
          </>
        }
      />

      <Divider orientation="left">Podaci zaposlenih</Divider>

      <List
        header={
          <div className="text-right flex justify-center">
            <Image
              preview={false}
              alt="barcode"
              src={`data:image/png;base64,${data.qr_code}`}
            />
          </div>
        }
        footer={
          <div className="text-right">
            Odgovoran: <span className="font-semibold">{data.responsible}</span>
          </div>
        }
        bordered
        style={{ borderColor: '#f0f0f0' }}
        className="bg-gray-50"
        size="small"
        dataSource={employeeData}
        renderItem={(item) => (
          <List.Item>
            {item.title}
            <span className="font-semibold">{item.content}</span>
          </List.Item>
        )}
      />

      <Divider orientation="left">Podaci vozila</Divider>
          <Row gutter={[12,4]}>
          {
          vehicleData?.map(vehicle => (
            
                <Col xs={24} sm={12} md={6} key={vehicle.title}>
                  <Card
                  title={vehicle.title}
                  headStyle={cardStyle.head}
                  bodyStyle={cardStyle.body}
                  size="small">
                  <p>{vehicle.content}</p>
                  </Card>
                </Col>
            
          ))
          }
          </Row>

      <Divider orientation="left">Protokol o merenju</Divider>

      {protocols?.map((p) => {
        return (
          <Row gutter={[5, 15]} key={p.id}>
            <Col xl={3} md={6} sm={12} xs={12}>
              <Card
                title="Šifra kompanije"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{p.company_code}</p>
              </Card>
            </Col>

            <Col xl={3} md={6} sm={12} xs={12}>
              <Card
                title="Broj protokola"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{p.protocol_number}</p>
              </Card>
            </Col>

            <Col xl={3} md={6} sm={12} xs={12}>
              <Card
                title="Vreme ulaska"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{moment(p.entry_time).format('DD/MM/YYYY HH:mm')}</p>
              </Card>
            </Col>

            <Col xl={3} md={6} sm={12} xs={12}>
              <Card
                title="Vreme izlaska"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{moment(p.exit_time).format('DD/MM/YYYY HH:mm')}</p>
              </Card>
            </Col>

            <Col xl={2} md={6} sm={12} xs={12}>
              <Card
                title="Ulazna težina"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{p.entry_weight} kg</p>
              </Card>
            </Col>

            <Col xl={2} md={6} sm={12} xs={12}>
              <Card
                title="Izlazna težina"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{p.exit_weight} kg</p>
              </Card>
            </Col>

            <Col xl={4} md={6} sm={12} xs={12}>
              <Card
                title="Napomena"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{p.note}</p>
              </Card>
            </Col>

            <Col xl={4} md={6} sm={12} xs={12}>
              <Card
                title="Neto izmerena masa"
                size="small"
                headStyle={cardStyle.head}
                bodyStyle={cardStyle.body}>
                <p>{p.entry_weight - p.exit_weight} kg</p>
              </Card>
            </Col>
            <Divider dashed={true} />
          </Row>
        );
      })}

      <Divider orientation="left">Usute tečnosti</Divider>

      <List
        bordered
        style={{ borderColor: '#f0f0f0' }}
        className="bg-gray-50"
        size="small"
        dataSource={vehicleLiquidsData}
        renderItem={(item) => (
          <List.Item>
            {item.title}: 
            <span className="font-semibold"> {item.content}</span>
          </List.Item>
        )}
      />

      <Divider />
      </Route>

      <Route path={`${path}/dko`}>
          <TravelWarrantDko warrant={data}/>
      </Route>

      <Route path={`${path}/dko_create`} >
          <CreateTravelWarrantDko warrant={data} />
      </Route> 
      </Switch>
    </>
  );
};

export default TravelWarrantDetails;
