import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Points from './Points';
import PointDetails from './PointDetails';

const PointRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Points />
      </Route>

      <Route path={`${path}/:id`}>
        <PointDetails />
      </Route>
    </Switch>
  );
};

export default PointRoutes;
