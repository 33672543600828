import React from 'react';

export const FieldWrapper = ({ label, description, error, children }) => {
  return (
    <label className={`${label ? `block mb-4` : `inline-block`}`}>
      <span className="text-shadow">{label}</span>
      {description && (
        <div className="text-sm text-gray-500">{description}</div>
      )}
      {children}
      <div className="text-red-500 font-semibold text-xs">{error}</div>
    </label>
  );
};
