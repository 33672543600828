import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Form } from 'antd';
import { Checkbox as AntCheckbox } from 'antd';

import { FieldWrapper } from './FieldWrapper';

export const Checkbox = ({
  name,
  label,
  description,
  placeholder,
  type = 'text',
  rules,
  ...props
}) => {
  const { control, errors } = useFormContext();

  return (
    <FieldWrapper
      label={label}
      description={description}
      error={errors[name]?.message}>
      <Form.Item className="m-0">
        <Controller
          rules={rules}
          type={type}
          defaultValue=""
          name={name}
          label={label}
          control={control}
          placeholder={placeholder}
          render={({ onChange, onBlur, value }) => (
            <AntCheckbox
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
            />
          )}
          {...props}
        />
      </Form.Item>
    </FieldWrapper>
  );
};
