import React from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { queryClient } from 'src/lib/react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { createNotification } from '../api';
import { getLoggedUsers } from 'src/features/employees/api';
import openNotificationWithIcon from 'src/utils/notification';
import { SelectElement as Select } from 'src/components/Form/Select';
import { AntTextArea } from 'src/components/Form/TextArea';
import { SendOutlined } from '@ant-design/icons';
import {
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const CreateNotificationForm = ({ employeeID }) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(createNotification);

  const { data } = useQuery('loggedUsers', () => getLoggedUsers(), {
    select: (res) => res.data,
    initialData: { data: [] },
  });

  const employeesOptions = data?.map((employee) => ({
    label:
      employee.first_name +
      ' ' +
      employee.last_name +
      ' - ' +
      employee.employee_code,
    value: employee.id,
  }));

  const formMethods = useForm();

  const handleSubmit = async (data) => {
    const checkData = employeeID ? { ...data, id: employeeID } : data;
    try {
      await mutateAsync(checkData);
      queryClient.invalidateQueries('notifications');
      formMethods.reset();
      openNotificationWithIcon('success');
    } catch (error) {
      errorHandler(error);
    }
  };

  const formName = 'createNotification';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={
        <Button className="text-white bg-blue-500 xs:w-full sm:w-full" icon={<SendOutlined />}>
          Pošalji obaveštenje
        </Button>
      }
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Pošalji
        </Button>
      }
      title="Pošalji obaveštenje">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="title"
          label={<>{requiredFieldIcon} Naslov</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <AntTextArea
          name="body"
          label={<>{requiredFieldIcon} Sadržaj</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        {!employeeID && (
          <Select
            name="employee_ids"
            label={<>{requiredFieldIcon} Vozači</>}
            options={employeesOptions}
            labelInValue
            mode="multiple"
            rules={{
              required: {
                value: true,
              },
              validate: (value) => value.length || 'Obavezno polje',
            }}
            allowClear={false}
          />
        )}
      </Form>
    </FormDrawer>
  );
};

export default CreateNotificationForm;
