import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';

export const getLegalEntities = (params) => {
    return axios(`${API_URL}/admin/legal_entities`, { params }).catch((error) =>
      errorHandler(error),
    );
  };
  
export const getLegalEntity = (id) => {
    return axios(`${API_URL}/admin/legal_entities/${id}`).catch((error) =>
      errorHandler(error),
    );
  };

export const createLegalEntity = (data) => {
    return axios.post(`${API_URL}/admin/legal_entities`, data);
  };

export const updateLegalEntity = ({ id, data }) => {
  return axios.put(`${API_URL}/admin/legal_entities/${id}`, data);
};

export const deleteLegalEntity = (id) => {
    return axios.delete(`${API_URL}/admin/legal_entities/${id}`);
  };

export const importLegalEntities = (data) => {
  return axios.post(`${API_URL}/admin/legal_entities_import`, data,  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
});
};