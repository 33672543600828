import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getVehiclesGPS } from '../api';
import MapShell from '../components/MapShell';
import Marker from '../components/Marker';
import InfoBox from '../components/InfoBox';
import Polyline from '../components/Polyline';
// import VehicleHistory from '../components/VehicleHistory';
import Loader from 'src/utils/Loader';

const Map = () => {
  const [ids, setIds] = useState([]);
  const [vehicle, setVehicle] = useState([]); // when select checkbox in table
  const [history, setHistory] = useState([]); // when submit form with dates
  const [allVehicles, setAllVehicles] = useState([]);

  const { data, isLoading } = useQuery('GPS', () => getVehiclesGPS(), {
    refetchInterval: 10000,
    select: (res) => res.data,
    onSuccess: (res) => setAllVehicles(res?.data),
  });

  const clearMap = (form) => {
    form.reset();
    setVehicle([]);
    setHistory([]);
    setIds([]);
  };

  React.useEffect(() => {
    console.log('index ', {
      ids: ids,
      vehicle: vehicle,
      allVehicles: allVehicles,
    });
    if (allVehicles && allVehicles.length !== 0) {
      const selected = allVehicles.filter((v) => ids.includes(v.id));
      setVehicle(selected);
    }
  }, [data, ids]);

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <MapShell>
      <InfoBox
        data={data}
        ids={ids}
        vehicle={vehicle}
        setIds={setIds}
        setHistory={setHistory}
        clearMap={clearMap}
      />

      {ids.length > 0 && <Marker data={vehicle} />}

      <Polyline data={history} />

      {/* <VehicleHistory data={history} /> */}
    </MapShell>
  );
};

export default Map;
