import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Users from './Users';
import UserDetails from './UserDetails';

const UsersRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Users />
      </Route>

      <Route path={`${path}/:id`}>
        <UserDetails />
      </Route>
    </Switch>
  );
};

export default UsersRoutes;
