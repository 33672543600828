import React from 'react';
import { Marker as MapMarker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';

const Marker = ({ data = [] }) => {
  console.log('Marker ', data);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(null);
  const style = 'font-medium italic mb-1 mr-2 inline-block';

  const markers = data.map((vehicle) => {
    console.log('Marker 2', vehicle?.params);

    const parms = vehicle?.params;
    const lastPosition = parms.length - 1;

    return (
      <MapMarker
        position={{
          lat: parseFloat(parms[lastPosition].latitude),
          lng: parseFloat(parms[lastPosition].longitude),
        }}
        onMouseOver={() => {
          setIsHovered(vehicle.id);
          setIsOpen(true);
        }}
        onMouseOut={() => setIsOpen(false)}
        key={vehicle.id}>
        {isOpen && vehicle.id === isHovered && (
          <InfoWindow>
            <ul>
              <li>
                <span className={style}>Vozilo:</span>
                {vehicle.licence_plate}
              </li>
              <li>
                <span className={style}>Datum/Vreme:</span>
                {moment(parms[lastPosition].report_date).format(
                  'DD-MM-YYYY HH:mm',
                )}
              </li>
            </ul>
          </InfoWindow>
        )}
      </MapMarker>
    );
  });

  return markers;
};

export default Marker;
