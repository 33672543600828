import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Table } from 'antd';
import { getNotifications } from '../api';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import usePagination from 'src/hooks/usePagination';
import { options } from 'src/utils/tableConfig';
import CreateNotificationForm from '../components/CreateNotificationForm';
import { SearchOutlined } from '@ant-design/icons';

const columns = [
  { title: 'Naslov', dataIndex: 'title', width: '25%' },
  { title: 'Sadržaj', dataIndex: 'body' },
  {
    title: '',
    render: (entry) => (
      <Link
        className="align-middle inline-block p-1 text-gray-500"
        to={`/notifications/${entry.id}`}>
        <SearchOutlined />
      </Link>
    ),
    width: 50,
  },
];

const Notifications = () => {
  const { page, setPage, paginate, getPaginationProps } = usePagination();
  const listParams = { page, paginate };

  const { data, isLoading } = useQuery(
    ['notifications', listParams],
    () => getNotifications(listParams),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );

  const listHeadingClasses = {
    main: "flex flex-col mb-2 sm:flex-row md:justify-between sm:items-center",
    leftContainer: "flex mb-2 sm:mb-0 flex-col sm:flex-row sm:items-center w-full",
    right: "flex justify-center sm:justify-end w-full sm:w-6/12 lg:w-4/12 ",
    left: "mt-3 sm:ml-4 sm:mt-0 w-full"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading classNames={listHeadingClasses} title="Obaveštenja" right={<CreateNotificationForm />} />

      <Table
        {...options}
        columns={columns}
        dataSource={data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default Notifications;
