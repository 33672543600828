import React from 'react';
import { Modal } from 'antd';

const StatusModal = ({
  isModalVisible,
  isLoading,
  handleCancel,
  handleOk,
  status,
}) => {
  let statusText =
    status === 'approved' ? (
      <span className="bg-green-200 px-2">odobrite</span>
    ) : (
      <span className="bg-red-200 px-2">odbijete</span>
    );

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      okButtonProps={{
        className: 'text-white bg-red-500',
        danger: true,
        type: 'default',
      }}
      cancelButtonProps={{
        className: 'text-white bg-blue-500',
      }}
      okText="Potvrdi"
      cancelText="Otkaži">
      <span className="text-lg">
        Da li ste sigurni da želite da {statusText} status?
      </span>
    </Modal>
  );
};

export default StatusModal;
