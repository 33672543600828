import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Table, Button, Input, Row, Col, Divider, Tooltip } from 'antd';
import { getVehicles } from '../api';
import { Link } from 'react-router-dom';
import CreateVehicleForm from '../components/CreateVehicleForm';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import usePagination from 'src/hooks/usePagination';
import { options } from 'src/utils/tableConfig';
import { EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import DropdownSelect from 'src/lib/DropdownSelect';
import { useStorage } from 'src/hooks/useStorage';
import SearchOptions from 'src/utils/SearchOptions';

const columns = [
  { title: 'Garažni broj', dataIndex: 'unique_code', width: '5%' },
  { title: 'Registracioni broj', dataIndex: 'licence_plate' },
  { title: 'Tip vozila', dataIndex: 'type' },
  { title: 'Namena vozila', dataIndex: 'purpose' },
  { title: 'Marka vozila', dataIndex: 'manufacturer' },
  { title: 'Pripadnost direkciji', dataIndex: 'office' },
  { title: 'Datum prve registracije', dataIndex: 'first_registration' },
  { title: 'Datum isteka registracije', dataIndex: 'registration_end' },
  { title: 'Masa vozila', dataIndex: 'net_weight' },
  { title: 'Dozvoljena nosivost', dataIndex: 'load_capacity' },
  { title: 'Validiran', dataIndex: 'svo_validated', render: (entry) => (
    parseInt(entry, 10) === 1 ? "Da" : parseInt(entry, 10) === 0 ? "Ne" : "Došlo je do greške, svo_validated broj nije ispravan"
  ) },
  {
    title: '',
    render: (entry) => (
      <Link
        className="align-middle inline-block p-1 text-gray-500"
        to={`/vehicles/${entry.id}`}>
        <SearchOutlined />
      </Link>
    ),
    width: 40,
  },
];

const validationOptions = [
  {
    label: "Svi",
    value: ""
  },
  {
    label: "Validiran",
    value: "1"
  },
  {
    label: "Nije Validiran",
    value: "0"
  }
]

const Vehicles = () => {
  const {
    page,
    setPage,
    term,
    setTerm,
    paginate,
    getPaginationProps,
  } = usePagination();

  const listParams = { page, paginate, ...term };
  const {storage, vehiclesTerm} = useStorage()
  const [filterData, setFilterData] = useState(vehiclesTerm)

  const { data, isLoading } = useQuery(
    ['vehicles', listParams],
    () => getVehicles(listParams),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );
  const vehicleFilterExist = storage.get("vehiclesFilter")
  const handleInputChange = function(e) {
    setFilterData({...filterData, [e.target.name] : e.target.value})
  }

  const handleSelectChange = function(value, event) {
    setFilterData({...filterData, [event.name] : value.value})
  }

  const handleFormSubmit = function(e) {
    e.preventDefault()
    setPage(1);
    setTerm(filterData);
    storage.set("vehiclesFilter", JSON.stringify({ ...filterData }))
  }

  const clearFilterData = function() {
    if (!vehicleFilterExist) return
    setPage(1);
    setTerm(vehiclesTerm);
    setFilterData(vehiclesTerm);
    storage.remove("vehiclesFilter")
  }

  useEffect(() => {
    if (vehicleFilterExist && !isLoading) {
      const existingFilter = JSON.parse(storage.get("vehiclesFilter"))
      setTerm(existingFilter);
      setFilterData(existingFilter);
    }
  },[isLoading])

  const listHeadingClasses = {
    main: "flex flex-col mb-2 lg:flex-row md:justify-between lg:items-center",
    leftContainer: "flex mb-2 lg:mb-0 flex-col lg:flex-row lg:items-center w-full",
    right: "flex justify-center lg:justify-end w-full",
    left: "mt-3 lg:ml-4 lg:mt-0 w-full"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;
  
  return (
    <>
      <ListHeading
        title="Vozila"
        classNames={listHeadingClasses}
        left={
          <form onSubmit={handleFormSubmit}>
            <Row gutter={[8,4]}>

              <Col lg={10} sm={12} xs={24}>
                <Tooltip title="Garažni ili registarski broj" className="w-full" color="#666" >
                  <Input
                    value={filterData.licence_plate}
                    placeholder="Unesite garažni ili registarski broj"
                    onChange={handleInputChange}
                    name="licence_plate"
                  />
                </Tooltip>
              </Col>

              <Col lg={10} sm={12} xs={24}>
                <Tooltip title="Validan ili nevalidan" className="w-full" color="#666" >
                  <div className="dropdown-select-wrapper" >
                    <DropdownSelect name="svo_validated" 
                    placeholder="Izaberi..." 
                    options={validationOptions} 
                    onChange={handleSelectChange}
                    value={validationOptions.filter((option) => {
                      return option.value === filterData.svo_validated
                    })}
                    />
                  </div>
                </Tooltip>
              </Col>
             
              <Col xl={4} lg={4} sm={24} xs={24} className="flex justify-center lg:justify-start">
                  <SearchOptions onClear={clearFilterData} />
              </Col>
            
            <Col lg={0} sm={24} xs={24}>
              <Divider />
            </Col>
            </Row>
          </form>
        }
        right={
          
       <Row gutter={[8,4]} className="w-full mb-5 lg:mb-0" justify="end">
         <Col xs={12} sm={8} lg={6} >
          <Link to="/vehicles/reports" className="block w-full " >
            <Button className="text-white bg-blue-500 xs:w-full sm:w-full ">Izveštaji</Button>
          </Link>
          </Col>
          <Col xs={12} sm={8} lg={6}>
          <Link to="/map" className="block w-full">
            <Button
              className="text-white bg-blue-500 xs:w-full sm:w-full"
              icon={<EnvironmentOutlined />}>
              Mapa
            </Button>
          </Link>
          </Col>
          <Col xs={24} sm={8} lg={6}>
          <CreateVehicleForm />
          </Col>
        </Row>
        }
      />

      <Table
        {...options}
        columns={columns}
        dataSource={data.data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default Vehicles;
