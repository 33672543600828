import React from 'react'
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import { useParams } from 'react-router-dom';
import Loader from 'src/utils/Loader';
import Table from 'src/utils/Table';
import { Row, Col } from 'antd';
import { getLegalEntity } from '../api';
import DeleteLegalEntity from '../components/DeleteLegalEntity';
import UpdateLegalEntityForm from '../components/UpdateLegalEntityForm';


const columns = [
    {title: 'Naziv', dataIndex: 'name'},
    {title: 'PIB', dataIndex: 'pib'},
    {title: 'MB', dataIndex: 'mb'},
    {title:'Kupac', dataIndex: 'client'},
    {title:'Adresa Prostora', dataIndex: 'address' },
    {title:'Adresa Dostave Računa', dataIndex: 'billing_address'},
    {title:'Ukupna Površina', dataIndex: 'area'},
    {title: 'Šifra Opštine SON', dataIndex: 'son_municipality_code'},
    {title: 'Šifra Ulice SON', dataIndex: 'son_street_code'},
    {title:'Imanje Za Fakturu', dataIndex: 'invoice_property'},
    {title: 'Broj Fakture', dataIndex: 'invoice_number'},
    {title:'Šifra Imanja', dataIndex: 'property_code'},
    {title: 'Cenovna Grupa', dataIndex: 'price_group'},
]

function LegalEntityDetails() {
    const { id } = useParams();
    
    const { data, isLoading } = useQuery(['legal-entity', id], () => getLegalEntity(id), {
        select: (res) => res.data,
      });

      const listHeadingClasses = {
        main: "flex flex-col mb-2 md:flex-row xl:justify-between md:items-center",
        leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
        right: "flex justify-center md:justify-end w-full",
        left: "mt-3 md:ml-4 xl:mt-0 w-full"
      }
      
      if (isLoading) return <Loader />;
    
      if (!data) return <div className="text-lg">Nema podataka</div>;

    return (
        <>
            <ListHeading
                title={data.name}
                classNames={listHeadingClasses}
                right={
                <Row gutter={[8,4]} className="w-full" justify="end">
                   <Col md={8} sm={12} xs={12}>   
                    <DeleteLegalEntity id={id} />
                   </Col>
                   <Col md={8} sm={12} xs={12}>
                    <UpdateLegalEntityForm user={data} />
                   </Col>
                </Row>
                }
            />
            <Table columns={columns} data={data} />
        </>
    )
}

export default LegalEntityDetails
