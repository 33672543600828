import React from 'react';
import { Drawer, Button } from 'antd';

export const FormDrawer = ({
  triggerButton,
  showModal,
  submitButton,
  children,
  title,
  isDone,
  width = 600,
  additionalButton
}) => {
  const [visible, setVisible] = React.useState(showModal || false);

  React.useEffect(() => {
    if (isDone) {
      setVisible(false);
    }
  }, [isDone]);

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: () => setVisible(true) })}
      <Drawer
        title={title}
        width={width}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
        footer={
          <div className="text-right">
            <Button
              onClick={() => setVisible(false)}
              style={{ marginRight: 8 }}>
              Otkaži
            </Button>

            {submitButton}
            {additionalButton}
          </div>
        }>
        {children}
      </Drawer>
    </>
  );
};

export default FormDrawer;
