import React from 'react';
import Sidebar from './Sidebar';
import { useAuth } from 'src/lib/auth';
import { Card, Layout, Dropdown, Menu, Badge, Row, Col } from 'antd';
import { DownOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { RBAC, ROLES } from 'src/lib/rbac';
import { useGetAlerts } from 'src/features/alerts/hooks/useGetAlerts';
import { useDashboard } from './DashboardProvider';

const { Header, Content } = Layout;

const DashboardShell = (props) => {
  const { logout, user } = useAuth();
  const { pathname } = useLocation();
  let history = useHistory();
  const { isMenuOpen } = useDashboard();

  const { totalUnreadAlerts } = useGetAlerts();

  return (
    <Layout className="min-h-screen" style={{"paddingLeft": !isMenuOpen ? "160px" : "50px"}}>
      <Sidebar />

      <Layout>
        <Header className="bg-white h-auto leading-none px-4">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="flex justify-start items-center">
              {pathname.lastIndexOf('/') > 0 && (
                <span
                  className="cursor-pointer hover:text-blue-500"
                  onClick={() => history.goBack()}>
                  <CaretLeftOutlined className="text-xs text-gray-500" /> Nazad
                </span>
              )}
            </Col>

            <Col xl={20} lg={20} md={20} sm={20} xs={18} className="flex justify-end items-center flex-wrap">
             
                <RBAC allowedRoles={[ROLES.ADMIN_JKP]}>
                    <Badge count={totalUnreadAlerts} offset={[10, 0]} showZero>
                      <Link to="/alerts">Poruke</Link>
                    </Badge>
                </RBAC>
                
                <Dropdown
                  className="my-4 inline-block ml-7"
                  placement="bottomRight"
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <span onClick={logout}>Odjavi se</span>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/user-account">Moj nalog</Link>
                      </Menu.Item>
                    </Menu>
                  }>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}>
                    {user?.name} <DownOutlined />
                  </a>
                </Dropdown>

            </Col>
          </Row>
        </Header>

        <Content className="bg-white">
          <Card bodyStyle={{ padding: 10 }}>{props.children}</Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardShell;
