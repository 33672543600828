import React from 'react';
import { useQuery } from 'react-query';
import { Table } from 'antd';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import { options } from 'src/utils/tableConfig';
import { getAlerts } from '../api';
import usePagination from 'src/hooks/usePagination';
import { useGetAlerts } from '../hooks/useGetAlerts';

const Alerts = () => {
  const { handleToogle, ifAdminRunQuery } = useGetAlerts();

  const columns = [
    {
      title: 'Vozač',
      render: ({ employee }) =>
        employee
          ? employee.first_name +
            '  ' +
            employee.last_name +
            ' - ' +
            employee.employee_code
          : '',
      width: '25%',
    },
    { title: 'Naslov', dataIndex: 'address', width: '30%' },
    { title: 'Sadržaj', dataIndex: 'description' },
    {
      title: 'Pročitana',
      render: (item) => handleToogle({ id: item.id, read: item.read }),
      width: '10%',
    },
  ];

  const { page, setPage, paginate, getPaginationProps } = usePagination();
  const listParams = { page, paginate };

  const { data, isLoading } = useQuery(
    ['alerts', listParams],
    () => getAlerts(listParams),
    {
      enabled: ifAdminRunQuery,
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading title="Poruke" />

      <Table
        {...options}
        columns={columns}
        dataSource={data.data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default Alerts;
