import { Button } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import { updatePoint } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import {
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const UpdatePointForm = ({ point }) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(updatePoint);
  const formMethods = useForm();

  React.useEffect(() => {
    formMethods.reset(point);
  }, [point]);

  const handleSubmit = async (data) => {
    try {
      await mutateAsync({ data, id: point.id });
      queryClient.invalidateQueries('points');
      queryClient.invalidateQueries('point');
      formMethods.reset();
      openNotificationWithIcon('success');
    } catch (error) {
      errorHandler(error);
    }
  };

  const formName = 'updatePoint';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      triggerButton={<Button className="text-white bg-blue-500 xs:w-full sm:w-full">Izmeni</Button>}
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Izmeni
        </Button>
      }
      title="Izmeni podatke">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <Input
          name="point_number"
          label={<>{requiredFieldIcon} Redni broj</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
            maxLength: {
              value: 10,
              message: 'Broj karaktera ne sme biti veći od 10!',
            },
          }}
          type="number"
        />

        <Input
          name="municipality_code"
          label={<>{requiredFieldIcon} Šifra opštine</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input name="street" label="Ulica" />
        <Input name="street_code" label="Šifra ulice" />
        <Input name="street_number" label="Kućni broj" />

        <Input
          name="collective_point_code"
          label={<>{requiredFieldIcon} Šifra sabirnog mesta</>}
          rules={{
            required: {
              value: true,
              message: 'Obavezno polje',
            },
          }}
        />

        <Input name="near_destination" label="Bliže odredište" />

        <Input
          name="metal"
          label="Metal"
          type="number"
          rules={{
            maxLength: {
              value: 11,
              message: 'Broj karaktera ne sme biti veći od 11!',
            },
          }}
        />

        <Input
          name="plastic"
          label="Plastika"
          type="number"
          rules={{
            maxLength: {
              value: 11,
              message: 'Broj karaktera ne sme biti veći od 11!',
            },
          }}
        />

        <Input name="container_code" label="Šifra kontejnera" />
        <Input name="volume_code" label="Šifra zapremine" />
        <Input name="waste_code" label="Šifra otpada" />
        <Input name="erp_code" label="Šifra za ERP" />

        <Input
          name="longitude"
          label={
            <>
              Geografska dužina (Longitude) -{' '}
              <a
                href="https://www.latlong.net/"
                className="text-blue-300"
                target="_blank">
                latlong.net
              </a>
            </>
          }
          type="number"
          rules={{
            pattern: {
              value: /^-?[0-9]{1,3}(?:\.[0-9]{1,10})?$/,
              message: 'Koordinate nisu validne!',
            },
          }}
        />

        <Input
          name="latitude"
          label="Geografska širina (Latitude)"
          type="number"
          rules={{
            pattern: {
              value: /^-?[0-9]{1,3}(?:\.[0-9]{1,10})?$/,
              message: 'Koordinate nisu validne!',
            },
          }}
        />
      </Form>
    </FormDrawer>
  );
};

export default UpdatePointForm;
