import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getNotifications = (params) => {
  return axios(`${API_URL}/admin/notifications`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getNotification = (id) => {
  return axios(`${API_URL}/admin/notifications/${id}`).catch((error) =>
    errorHandler(error),
  );
};

export const createNotification = (data) => {
  return axios.post(`${API_URL}/admin/notifications/push`, data);
};

export const deleteNotification = (id) => {
  return axios.delete(`${API_URL}/admin/notifications/${id}`);
};
