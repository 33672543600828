import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getPoints = (params) => {
  return axios(`${API_URL}/admin/points`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getPoint = (id) => {
  return axios(`${API_URL}/admin/points/${id}`).catch((error) => errorHandler(error));
};

export const createPoint = (data) => {
  return axios.post(`${API_URL}/admin/points`, data);
};

export const updatePoint = ({ id, data }) => {
  return axios.put(`${API_URL}/admin/points/${id}`, data);
};

export const deletePoint = (id) => {
  return axios.delete(`${API_URL}/admin/points/${id}`);
};

export const getAllPoints = () => {
  return axios(`${API_URL}/admin/points_all`).catch((error) => errorHandler(error));
};
