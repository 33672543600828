import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getReports = (params) => {
  return axios(`${API_URL}/admin/reports`, { params }).catch((error) => errorHandler(error));
};

export const getCompanies = () => {
  return axios(`${API_URL}/admin/companies`).catch((error) => errorHandler(error));
} 