import React, { useRef } from 'react';
import { Button, Alert, Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import { updateUserAccount } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import {
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const UpdateUserAccountForm = ({ user }, showModal) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(updateUserAccount);
  const formMethods = useForm({
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
  });

  const handleSubmit = async (data) => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries('user-account');
      formMethods.reset();
      openNotificationWithIcon('success');
      user.account_verified = 1
    } catch (error) {
      errorHandler(error);
    }
  };

  const isDisabled = user?.role !== 'admin_jkp';
  const formName = 'updateUserAccount';

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      showModal={showModal}
      triggerButton={<Button className="text-white bg-blue-500">Izmeni</Button>}
      submitButton={
        <Button
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Izmeni
        </Button>
      }
      title="Izmeni podatke"
      >
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}
        {user.account_verified === 0 &&
        <Alert showIcon={true} message="Promenite šifru koju ste dobili od administratora." type="warning" />}
        <Row gutter={[12,4]}>

          <Col span={24}> 
            <Input name="name" label="Ime" disabled={isDisabled} />
          </Col>

          <Col span={24}>
            <Input
              name="email"
              type="email"
              label="Email"
              disabled={isDisabled}
              rules={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Email nije validan!',
                },
              }}
            />
          </Col>
          
          <Col span={24}>
            <Input
              name="password"
              label={<>{requiredFieldIcon} Šifra </>}
              rules={{
                required: {
                  value: true,
                  message: 'Obavezno polje',
                },
                minLength: {
                  value: 8,
                  message: 'Broj karaktera ne sme biti manji od 8!',
                },
              }}
              type="password"
            />
          </Col>
              
          <Col span={24}>
            <Input
              name="password_confirmation"
              label="Potvrdi šifru"
              rules={{
                validate: (value) =>
                  value === formMethods.watch('password') ||
                  'Lozinke se ne poklapaju!',
              }}
              type="password"
            />
          </Col>
        </Row>
      </Form>
    </FormDrawer>
  );
};

export default UpdateUserAccountForm;
