import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Logs from './Logs';
import LogDetails from './LogDetails';

const LogsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Logs />
      </Route>

      <Route path={`${path}/:id`}>
        <LogDetails />
      </Route>
    </Switch>
  );
};

export default LogsRoutes;
