import React from 'react';
import { useQuery } from 'react-query';
import { getAllEmployees, getMunicipalities, getWasteProducers, getWasteTypes } from '../api';
import { getAllVehicles } from '../api';
import { getAllRoutes } from '../api';

export const useTravelWarrantFormData = (formMethods) => {

  const { data: employees, isLoading: isLoadingEmployees } = useQuery(
    'all_employees',
    () => getAllEmployees(),
    {
      select: (res) => res.data,
      initialData: { data: [] },
    },
  );

  const { data: vehicles, isLoading: isLoadingVehicles } = useQuery(
    'all_vehicles',
    () => getAllVehicles(),
    {
      select: (res) => res.data,
      initialData: { data: [] },
    },
  );

  const { data: routes, isLoading: isLoadingRoutes } = useQuery(
    'all_routes',
    () => getAllRoutes(),
    {
      select: (res) => res.data,
      initialData: { data: [] },
    },
  );

  const {data: municipalities, isLoading:isLoadingMunicipalities} = useQuery('municipalities', () => getMunicipalities(), {
    select: (res) => res.data,
    initialData: {data:[]}
  })

  const {data: waste_producers, isLoading:isLoadingWasteProducers} = useQuery('waste_producers', () => getWasteProducers(), {
    select: (res) => res.data,
    initialData: { data: [] }
  })
  
  const {data: waste_types, isLoading:isLoadingWasteTypes} = useQuery('waste_types', () => getWasteTypes(), {
    select: (res) => res.data,
    initialData: {data:[]}
  })

  // Derived data:

  const employee_id = formMethods.watch('employee_id');
  const vehicle_id = formMethods.watch('vehicle_id');
  const route_id = formMethods.watch('route_id');
  const employeesOptions = employees?.filter(employee => employee.svo_validated === 1 && employee.employee_type === "driver").map((employee) => ({
    label:
      employee.parent_name ?
      employee.first_name +
      ' ' +  employee.parent_name + ' ' +
      employee.last_name +
      ' - ' +
      employee.employee_code
      :
      employee.first_name +
      ' ' + 
      employee.last_name +
      ' - ' +
      employee.employee_code,
    value: employee.id,
  }));
  
  const crewOptions = employees?.filter(employee => employee.employee_type === "employee").map((employee) => ({
    label:
      employee.first_name +
      ' ' +
      employee.last_name +
      ' - ' +
      employee.employee_code,
    value: employee.id,
  }));


  const vehiclesOptions = vehicles?.filter(vehicle => vehicle.svo_validated === 1).map((vehicle) => ({
    label: vehicle.unique_code + ' - ' + vehicle.licence_plate,
    value: vehicle.id,
  }));

  const routesOptions = routes?.map((route) => ({
    label: route.name,
    value: route.id,
  }));

  const municipalityOptions = municipalities?.map(municipality => ({
    label: municipality.name,
    value: municipality.id
  }))

  const wasteProducersOptions = waste_producers?.map(waste_producer => ({
    label: waste_producer.name,
    value: waste_producer.id
  }))

  const wasteTypesOptions = waste_types?.map(waste_type => ({
    label: waste_type.name,
    value: waste_type.id
  }))

  const selectedVehicle = vehicles?.find((v) => v.id === vehicle_id);

  React.useEffect(() => {
    formMethods.setValue('unique_code', selectedVehicle?.unique_code || '');
    formMethods.setValue('licence_plate', selectedVehicle?.licence_plate || '');
    formMethods.setValue('wheel_drive', selectedVehicle?.wheel_drive || '');
    formMethods.setValue('transportation_type', selectedVehicle?.type || '');
    formMethods.setValue('load_capacity', selectedVehicle?.load_capacity || '');
    formMethods.setValue('office', selectedVehicle?.office || '');
    formMethods.setValue(
      'connected_dimensions',
      selectedVehicle?.connected_dimensions || '',
    );
  }, [selectedVehicle, formMethods, vehicles]);

  const selectedEmployee = employees?.find((e) => e.id === employee_id)


  React.useEffect(() => {
    formMethods.setValue('route_id', selectedEmployee?.route_id || '');
  }, [selectedEmployee, employees]);

  const typeOptions = [
    { label: 'Redovan', value: 'regular' },
    { label: 'Vanredan', value: 'irregular' },
  ];

  const oilOptions = [
    { label: 'Motorno ulje', value: 'motor_oil' },
    { label: 'Hidrol ulje', value: 'hydrol_oil' },
    { label: 'Rashladna tečnost', value: 'cooling_liquid' },
    { label: 'ADBlue', value: 'adblue' },
    { label: 'Benzin', value: 'petrol' },
    { label: 'Dizel', value: 'diesel' },
    { label: 'CNG', value: 'cng' },
    { label: 'Drugo gorivo', value: 'other_fuel' }
  ];

  return {
    employeesOptions,
    crewOptions,
    vehiclesOptions,
    routesOptions,
    typeOptions,
    oilOptions,
    municipalityOptions,
    wasteProducersOptions,
    wasteTypesOptions,
    isLoadingEmployees,
    isLoadingRoutes,
    isLoadingVehicles,
    isLoadingMunicipalities,
    isLoadingWasteProducers,
    isLoadingWasteTypes
  };
};
