import { Space, DatePicker, Table, Button, Row, Col, Divider } from 'antd'
import Form from 'antd/lib/form/Form'
import React, { useState, useEffect } from 'react'
import { useStorage } from 'src/hooks/useStorage';
import usePagination from 'src/hooks/usePagination';
import { ListHeading } from 'src/components/ListHeading'
import DropdownSelect from 'src/lib/DropdownSelect'
import { useQuery } from 'react-query';
import { getAllVehicles, getReports } from '../api'
import { options } from 'src/utils/tableConfig';
import moment from 'moment';
import openNotificationWithIcon from 'src/utils/notification';
import Loader from 'src/utils/Loader';
import { API_URL } from 'src/config';
import axios from 'axios';
import { errorHandler } from 'src/utils/Error';
import utilsStorage  from 'src/utils/storage';
import SearchOptions from 'src/utils/SearchOptions';



function VehicleReports() {
    const { storage, vehicleReportsTerm } = useStorage();
    const {
        page,
        setPage,
        term,
        setTerm,
        paginate,
        getPaginationProps,
      } = usePagination();
    const listParams = { page, paginate, ...term };
    const { RangePicker } = DatePicker;
    const [filterData, setFilterData] = useState(vehicleReportsTerm)
    const [exportFilter, setExportFilter] = useState(vehicleReportsTerm)
    const [isExcelLoading, setIsExcelLoading] = useState(false)
    const [isCsvLoading, setIsCsvLoading] = useState(false)
  
    const vehicleFilterReportsExist = storage.get('vehicleFilterReports')
    const { data, isLoading } = useQuery(
        ['reports', listParams],
        () => getReports(listParams),
        {
          select: (res) => res.data,
          onSuccess: ({ current_page, last_page } = {}) =>
            current_page > last_page ? setPage(last_page) : 1,
        },
      );
    const {data: vehicles, isLoading: isVehiclesLoading} = useQuery('all_report_vehicles', getAllVehicles, {
        select: res => res.data,
        initialData: { data: [] }
    })
    
    const vehiclesOptions = vehicles?.map((vehicle) => ({
        label: vehicle.unique_code + ' - ' + vehicle.licence_plate,
        value: vehicle.id,
    }));
    const typeOptionsDuplicate = vehicles?.map((vehicle) => ({
            label: vehicle.type,
            value: vehicle.type
        })
    )

    const seenTypes = new Set()
    
    const typeOptions = typeOptionsDuplicate?.filter(type => {
        const duplicate = seenTypes.has(type.label) 
        seenTypes.add(type.label)
        return !duplicate
    })

    const handleRangeChange = function(dates, dateStrings) {
        setFilterData({...filterData, "start_date": dateStrings[0], "end_date":dateStrings[1]})
    }
    const handleSelectChange = function(value, event) {
        setFilterData({...filterData, [event.name] : value.value})
    }

    const handleFilterFormSubmit = function(e) {
        e.preventDefault();
        setExportFilter(filterData)
        setPage(1);
        setTerm({ ...filterData });
        storage.set('vehicleFilterReports', JSON.stringify({ ...filterData }));
      }

    const clearReportFilter = function() {
        if (!vehicleFilterReportsExist) return;
        setPage(1);
        setTerm(vehicleReportsTerm);
        setFilterData(vehicleReportsTerm);
        setExportFilter(vehicleReportsTerm)
        storage.remove('vehicleFilterReports');
    }

    const handleExportClick = function(type, loading) {
        const token = utilsStorage.getToken()
        if (token && type) {
          loading(true)
            axios({
              method: "GET",
              url: `${API_URL}/admin/reports-export?report_type=${exportFilter.report_type}&start_date=${exportFilter.start_date}&end_date=${exportFilter.end_date}&export_type=${type}`,
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${token}`
              } 
            })
            .then(res => {
              const windowUrl = window.URL.createObjectURL(res.data)
              const a = document.createElement('a')
              a.href = windowUrl
              a.download = `${exportFilter.report_type && (exportFilter.report_type + ' ')}${(exportFilter.start_date && exportFilter.end_date) && (exportFilter.start_date + '_' + exportFilter.end_date + ' ')}izveštaj.${type}`
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              window.URL.revokeObjectURL(windowUrl)
              loading(false)
            }).catch(err => {
              errorHandler(err)
              loading(false)
            })
        }
      }
    useEffect(() => {
        if (vehicleFilterReportsExist && !isLoading) {
            const existingFilter = JSON.parse(storage.get('vehicleFilterReports'))
            setFilterData(existingFilter)
            setTerm(existingFilter);
        }
      }, [isLoading])

      const listHeadingClasses = {
        main: "flex flex-col mb-2 xl:flex-row xl:justify-between xl:items-center",
        leftContainer: "flex mb-2 xl:mb-0 flex-col xl:flex-row xl:items-center w-full",
        right: "flex justify-center xl:justify-end w-full xl:w-7/12  ",
        left: "mt-3 xl:ml-4 xl:mt-0 w-full",
        title: "text-2xl min-w-max"
      }
    if (isLoading || isVehiclesLoading) return <Loader />

    return (
        <>
         <ListHeading 
            title="Izveštaji-vozila"
            classNames={listHeadingClasses}
            left={
                <form onSubmit={handleFilterFormSubmit}>
                    <Row gutter={[8,4]} className="w-full">
           
                        <Col xl={6}  md={12} sm={24} xs={24}>
                        <div className="dropdown-select-wrapper">
                        <DropdownSelect 
                            name="vehicle"
                            options={isVehiclesLoading ? {label:"Učitavanje", value:""} : vehiclesOptions}
                            placeholder="Izaberi vozilo..."
                            onChange={handleSelectChange}
                            value={vehiclesOptions.filter((option) => {
                                return option.value === filterData.vehicle
                            })}
                            onFocus={() => {
                                if (vehiclesOptions.length < 1) {
                                  openNotificationWithIcon('info', {
                                    message: 'Napravite vozilo u sekciji "Vozila"!',
                                  });
                                }
                            }}
                        />
                        </div>
                        </Col>
                        <Col xl={7}  md={12} sm={24} xs={24}>
                        <div className="dropdown-select-wrapper">
                          <DropdownSelect 
                            name="vehicle_type"
                            options={isVehiclesLoading ? {label:"Učitavanje", value:""} : typeOptions}
                            placeholder="Tip izveštaja"
                            onChange={handleSelectChange}
                            value={typeOptions.filter((option) => {
                                return option.value === filterData.vehicle_type
                            })}
                            onFocus={() => {
                                if (typeOptions.length < 1) {
                                  openNotificationWithIcon('info', {
                                    message: 'Napravite vozilo u sekciji "Vozila"!',
                                  });
                                }
                            }}
                        />
                        </div>
                        </Col>
                        <Col xl={8} md={24} sm={24} xs={24}>
                        {filterData.start_date !== "" ?
                        <RangePicker value={[moment(filterData.start_date, 'YYYY-MM-DD'), moment(filterData.end_date, 'YYYY-MM-DD')]} onChange={handleRangeChange} className="w-full" />
                        :
                        <RangePicker onChange={handleRangeChange} className="w-full" />
                        }
                        </Col>
                        <Col xl={3} md={24} sm={24} xs={24} className="flex justify-center">
                            <SearchOptions onClear={clearReportFilter} />
                        </Col>
                        <Col xl={0} sm={24} xs={24}>
                            <Divider />
                        </Col>
                
                  
                    </Row>
                </form>
            }

            right= {
                <Row gutter={[8,4]} className="w-full xl:mb-0 sm:mb-5 xs:mb-5" justify="end">
                    <Col xl={6} sm={12} xs={12}>
                     <Button loading={isExcelLoading} onClick={() => {handleExportClick('xlsx', setIsExcelLoading)}} className="xs:w-full sm:w-full text-white bg-blue-500" >Excel</Button>
                    </Col>
                    <Col xl={6} sm={12} xs={12}>
                     <Button loading={isCsvLoading} onClick={() => {handleExportClick('csv', setIsCsvLoading)}} className="xs:w-full sm:w-full text-white bg-blue-500">CSV</Button>
                    </Col>
                </Row>
              }
         />
        <Table {...options} columns={data.header} dataSource={data.body}  pagination={getPaginationProps({ total: data.total })} summary={() => {return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={data.header.length}>Ukupna Težina: <span className="font-bold">{data.footer.total_weight}</span></Table.Summary.Cell>
            </Table.Summary.Row>
          )}}/>
            

        </>
    )
}

export default VehicleReports
