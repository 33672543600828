import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

const SearchOptions = ({ onSearch, onClear, formName }) => {
  return (
    <>
      <Tooltip title="Pretraži" mouseEnterDelay={0} color="#666">
        <Button
          className="text-gray-400 hover:text-blue-500 hover:border-blue-500 align-top"
          onClick={onSearch}
          htmlType="submit"
          form={formName && formName}>
          <SearchOutlined />
        </Button>
      </Tooltip>

      <Tooltip title="Poništi" mouseEnterDelay={0} color="#666">
        <Button
          className="text-gray-400 hover:text-red-500 hover:border-red-500 align-top"
          onClick={onClear}>
          <CloseOutlined />
        </Button>
      </Tooltip>
    </>
  );
};

export default SearchOptions;
