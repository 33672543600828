import React, {useRef, useState, useMemo} from 'react'
import ReactToPrint from 'react-to-print'
import { ListHeading } from 'src/components/ListHeading'
import TravleWarrantDkoPrint from '../components/TravelWarrantDkoPrint'
import { Button, Switch } from 'antd'
import { convertMergedLetters, latinToCyrMixed, latinToCyrCapital, latinToCyr } from '../components/latinToCyrVars'
import {  getTravelWarrantDkoDetails, searchLegalEntities, updateTravelWarrantDko } from '../api'
import Loader from 'src/utils/Loader'
import { useMutation, useQuery } from 'react-query';
import { useForm } from 'react-hook-form'
import { SelectElement as Select } from 'src/components/Form/Select';
import { Form, Input } from 'src/components/Form';
import openNotificationWithIcon from 'src/utils/notification'
import { queryClient } from 'src/lib/react-query';
import { errorHandler } from 'src/utils/Error'
import { useParams } from 'react-router'
import NotFoundLegalEntitiesSelect from '../components/NotFoundLegalEntitiesSelect'

function TravelWarrantDkoDetails({warrant}) {
    const componentRef = useRef();
    const [printLoading, setPrintLoading] = useState(false)
    const [isLoadedOnce, setIsLoadedOnce] = useState(false)
    const [cyrEnabled, setCyrEnabled] = useState(true)
    const [legalTerm, setLegalTerm] = useState("")
    const formMethods = useForm();
    const { mutateAsync, isLoading:isCreateLoading } = useMutation(updateTravelWarrantDko);
    const params = useParams()
  
    const {data:detailsData, isLoading:detailsIsLoading} = useQuery('travel_warrant_dko_details', () => getTravelWarrantDkoDetails(params.id), {select:res => res.data})
    
    const { data:legalEntities , isLoading:isLegalLoading, } = useQuery(['search_legal_entities', legalTerm], () => searchLegalEntities(legalTerm), {enabled:Boolean(legalTerm) ,select: res => res.data.data})
    
    const legalEntityOptions = useMemo(() => {
        if (detailsData && !isLoadedOnce) {
            setIsLoadedOnce(true)
            const legalArray = []
            legalArray.push ({
                label: detailsData.legal_entity_name,
                value: detailsData.legal_entity_id
            })
            return legalArray
        } else {
            const options = legalEntities?.map((legalEntity) => {                    
                return{
                  label: legalEntity.name,
                  value: legalEntity.id
              }}) 
            return options
        }
     
    }, [legalEntities, detailsData])
    const handleInputChange = (e) => {
       if (cyrEnabled) {
            const start = e.target.selectionStart
            const end = e.target.selectionEnd
            const val = e.target.value
            if (!e.ctrlKey && !e.altKey && !e.metaKey && e.key !== "Dead") {
                if (e.getModifierState('CapsLock')) {
                    if (e.shiftKey) {
                        for (let j = 0; j < Object.keys(latinToCyr).length; j++) {
                            if (e.code === Object.keys(latinToCyr)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyr)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    } else {
                        for (let j = 0; j < Object.keys(latinToCyrCapital).length; j++) {
                            if (e.code === Object.keys(latinToCyrCapital)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyrCapital)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    }
                    
                } else {
                    if (!e.shiftKey) {
                        for (let j = 0; j < Object.keys(latinToCyr).length; j++) {
                            if (e.code === Object.keys(latinToCyr)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyr)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    } else {
                        for (let j = 0; j < Object.keys(latinToCyrCapital).length; j++) {
                            if (e.code === Object.keys(latinToCyrCapital)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyrCapital)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    }
                }
            } 
       }
       formMethods.setValue(e.target.name, e.target.value)
    }

    const handleInputPaste = (e) => {
        if (cyrEnabled) {
            e.preventDefault()
            const start = e.target.selectionStart
            const end = e.target.selectionEnd
            let val = e.target.value
            let paste = (e.clipboardData || window.clipboardData).getData('text')
            for (let i = 0; i < paste.length; i++) {
                for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                    if(paste[i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                        paste = paste.split(paste[i]).join(Object.values(latinToCyrMixed)[j])
                    }
                }
            }
           
            e.target.value = val.slice(0, start) + paste + val.slice(end)
            e.target.value = convertMergedLetters(e.target.value)
            e.target.selectionStart = e.target.selectionEnd = start + paste.length;
        }
        formMethods.setValue(e.target.name, e.target.value)
    }

    const onSubmit = async (data) => {
        try {
            const response = await mutateAsync({id : detailsData.id, data})
            queryClient.invalidateQueries('travel-warrant-all-dko')
            queryClient.invalidateQueries('travel_warrant_dko_details')
            openNotificationWithIcon('success')
        }
        catch (error) {
            errorHandler(error);
        }
    };
    
    const legal_entitiy_id = formMethods.watch('legal_entity_id');
    const selectedLegalEntity = legalEntities && legalEntities?.find((l) => l.id ===  legal_entitiy_id)

      React.useEffect(() => {
        if (selectedLegalEntity) {
            for (const key in selectedLegalEntity) {
                if (selectedLegalEntity[key] && key !== "name") {
                    for (let i = 0; i < selectedLegalEntity[key].length; i++) {
                        for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                            if(selectedLegalEntity[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                                selectedLegalEntity[key] = selectedLegalEntity[key].split(selectedLegalEntity[key][i]).join(Object.values(latinToCyrMixed)[j])
                            }
                        }
                    }
                }
            }
            for (const key in selectedLegalEntity.municipality) {
                if (selectedLegalEntity.municipality[key]) {
                    for (let i = 0; i < selectedLegalEntity.municipality[key].length; i++) {
                        for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                            if(selectedLegalEntity.municipality[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                                selectedLegalEntity.municipality[key] = selectedLegalEntity.municipality[key].split(selectedLegalEntity.municipality[key][i]).join(Object.values(latinToCyrMixed)[j])
                            }
                        }
                    }
                }
            }
        }
        formMethods.setValue('legal_entity_municipality', selectedLegalEntity?.municipality?.name || detailsData?.legal_entity_municipality || "")
        formMethods.setValue('legal_entity_city', selectedLegalEntity?.city || detailsData?.legal_entity_city || "")
        formMethods.setValue('legal_entity_zip_code', selectedLegalEntity?.zip_code || detailsData?.legal_entity_zip_code || "")
        formMethods.setValue('legal_entity_address', selectedLegalEntity?.address || detailsData?.legal_entity_address || "")
        formMethods.setValue('legal_entity_phone', selectedLegalEntity?.phone || detailsData?.legal_entity_phone || "")
        formMethods.setValue('legal_entitiy_fax', selectedLegalEntity?.fax || detailsData?.legal_entity_fax || "")
        formMethods.setValue('legal_entity_name', selectedLegalEntity?.name || detailsData?.legal_entity_name || "")
      }, [formMethods, selectedLegalEntity, legalEntities, detailsData])

    let delaySearch

    const handleSelectSearch = (value) => {  
        clearTimeout(delaySearch)
        delaySearch = setTimeout(function() {
            setLegalTerm(value)
        },300) 
    }
    React.useEffect(() => {
        return () => {
            setIsLoadedOnce(false)
            clearTimeout(delaySearch)
        }
    }, [])

    const listHeadingClasses = {
        main: "flex flex-col mb-2 md:flex-row md:justify-between md:items-center",
        leftContainer: "flex items-center xs:flex-col xs:items-start sm:mb-3 md:mb-0",
        right: "flex justify-center md:justify-end md:w-auto",
        left: "ml-4 xs:my-3 xs:ml-0",
        title: "md:text-2xl sm:text-xl xs:text-xl md:max-w-2xl"
      }

      const formName = "dkoForm"
      
    if (detailsIsLoading) return <Loader />;

    return (
        <>
         <ListHeading classNames={listHeadingClasses} title={ detailsData ? detailsData.legal_entity_name && <>DKO - <span className="text-lg text-gray-500">{detailsData.legal_entity_name}</span></> :  "Dokument o Kretanju Otapada"} right={
               <ReactToPrint
                trigger={() => <Button loading={printLoading} className="text-white bg-blue-500 xs:w-full sm:w-full md:w-auto order-2">Štampaj!</Button>}
                content={() => componentRef.current}
                onBeforeGetContent={() => {setPrintLoading(true)}}
                onAfterPrint={() => {setPrintLoading(false)}}
                />      
         } 
         
         left={
            <div className="flex">
                <Switch checked={cyrEnabled} onChange={() => {setCyrEnabled(!cyrEnabled)}} className="m-auto block"  />
                <div onClick={() => {setCyrEnabled(!cyrEnabled)}} className="text-xl cursor-pointer ml-1 mr-2">Ћирилица</div>
            </div>
        }
         />
        <div className="hidden">
            <TravleWarrantDkoPrint ref={componentRef} data={detailsData} />
        </div>

        <Form id={formName} formMethods={formMethods}  onSubmit={onSubmit} className="flex flex-col space-y-2">
            <Input className="hidden" defaultValue={warrant.id} name="travel_warrant_id" />

            <div className="text-xl">Део Б - Произвођач/власник отпада </div>
            <label className="text-lg">1. Назив произвођача/власника</label>
            <label className="text-sm">Претражите правна лица </label>
            <div className="dropdown-select-wrapper">
                <Select allowClear={false} name="legal_entity_id" options={legalEntityOptions} defaultValue={ detailsData && detailsData.legal_entity_id } labelInValue={true}
                onSearch={handleSelectSearch} notFoundContent={<NotFoundLegalEntitiesSelect loading={isLegalLoading}/>}
               
                />
                  <Input className="hidden" name="legal_entity_name" />
            </div>

            <label className="text-lg">2. Адреса произвођача/власника</label>
            <Input name="legal_entity_municipality"  placeholder="Општина" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_city" placeholder="Место" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_zip_code" placeholder="Поштански број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_address" placeholder="Улица и број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_phone" placeholder="Телефон" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_fax" placeholder="Телефакс " onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <div className="text-xl">Део Ц - Транспорт отпада</div>
            <label className="text-lg">1. Назив превозника отпада</label>
            <Input name="company_name" disabled={detailsData ? detailsData.company_name && true : false} defaultValue={detailsData ? detailsData.company_name && detailsData.company_name : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input className="hidden" defaultValue={detailsData? detailsData.id && detailsData.company_id : ""} name="company_id" />

            <label className="text-lg">2. Адреса превозника отпада</label>
            <Input name="company_municipality" disabled={detailsData ? detailsData.company_municipality && true : false} defaultValue={detailsData ? detailsData.company_municipality && detailsData.company_municipality : ""} placeholder="Општина" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_city" disabled={detailsData ? detailsData.company_city && true : false} defaultValue={detailsData ? detailsData.company_city && detailsData.company_city : ""} placeholder="Место" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_zip_code" disabled={detailsData ? detailsData.company_zip_code && true : false}  defaultValue={detailsData ? detailsData.company_zip_code && detailsData.company_zip_code : ""} placeholder="Поштански број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_address" disabled={detailsData ? detailsData.company_address && true : false} defaultValue={detailsData ? detailsData.company_address && detailsData.company_address : ""} placeholder="Улица и број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_phone" disabled={detailsData ? detailsData.company_phone && true : false} defaultValue={detailsData ? detailsData.company_phone && detailsData.company_phone: ""} placeholder="Телефон" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_fax" disabled={detailsData ? detailsData.company_fax && true : false} defaultValue={detailsData ? detailsData.company_fax && detailsData.company_fax : ""} placeholder="Телефакс " onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">3.  Начин транспорта</label>
            <Input name="travel_warrant_transport_type" disabled={detailsData ? detailsData.travel_warrant_transport_type ? true : false : false} defaultValue={detailsData ? detailsData.travel_warrant_transport_type && detailsData.travel_warrant_transport_type : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">4. Релација</label>
            <Input name="travel_warrant_relation" disabled={detailsData ? detailsData.travel_warrant_relation ? true : false : false}  defaultValue={detailsData ? detailsData.travel_warrant_relation && detailsData.travel_warrant_relation : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">5.  Рута кретања</label>
            <Input name="travel_warrant_route" defaultValue={detailsData ? detailsData.travel_warrant_route && detailsData.travel_warrant_route : "" } onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">6.  Дозвола за превознике отпада - број и датум издавања</label>
            <Input name="waste_licence" defaultValue={detailsData ? detailsData.waste_licence && detailsData.waste_licence : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">7.  Датум пријема отпада</label>
            <Input name="waste_takeover" defaultValue={detailsData ? detailsData.waste_takeover && detailsData.waste_takeover : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
        
            <label className="text-lg">7.  Датум предаје отпада</label>
            <Input name="waste_delivery" defaultValue={detailsData ? detailsData.waste_delivery && detailsData.waste_delivery : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <Button loading={isCreateLoading} form={formName} key="submit" htmlType="submit" className="text-white bg-blue-500">Сачувај</Button>
        </Form> 
        </>
    )
}

export default TravelWarrantDkoDetails
