import React from 'react';

const colStyle = {
  thead: 'p-3 whitespace-nowrap',
  tbody: 'p-3 whitespace-nowrap',
};

const Table = ({ columns, data, render }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full border border-gray-200 rounded leading-4">
        <thead>
          <tr className="border-b border-gray-200 bg-gray-50">
            {columns.map((item) => (
              <th
                width={item.width}
                className={colStyle.thead}
                key={item.title}>
                {item.title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            {columns.map((item) => (
              <td className={colStyle.tbody} key={item.dataIndex}>
                {
                  item.render ? item.render(data[item.dataIndex]) : data[item.dataIndex]
                }
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {
  columns: [],
  data: {},
};

export default Table;
