import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useDashboard } from './DashboardProvider';
import { Layout, Menu, Image } from 'antd';

import MenuItem from 'antd/lib/menu/MenuItem';

const { Sider } = Layout;

const Sidebar = () => {
  const { isMenuOpen, toggleMenu, menuItems } = useDashboard();
  const { pathname } = useLocation();

  const path =
    pathname.lastIndexOf('/') > 0
      ? pathname.substring(0, pathname.lastIndexOf('/'))
      : pathname;

  return (
    <Sider
      width={160}
      collapsible
      collapsedWidth={50}
      breakpoint='lg'
      onCollapse={toggleMenu}
      collapsed={isMenuOpen}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
      >
      <div className="text-center py-2">
        <Image
          width={isMenuOpen ? 30 : 50}
          preview={false}
          src={window.location.origin + "/belgrade_logo.png"}
        />
      </div>

      <Menu
        selectable={false}
        theme="dark"
        mode="inline"
        inlineIndent={0}
        selectedKeys={[path]}>
        {menuItems?.map(({ title, url, icon }) => {
          return (
            <MenuItem
              className="flex items-center"
              style={{ height: 'auto', lineHeight: '35px' }}
              icon={icon}
              key={url}>
              <Link to={url}>{title}</Link>
            </MenuItem>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
