import React from 'react';
import { useStorage } from './useStorage';

const usePagination = ({ initialPaginate = 10 /*25 default*/ } = {}) => {
  const { storage, initialPage, searchTermExist, initialTerm } = useStorage();

  const [page, setPage] = React.useState(initialPage);
  const [term, setTerm] = React.useState(initialTerm);
  const [paginate, setPaginate] = React.useState(initialPaginate);

  function getPaginationProps(props = {}) {
    const handleChange = (e) => {
      searchTermExist ? storage.set('subpage', e) : storage.set('page', e);
      setPage(e);
    };

    return {
      current: page,
      onChange: (e) => handleChange(e),
      pageSize: paginate,
      onShowSizeChange: (_, size) => setPaginate(size),
      showSizeChanger: true,
      showQuickJumper: true,
      responsive: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} od ukupno ${total}`,
      ...props,
    };
  }

  return {
    page,
    setPage,
    term,
    setTerm,
    paginate,
    getPaginationProps,
  };
};

export default usePagination;
