export const convertMergedLetters = (value) => {
    value = value.split("дз").join("џ")
    value = value.split("дЗ").join("џ")
    value = value.split("ДЗ").join("Џ")
    value = value.split("Дз").join("Џ")
    value = value.split("дж").join("џ")
    value = value.split("дЖ").join("џ")
    value = value.split("ДЖ").join("Џ")
    value = value.split("Дж").join("Џ")
    value = value.split("нј").join("њ")
    value = value.split("нЈ").join("њ")
    value = value.split("НЈ").join("Њ")
    value = value.split("Нј").join("Њ")
    value = value.split("дј").join("ђ")
    value = value.split("дЈ").join("ђ")
    value = value.split("ДЈ").join("Ђ")
    value = value.split("Дј").join("Ђ")
    value = value.split("лј").join("љ")
    value = value.split("лЈ").join("љ")
    value = value.split("Лј").join("Љ")
    value = value.split("ЛЈ").join("Љ")
    return value
}

export const latinToCyr = {
    "KeyQ" : "љ",
    "KeyW" : "њ",
    "KeyЕ" : "е",
    "KeyR" : "р",
    "KeyT" : "т",
    "KeyY" : "з",
    "KeyU" :"у",
    "KeyI" :"и",
    "KeyO" :"о",
    "KeyP" :"п",
    "BracketRight" :"ђ",
    "BracketLeft" :"ш",
    "KeyA" :"а",
    "KeyS" :"с",
    "KeyD" :"д",
    "KeyF" :"ф",
    "KeyG" :"г",
    "KeyH" :"х",
    "KeyJ" :"ј",
    "KeyK" :"к",
    "KeyL" :"л",
    "Semicolon" :"ч",
    "Quote" :"ћ",
    "Backslash" :"ж",
    "KeyZ" :"ѕ",
    "KeyX" :"џ",
    "KeyC" :"ц",
    "KeyV" :"в",
    "KeyB" :"б",
    "KeyN" :"н",
    "KeyM" :"м",
    "Comma" : ",",
    "Period" : ".",
    "IntlBackslash" : "<",
    "Backquote" : "`",
    "Digit1" : "1",
    "Digit2" : '2',
    "Digit3" : '3',
    "Digit4" : '4',
    "Digit5" : '5',
    "Digit6" : '6',
    "Digit7" : '7',
    "Digit8" : '8',
    "Digit9" : '9',
    "Digit0" : '0',
    "Minus" : "''",
    "Equal" : "+",
    "Slash" : "-",
}

export const latinToCyrCapital = {
    "KeyQ" : "Љ",
    "KeyW" : "Њ",
    "KeyЕ" : "Е",
    "KeyR" : "Р",
    "KeyT" : "Т",
    "KeyY" : "З",
    "KeyU" :"У",
    "KeyI" :"И",
    "KeyO" :"О",
    "KeyP" :"П",
    "BracketRight" :"Ђ",
    "BracketLeft" :"Ш",
    "KeyA" :"А",
    "KeyS" :"С",
    "KeyD" :"Д",
    "KeyF" :"Ф",
    "KeyG" : "Г",
    "KeyH" :"Х",
    "KeyJ" :"Ј",
    "KeyK" :"К",
    "KeyL" :"Л",
    "Semicolon" :"Ч",
    "Quote" :"Ћ",
    "Backslash" :"Ж",
    "KeyZ" :"Ѕ",
    "KeyX" :"Џ",
    "KeyC" :"Ц",
    "KeyV" :"В",
    "KeyB" :"Б",
    "KeyN" :"Н",
    "KeyM" :"М",
    "Comma" : ";",
    "Period" : ":",
    "IntlBackslash" : ">",
    "Backquote" : "~",
    "Digit1" : "!",
    "Digit2" : '"',
    "Digit3" : '#',
    "Digit4" : '$',
    "Digit5" : '%',
    "Digit6" : '&',
    "Digit7" : '/',
    "Digit8" : '(',
    "Digit9" : ')',
    "Digit0" : '=',
    "Minus" : "?",
    "Equal" : "*",
    "Slash" : "_",
}

export const latinToCyrMixed = {
    "113" : "љ",
    "81" : "Љ",
    "119" : "њ",
    "87" : "Њ",
    "101" : "е",
    "69" : "Е",
    "114" : "р",
    "82" : "Р",
    "116" : "т",
    "84" : "Т",
    "122" : "з",
    "90" : "З",
    "117" :"у",
    "85" :"У",
    "105" :"и",
    "73" :"И",
    "111" :"о",
    "79" :"О",
    "112" :"п",
    "80" :"П",
    "273" :"ђ",
    "272" :"Ђ",
    "353" :"ш",
    "352" :"Ш",
    "97" :"а",
    "65" :"А",
    "115" :"с",
    "83" :"С",
    "100" :"д",
    "68" :"Д",
    "102" :"ф",
    "70" :"Ф",
    "103" :"г",
    "71" : "Г",
    "104" :"х",
    "72" :"Х",
    "106" :"ј",
    "74" :"Ј",
    "107" :"к",
    "75" :"К",
    "108" :"л",
    "76" :"Л",
    "269" :"ч",
    "268" :"Ч",
    "263" :"ћ",
    "262" :"Ћ",
    "382" :"ж",
    "381" :"Ж",
    "121" :"ѕ",
    "89" :"Ѕ",
    "120" :"џ",
    "88" :"Џ",
    "99" :"ц",
    "67" :"Ц",
    "118" :"в",
    "86" :"В",
    "98" :"б",
    "66" :"Б",
    "110" :"н",
    "78" :"Н",
    "109" :"м",
    "77" :"М",
}
