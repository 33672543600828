import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Form, Input as AntInput } from 'antd';
import { FieldWrapper } from './FieldWrapper';

export const Input = ({
  name,
  label,
  description,
  placeholder,
  type = 'text',
  defaultValue,
  rules,
  ...props
}) => {
  const { control, errors } = useFormContext();

  return (
    <FieldWrapper
      label={label}
      description={description}
      error={errors[name]?.message}>
      <Form.Item className="m-0">
        <Controller
          rules={rules}
          type={type}
          defaultValue={defaultValue ? defaultValue : ""}
          name={name}
          label={label}
          control={control}
          placeholder={placeholder}
          as={<AntInput />}
          {...props}
        />
      </Form.Item>
    </FieldWrapper>
  );
};
