import React, {useRef, useState, useMemo} from 'react'
import { ListHeading } from 'src/components/ListHeading'
import TravleWarrantDkoPrint from '../components/TravelWarrantDkoPrint'
import { Button, Switch, } from 'antd'
import { convertMergedLetters, latinToCyrMixed, latinToCyrCapital, latinToCyr } from '../components/latinToCyrVars'
import { createTravelWarrantDko, getTravelWarrantDkoSettings, searchLegalEntities } from '../api'
import Loader from 'src/utils/Loader'
import { useMutation, useQuery } from 'react-query';
import { useForm } from 'react-hook-form'
import { SelectElement as Select } from 'src/components/Form/Select';
import { Form, Input } from 'src/components/Form';
import openNotificationWithIcon from 'src/utils/notification'
import { Checkbox } from 'src/components/Form/Checkbox';
import { queryClient } from 'src/lib/react-query';
import { errorHandler } from 'src/utils/Error'
import { useHistory, useLocation } from 'react-router'
import NotFoundLegalEntitiesSelect from '../components/NotFoundLegalEntitiesSelect'


function CreateTravelWarrantDko({warrant}) {
    const [cyrEnabled, setCyrEnabled] = useState(true)
    const [legalTerm, setLegalTerm] = useState("")
    const formMethods = useForm();
    const history = useHistory();
    const { pathname } = useLocation()
    const { mutateAsync, isLoading:isCreateLoading } = useMutation(createTravelWarrantDko);
    const { data, isLoading } = useQuery(
        ['travel_warrant_dko', warrant.id], () => getTravelWarrantDkoSettings(warrant.id),
        {
          select: (res) => res.data,
        },
      );

    const { data:legalEntities , isLoading:isLegalLoading, } = useQuery(['search_legal_entities', legalTerm], () => searchLegalEntities(legalTerm), {enabled:Boolean(legalTerm) ,select: res => res.data.data})
    
    const cyrData = useMemo(() => {
        if (data) {
            for (const key in data) {
                if (data[key] && key !== "email" && key !== "website") {
                  for (let i = 0; i < data[key].length; i++) {
                      for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                          if(data[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                              data[key] = data[key].split(data[key][i]).join(Object.values(latinToCyrMixed)[j])
                          }
                      }
                  }
                } 
                
            }
            return data
        }
    }, [data])

    const cyrWarrant = useMemo(() => {
        if (warrant) {
            for (const key in warrant) {
                if (warrant[key] && typeof warrant[key] === "string" && key !== "crew" && key !== "employee" && key !== "protocols" && key !== "route" && key !== "qr_code") {
                    for (let i = 0; i < warrant[key].length; i++) {
                      for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                          if(warrant[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                            warrant[key] = warrant[key].split(warrant[key][i]).join(Object.values(latinToCyrMixed)[j])
                          }
                      }
                  }
                } 
            }
            for (const key in warrant.route) {
                if (warrant.route[key] && typeof warrant.route[key] === "string") {
                    for (let i = 0; i < warrant.route[key].length; i++) {
                        for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                            if(warrant.route[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                                warrant.route[key] = warrant.route[key].split(warrant.route[key][i]).join(Object.values(latinToCyrMixed)[j])
                            }
                        }
                    }
                }
            }
            return warrant
        }
    }, [warrant])

    const legalEntityOptions = useMemo(() => {
        const options = legalEntities?.map((legalEntity) => {                    
            return{
                label: legalEntity.name,
                value: legalEntity.id
            }}) 
        return options
    }, [legalEntities])
    const handleInputChange = (e) => {
       if (cyrEnabled) {
            const start = e.target.selectionStart
            const end = e.target.selectionEnd
            const val = e.target.value
            if (!e.ctrlKey && !e.altKey && !e.metaKey && e.key !== "Dead") {
                if (e.getModifierState('CapsLock')) {
                    if (e.shiftKey) {
                        for (let j = 0; j < Object.keys(latinToCyr).length; j++) {
                            if (e.code === Object.keys(latinToCyr)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyr)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    } else {
                        for (let j = 0; j < Object.keys(latinToCyrCapital).length; j++) {
                            if (e.code === Object.keys(latinToCyrCapital)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyrCapital)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    }
                    
                } else {
                    if (!e.shiftKey) {
                        for (let j = 0; j < Object.keys(latinToCyr).length; j++) {
                            if (e.code === Object.keys(latinToCyr)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyr)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    } else {
                        for (let j = 0; j < Object.keys(latinToCyrCapital).length; j++) {
                            if (e.code === Object.keys(latinToCyrCapital)[j]) {
                                e.preventDefault()
                                e.target.value = val.slice(0, start) + Object.values(latinToCyrCapital)[j] + val.slice(end)
                                e.target.value = convertMergedLetters(e.target.value)
                                e.target.selectionStart = e.target.selectionEnd = start + 1;
                            }
                        }
                    }
                }
            } 
       }
       formMethods.setValue(e.target.name, e.target.value)
    }

    const handleInputPaste = (e) => {
        if (cyrEnabled) {
            e.preventDefault()
            const start = e.target.selectionStart
            const end = e.target.selectionEnd
            let val = e.target.value
            let paste = (e.clipboardData || window.clipboardData).getData('text')
            for (let i = 0; i < paste.length; i++) {
                for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                    if(paste[i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                        paste = paste.split(paste[i]).join(Object.values(latinToCyrMixed)[j])
                    }
                }
            }
           
            e.target.value = val.slice(0, start) + paste + val.slice(end)
            e.target.value = convertMergedLetters(e.target.value)
            e.target.selectionStart = e.target.selectionEnd = start + paste.length;
        }
        formMethods.setValue(e.target.name, e.target.value)
    }

    const onSubmit = async (data) => {
        try {
            const response = await mutateAsync(data)
            queryClient.invalidateQueries('travel-warrant-all-dko')
            openNotificationWithIcon('success')
            history.replace(`${pathname.replace("/dko_create", "/dko")}`)
        }
        catch (error) {
            errorHandler(error);
        }
    };
    
    const legal_entitiy_id = formMethods.watch('legal_entity_id');
    const selectedLegalEntity = legalEntities && legalEntities?.find((l) => l.id ===  legal_entitiy_id)

    React.useEffect(() => {
        if (selectedLegalEntity) {
            for (const key in selectedLegalEntity) {
                if (selectedLegalEntity[key] && key !== "name") {
                    for (let i = 0; i < selectedLegalEntity[key].length; i++) {
                        for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                            if(selectedLegalEntity[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                                selectedLegalEntity[key] = selectedLegalEntity[key].split(selectedLegalEntity[key][i]).join(Object.values(latinToCyrMixed)[j])
                            }
                        }
                    }
                }
            }
            if (selectedLegalEntity.municipality) {
                for (const key in selectedLegalEntity.municipality) {
                    if (selectedLegalEntity.municipality[key]) {
                        for (let i = 0; i < selectedLegalEntity.municipality[key].length; i++) {
                            for (let j = 0; j < Object.keys(latinToCyrMixed).length; j++) {
                                if(selectedLegalEntity.municipality[key][i].charCodeAt(0) === parseInt(Object.keys(latinToCyrMixed)[j], 10)) {
                                    selectedLegalEntity.municipality[key] = selectedLegalEntity.municipality[key].split(selectedLegalEntity.municipality[key][i]).join(Object.values(latinToCyrMixed)[j])
                                }
                            }
                        }
                    }
                }
            }
        }
    
        formMethods.setValue('legal_entity_municipality', selectedLegalEntity?.municipality?.name || "")
        formMethods.setValue('legal_entity_city', selectedLegalEntity?.city || "")
        formMethods.setValue('legal_entity_zip_code', selectedLegalEntity?.zip_code || "")
        formMethods.setValue('legal_entity_address', selectedLegalEntity?.address || "")
        formMethods.setValue('legal_entity_phone', selectedLegalEntity?.phone || "")
        formMethods.setValue('legal_entitiy_fax', selectedLegalEntity?.fax || "")
        formMethods.setValue('legal_entity_name', selectedLegalEntity?.name || "")

      }, [formMethods, selectedLegalEntity, legalEntities])

    let delaySearch
    const handleSelectSearch = (value) => {  
        clearTimeout(delaySearch)
        delaySearch = setTimeout(function() {
            setLegalTerm(value)
        },300) 
    }
    React.useEffect(() => {
        return () => {
            clearTimeout(delaySearch)
        }
    },[])

    const listHeadingClasses = {
        main: "flex flex-col mb-2 md:flex-row md:justify-between md:items-center",
        leftContainer: "flex items-center xs:flex-col xs:items-start sm:mb-3 md:mb-0",
        right: "flex justify-center md:justify-end md:w-auto",
        left: "ml-4 xs:my-3 xs:ml-0"
      }

      const formName = "dkoForm"

    if (isLoading) return <Loader />;

    return (
        <>
         <ListHeading classNames={listHeadingClasses} title="Dokument o Kretanju Otapada" 
         left={
            <div className="flex items center">
                <Switch checked={cyrEnabled} onChange={() => {setCyrEnabled(!cyrEnabled)}} className="m-auto block"  />
                <div onClick={() => {setCyrEnabled(!cyrEnabled)}} className="text-xl cursor-pointer ml-1">Ћирилица</div>
            </div>
        }
         />

        <Form id={formName} formMethods={formMethods}  onSubmit={onSubmit} className="flex flex-col space-y-2">
            <Input className="hidden" defaultValue={warrant.id} name="travel_warrant_id" />

            <div className="text-xl">Део Б - Произвођач/власник отпада </div>
            <label className="text-lg">1. Назив произвођача/власника</label>
            <label className="text-sm">Претражите правна лица </label>
            <div className="dropdown-select-wrapper">
                <Select allowClear={false}  name="legal_entity_id" options={legalEntityOptions && legalEntityOptions}
                onSearch={handleSelectSearch} notFoundContent={<NotFoundLegalEntitiesSelect loading={isLegalLoading}/>} />
                  <Input className="hidden" name="legal_entity_name" />
            </div>

            <label className="text-lg">2. Адреса произвођача/власника</label>
            <Input name="legal_entity_municipality"  placeholder="Општина" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_city"  placeholder="Место" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_zip_code" placeholder="Поштански број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_address" placeholder="Улица и број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_phone" placeholder="Телефон" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="legal_entity_fax" placeholder="Телефакс " onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <div className="text-xl">Део Ц - Транспорт отпада</div>
            <label className="text-lg">1. Назив превозника отпада</label>
            <Input name="company_name" disabled={cyrData ? cyrData.company_name && true : false} defaultValue={cyrData ? cyrData.company_name && cyrData.company_name : ""} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input className="hidden" defaultValue={cyrData? cyrData.id && cyrData.id : ""} name="company_id" />

            <label className="text-lg">2. Адреса превозника отпада</label>
            <Input name="company_municipality" disabled={cyrData ? cyrData.municipality && true : false} defaultValue={cyrData ? cyrData.municipality && cyrData.municipality : ""} placeholder="Општина" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_city" disabled={cyrData ? cyrData.city && true : false} defaultValue={cyrData ? cyrData.city && cyrData.city : ""} placeholder="Место" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_zip_code" disabled={cyrData ? cyrData.zip_code && true : false}  defaultValue={cyrData ? cyrData.zip_code && cyrData.zip_code : ""} placeholder="Поштански број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_address" disabled={cyrData ? cyrData.address && true : false} defaultValue={cyrData ? cyrData.address && cyrData.address : ""} placeholder="Улица и број" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_phone" disabled={cyrData ? cyrData.phone && true : false} defaultValue={cyrData ? cyrData.phone && cyrData.phone : ""} placeholder="Телефон" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
            <Input name="company_fax" disabled={cyrData ? cyrData.fax && true : false} defaultValue={cyrData ? cyrData.fax && cyrData.fax : ""} placeholder="Телефакс " onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">3.  Начин транспорта</label>
            <Input name="travel_warrant_transport_type" disabled={cyrWarrant.transportation_type ? true : false} defaultValue={cyrWarrant.transportation_type && cyrWarrant.transportation_type} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">4. Релација</label>
            <Input name="travel_warrant_relation" disabled={cyrWarrant.route.name ? true : false}  defaultValue={cyrWarrant.route.name && cyrWarrant.route.name} onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">5.  Рута кретања</label>
            <Input name="travel_warrant_route" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">6.  Дозвола за превознике отпада - број и датум издавања</label>
            <Input name="waste_licence" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <label className="text-lg">7.  Датум пријема отпада</label>
            <Input name="waste_takeover" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />
        
            <label className="text-lg">7.  Датум предаје отпада</label>
            <Input name="waste_delivery" onKeyDown={handleInputChange} onPaste={handleInputPaste} className="ant-input" />

            <Button loading={isCreateLoading} form={formName} key="submit" htmlType="submit" className="text-white bg-blue-500">Направи</Button>
        </Form> 
        </>
    )
}

export default CreateTravelWarrantDko
