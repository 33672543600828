import { axios } from 'src/lib/axios';
import { errorHandler } from 'src/utils/Error';
import { API_URL } from 'src/config';


export const getTravelWarrants = (params) => {
  return axios(`${API_URL}/admin/travel_warrants`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getTravelWarrant = (id) => {
  return axios(`${API_URL}/admin/travel_warrants/${id}`).catch((error) =>
    errorHandler(error),
  );
};

export const createTravelWarrant = (data) => {
  return axios.post(`${API_URL}/admin/travel_warrants`, data);
};

export const updateTravelWarrant = ({ id, data }) => {
  return axios.put(`${API_URL}/admin/travel_warrants/${id}`, data);
};

export const deleteTravelWarrant = (id) => {
  return axios.delete(`${API_URL}/admin/travel_warrants/${id}`);
};

export const getMunicipalities = () => {
  return axios(`${API_URL}/admin/municipalities`).catch((error) => errorHandler(error));
}

export const getWasteProducers = () => {
  return axios(`${API_URL}/admin/waste_producers`).catch((error) => errorHandler(error));
}

export const getWasteTypes = () => {
  return axios(`${API_URL}/admin/waste_types`).catch((error) => errorHandler(error));
}

// ========== select dropdown

export const getAllEmployees = () => {
  return axios(`${API_URL}/admin/employees_all`).catch((error) => errorHandler(error));
};

export const getAllVehicles = () => {
  return axios(`${API_URL}/admin/vehicles_all`).catch((error) => errorHandler(error));
};

export const getAllRoutes = () => {
  return axios(`${API_URL}/admin/routes_all`).catch((error) => errorHandler(error));
};

// ========== controller warrants

export const getControllerTravelWarrants = (params) => {
  return axios(`${API_URL}/controller/travel_warrants`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getControllerTravelWarrant = (id) => {
  return axios(`${API_URL}/controller/travel_warrants/${id}`).catch((error) =>
    errorHandler(error),
  );
};

export const updateControllerTravelWarrant = (id, data) => {
  return axios.put(`${API_URL}/controller/travel_warrants/${id}`, data);
};

// ========== service warrants

export const getServiceTravelWarrants = (params) => {
  return axios(`${API_URL}/service/travel_warrants`, { params }).catch((error) =>
    errorHandler(error),
  );
};

export const getServiceTravelWarrant = (id) => {
  return axios(`${API_URL}/admin/settings${id}`).catch((error) =>
    errorHandler(error),
  );
};


export const getTravelWarrantDkoSettings = (id) => {
  return axios(`${API_URL}/admin/settings/${id}`).catch((error) => errorHandler(error))
}

export const getTravelWarrantDko = (id, params) => {
  return axios(`${API_URL}/admin/waste_transport_documents_warrant/${id}`, {params}).catch((error) => errorHandler(error))
}

export const getTravelWarrantDkoDetails = (id) => {
  return axios(`${API_URL}/admin/waste_transport_documents/${id}`).catch((error) => errorHandler(error))
}

export const createTravelWarrantDko = (data) => {
  return axios.post(`${API_URL}/admin/waste_transport_documents`, data).catch((error) => errorHandler(error))
}

export const updateTravelWarrantDko = ({id, data}) => {
  return axios.put(`${API_URL}/admin/waste_transport_documents/${id}`, data).catch((error) => errorHandler(error))
}

export const searchLegalEntities = (params) => {
  return axios(`${API_URL}/admin/legal_entities?term=${params}`).catch((error) =>
    errorHandler(error),
  );
};