import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { ListHeading } from 'src/components/ListHeading';
import { getUser } from '../api';
import UpdateUserForm from '../components/UpdateUserForm';
import DeleteUser from '../components/DeleteUser';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import Loader from 'src/utils/Loader';
import Table from 'src/utils/Table';


const columns = [
  { title: 'Ime i prezime', dataIndex: 'name', width: '25%' },
  { title: 'Email', dataIndex: 'email' },
];

const UserDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(['user', id], () => getUser(id), {
    select: (res) => res.data,
  });

  const listHeadingClasses = {
    main: "flex flex-col mb-2 md:flex-row xl:justify-between md:items-center",
    leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
    right: "flex justify-center md:justify-end w-full",
    left: "mt-3 md:ml-4 xl:mt-0 w-full"
  }
  
  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        title={data.name}
        classNames={listHeadingClasses}
        right={
          <Row gutter={[8,4]} className="w-full" justify="end">
            <Col md={8} sm={12} xs={12}>
              <DeleteUser id={id} />
            </Col>
            <Col md={8} sm={12} xs={12}>
              <UpdateUserForm user={data} />
            </Col>
          </Row>
        }
      />
      
      <Table columns={columns} data={data} />
    </>
  );
};

export default UserDetails;
