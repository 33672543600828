import React from 'react';
import { useLocation } from 'react-router-dom';

export const useStorage = () => {
  const { pathname } = useLocation();
  const storageType = window.sessionStorage;
  const storage = {
    get: (key) => storageType.getItem(key),
    set: (key, value) => storageType.setItem(key, value),
    remove: (key) => storageType.removeItem(key),
  };

  const countSlashes = pathname.split('/').length - 1;
  const path =
    countSlashes > 1
      ? pathname.substring(0, pathname.lastIndexOf('/'))
      : pathname;

  // 1.
  React.useEffect(() => {
    storage.set('path', path);
  }, [pathname]);

  if (path !== storage.get('path')) {
    storage.remove('search');
    storage.remove('page');
    storage.remove('subpage');
  }

  // 2.
  const page = storage.get('page');
  const subpage = storage.get('subpage');
  const searchTermExist = storage.get('search');

  let initialTerm;
  let initialPage;

  const travelWarrantsTerm = {
    driver_name: '',
    vehicle_name: '',
    travel_warrant_id: '',
    travel_warrant_type: '',
    travel_warrant_status: '',
  };

  const logsTerm = {
    name: '',
    loggable_id: '',
    loggable_type: '',
  };

  const vehiclesTerm = {
    licence_plate: '',
    svo_validated: ''
  }

  const reportsTerm = {
    report_type: 'vehicle',
    start_date: '',
    end_date: ''
  }

  const vehicleReportsTerm = {
    vehicle: '',
    report_type:'',
    start_date: '',
    end_date: ''
  }

  const employeesTerm = {
    term: '',
    employee_type: '',
    svo_validated: ''
  }

  if (pathname === '/travel-warrants') {
    initialTerm = searchTermExist
      ? JSON.parse(searchTermExist)
      : travelWarrantsTerm;
  } else if (pathname === '/logs') {
    initialTerm = searchTermExist ? JSON.parse(searchTermExist) : logsTerm;
  } else {
    initialTerm = searchTermExist ? searchTermExist : '';
  }

  // check if search term exist
  if (searchTermExist) {
    initialPage = subpage ? subpage : 1;
  } else {
    initialPage = page ? page : 1;
  }

  initialPage = parseInt(initialPage);

  return {
    storage,
    initialPage,
    initialTerm,
    searchTermExist,
    travelWarrantsTerm,
    logsTerm,
    vehiclesTerm,
    reportsTerm,
    vehicleReportsTerm,
    employeesTerm,
  };
};
