import { useAuth } from './auth';

export const ROLES = {
  ADMIN_JKP: 'admin_jkp',
  ADMIN_CITY: 'admin_city',
  SERVICE: 'service',
  CONTROLLER: 'controller',
  DRIVER: 'driver',
};

const isValidRole = (role) => ROLES.hasOwnProperty(role?.toUpperCase());

const isInvalidRoles = (roles) => roles?.some((role) => !isValidRole(role));

export const useRBAC = ({
  allowedRoles: allowed = [],
  forbiddenRoles: forbidden = [],
} = {}) => {
  const { user } = useAuth();

  const checkRoles = ({
    allowedRoles = allowed,
    forbiddenRoles = forbidden,
  } = {}) => {
    if (
      !isValidRole(user.role) ||
      isInvalidRoles([...allowedRoles, ...forbiddenRoles])
    ) {
      throw new Error(
        'You are using an invalid role! Please only use roles defined in ROLES',
      );
    }

    if (allowedRoles.length > 0) {
      return allowedRoles.includes(user.role);
    }

    if (forbiddenRoles.length > 0) {
      return !forbiddenRoles.includes(user.role);
    }

    return true;
  };

  const canAccess = checkRoles();

  return { canAccess, checkRoles };
};

export const RBAC = ({
  allowedRoles = [],
  forbiddenRoles = [],
  forbidden = null,
  children,
} = {}) => {
  const { canAccess } = useRBAC({ allowedRoles, forbiddenRoles });

  return canAccess ? children : forbidden;
};
