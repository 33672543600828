import React from 'react';
import { Collapse, Row, Col, Button } from 'antd';
import { Form } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { getVehicleHistoryGPS } from '../api';
import { useMutation } from 'react-query';
import { DateInput } from 'src/components/Form/DateInput';
import openNotificationWithIcon from 'src/utils/notification';
import moment from 'moment';
import { CaretRightOutlined } from '@ant-design/icons';
import { errorHandler } from 'src/utils/Error';

const { Panel } = Collapse;

const HistoryForm = ({ vehicle, setHistory, clearMap }) => {
  const { mutateAsync, isLoading } = useMutation(getVehicleHistoryGPS);

  const formMethods = useForm();

  const handleSubmit = async (data) => {
    const { date_from, date_to } = data;
    const start_date = moment(date_from).format('YYYY-MM-DD HH:mm:ss');
    const end_date = moment(date_to).format('YYYY-MM-DD HH:mm:ss');
    let id = 0;

    const vehicle_id = vehicle.map((v) => (id = v.id));
    const params = { start_date, end_date };

    if (vehicle.length === 0) {
      openNotificationWithIcon('error', {
        message: 'Morate izabrati vozilo!',
      });
      return false;
    } else if (vehicle.length > 1) {
      openNotificationWithIcon('error', {
        message: 'Izaberite samo jedno vozilo!',
      });
      return false;
    } else if (start_date > end_date) {
      openNotificationWithIcon('error', {
        message: 'Početni datum ne sme biti manji od krajnjeg datuma!',
        duration: 6,
      });
      return false;
    }

    try {
      const response = await mutateAsync({ id, data: params });
      setHistory(response?.data?.data);

      if (response?.data?.data?.params.length === 0) {
        openNotificationWithIcon('info', {
          message: 'Nema vrednosti za izabrani period.',
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const buttonOptions = {
    type: 'primary',
    style: { width: '100%', height: 'auto' },
  };

  const formName = 'showVehicleHistory';

  return (
    <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}>
        <Panel header="Istorija kretanja">
          <DateInput
            name="date_from"
            label="Od:"
            placeholder="Datum i vreme"
            rules={{
              required: {
                value: true,
                message: 'Obavezno polje',
              },
            }}
          />

          <DateInput
            name="date_to"
            label="Do:"
            placeholder="Datum i vreme"
            defaultTime="23:59"
            rules={{
              required: {
                value: true,
                message: 'Obavezno polje',
              },
            }}
          />

          <Row justify="space-between" gutter={10} className="mt-3">
            <Col span={12}>
              <Button
                {...buttonOptions}
                danger
                onClick={() => clearMap(formMethods)}>
                Očisti mapu
              </Button>
            </Col>

            <Col span={12}>
              <Button {...buttonOptions} loading={isLoading} htmlType="submit">
                Prikaži
              </Button>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
};

export default HistoryForm;
