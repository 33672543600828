import React from 'react';
import { useQuery } from 'react-query';
import { Table } from 'antd';
import {
  getTravelWarrants,
  getControllerTravelWarrants,
  getServiceTravelWarrants,
} from '../api';
import { Link } from 'react-router-dom';
import CreateTravelWarrantForm from '../components/CreateTravelWarrantForm';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import usePagination from 'src/hooks/usePagination';
import { options } from 'src/utils/tableConfig';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { RBAC, ROLES } from 'src/lib/rbac';
import SearchFilters from '../components/SearchFilters';
import { useTravelWarrantsUsers } from '../hooks/useTravelWarrantsUsers';
import StatusModal from '../components/StatusModal';
import { useAuth } from 'src/lib/auth';

const TravelWarrants = () => {
  const {
    handleStatusSelect,
    getStatus,
    getType,
    getDataByUserRole,
    isModalVisible,
    isStatusLoading,
    handleCancel,
    handleOk,
    status,
  } = useTravelWarrantsUsers();
  const {user} = useAuth()
  const columns = [
    {
      title: 'Br. naloga',
      dataIndex: 'id',
      width: '8%',
    },
    {
      title: 'Vozač',
      render: ({ employee }) =>
        employee
          ? employee.first_name +
            ' ' +
            employee.last_name +
            ' - ' +
            employee.employee_code
          : '',
    },
    {
      title: 'Vozilo',
      render: ({ vehicle }) =>
        vehicle ? vehicle.unique_code + ' - ' + vehicle.licence_plate : '',
    },
    {
      title: 'Opština',
      render: ({ municipality }) =>
      municipality ? municipality.name ? municipality.name : "" : "" ,
    },
    {
      title: 'Vreme',
      render: (entry) => {
        const date = new Date(entry.created_at)
        const year = date.getFullYear()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let hours = date.getHours()
        let minutes = date.getMinutes()
        day = ("0" + day).slice(-2)
        month = ("0"+month).slice(-2)
        hours = ("0" + hours).slice(-2);
        minutes = ("0" + minutes).slice(-2);
        return `${day}.${month}.${year} - ${hours}:${minutes}`
      }
    },
    {
      title: 'Tip',
      render: (item) => getType(item.type),
      width: '8%',
    },
    {
      title: 'Status',
      render: (item) => getStatus(item.status),
      width: '8%',
    },
    {
      title: user.role !== "controller" ?  'Izmeni' : null,
      render: (entry) => (
        user.role !== "controller"
        ?
        <Link
          className="align-middle inline-block p-1 text-gray-500"
          to={{
            pathname: `/travel-warrants/${entry.id}`,
            state: { openUpdateModal: true }
          }}>
          <EditOutlined />
        </Link>
        :
        null
      ),
      width: user.role !== "controller" ? 70 : 1,
    },
    {
      title: '',
      render: (item) =>
        item.status === 'pending' ? (
          <RBAC allowedRoles={[ROLES.CONTROLLER]}>
            {handleStatusSelect(item.id)}
          </RBAC>
        ) : (
          ''
        ),

      width: '12%',
    },
    {
      title: '',
      render: (entry) => (
        <Link
          className="align-middle inline-block p-1 text-gray-500"
          to={`/travel-warrants/${entry.id}`}>
          <SearchOutlined />
        </Link>
      ),
      width: 50,
    },
  ];

  const {
    page,
    setPage,
    term,
    setTerm,
    paginate,
    getPaginationProps,
  } = usePagination();

  const listParams = { page, paginate, ...term };

  const { data, isLoading } = useQuery(
    ['travel_warrants', listParams],
    () =>
      getDataByUserRole(
        listParams,
        getTravelWarrants,
        getControllerTravelWarrants,
        getServiceTravelWarrants,
      ),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );

  const listHeadingClasses = {
    main: "flex flex-col mb-2 xl:flex-row xl:justify-between xl:items-center",
    leftContainer: "flex mb-2 xl:mb-0 flex-col xl:flex-row xl:items-center",
    right: "flex justify-center xl:justify-end",
    left: "mt-3 xl:ml-4 xl:mt-0"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <RBAC allowedRoles={[ROLES.CONTROLLER]}>
        <StatusModal
          isModalVisible={isModalVisible}
          isLoading={isStatusLoading}
          handleCancel={handleCancel}
          handleOk={handleOk}
          status={status}
        />
      </RBAC>

      <ListHeading
        title="Putni nalozi"
        left={<SearchFilters setTerm={setTerm} setPage={setPage} />}
        right={
          <RBAC allowedRoles={[ROLES.ADMIN_JKP]}>
            <CreateTravelWarrantForm />
          </RBAC>
        }
        classNames={listHeadingClasses}
      />

      <Table
        {...options}
        columns={columns}
        dataSource={data.data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default TravelWarrants;
