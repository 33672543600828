import React from 'react';
import { useQuery } from 'react-query';
import { Table, Row, Col, Divider } from 'antd';
import { getUsers } from '../api';
import { Link } from 'react-router-dom';
import CreateUserForm from '../components/CreateUserForm';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import usePagination from 'src/hooks/usePagination';
import { SearchInput as Search } from 'src/utils/Search';
import { options } from 'src/utils/tableConfig';
import { SearchOutlined } from '@ant-design/icons';

const columns = [
  { title: 'Ime i prezime', dataIndex: 'name', width: '25%' },
  { title: 'Email', dataIndex: 'email' },
  // { title: 'Lozinka', dataIndex: 'password' },
  {
    title: '',
    render: (entry) => (
      <Link
        className="align-middle inline-block p-1 text-gray-500"
        to={`/users/${entry.id}`}>
        <SearchOutlined />
      </Link>
    ),
    width: 50,
  },
];

const Users = () => {
  const {
    page,
    setPage,
    term,
    setTerm,
    paginate,
    getPaginationProps,
  } = usePagination();

  const listParams = { page, paginate, term };

  const { data, isLoading } = useQuery(
    ['users', listParams],
    () => getUsers(listParams),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );

  const listHeadingClasses = {
    main: "flex flex-col mb-2 md:flex-row md:justify-between md:items-center",
    leftContainer: "flex mb-2 md:mb-0 flex-col md:flex-row md:items-center w-full",
    right: "flex justify-center md:justify-end w-full ",
    left: "mt-3 md:ml-4 md:mt-0 w-full"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        title="Korisnici"
        classNames={listHeadingClasses}
        left={
          <Search
            setPage={setPage}
            setTerm={setTerm}
            placeholder="Unesite ime i prezime"
            tooltip="Ime i prezime"
          />
        }
        right={
        <Row className="w-full mb-5 md:mb-0" justify="end">
          <Col md={0} xs={24} sm={24}>
            <Divider />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <CreateUserForm oldData={data.data} />
          </Col>
        </Row>
        }
      />

      <Table
        {...options}
        columns={columns}
        dataSource={data.data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default Users;
