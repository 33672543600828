import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import RoutePage from './RoutePage';
import RoutePageDetails from './RoutePageDetails';

const RoutePageRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <RoutePage />
      </Route>

      <Route path={`${path}/:id`}>
        <RoutePageDetails />
      </Route>
    </Switch>
  );
};

export default RoutePageRoutes;
