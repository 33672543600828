import React from 'react';
import { Input, Tooltip } from 'antd';
import { useStorage } from 'src/hooks/useStorage';
import SearchOptions from './SearchOptions';

export const SearchInput = ({ setPage, setTerm, placeholder = 'Pretraga', tooltip="" }) => {
  const { storage } = useStorage();
  const [value, setValue] = React.useState(storage.get('search') || '');
  const current_page = parseInt(storage.get('page')) || 1;

  const handleSearch = () => {
    if (!value) return;
    setPage(1);
    setTerm(value);
    storage.set('search', value);
  };

  const clearInput = () => {
    if (!value) return;
    if (value) setPage(current_page);
    setValue('');
    setTerm('');
    storage.remove('search');
    storage.remove('subpage');
  };

  return (
    <div className="flex">
      <Tooltip title={tooltip} className="w-full" color="#666" >
        <Input
          placeholder={placeholder}
          onPressEnter={handleSearch}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </Tooltip>

      <SearchOptions onSearch={handleSearch} onClear={clearInput} />
    </div>
  );
};

{
  /*
  On search, set search term in sessionStorage, and set page to 1, 
  From useStorage hook, get&&remove items from storage,
  From usePagination hook, if search term exist, add subpage to sessionStorage
*/
}
