import React from 'react';
import { Polyline as MapPolyline } from '@react-google-maps/api';

const Polyline = ({ data = {} }) => {
  if (!Object.keys(data).length) return null;

  const points = data?.params.map((param) => ({
    lat: parseFloat(param.latitude),
    lng: parseFloat(param.longitude),
  }));

  return <MapPolyline path={[...points]} options={options} />;
};

const options = {
  strokeColor: '#FF0000',
  strokeWeight: 2,
  fillColor: '#FF0000',
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

export default Polyline;
