import React, { Component } from 'react'

export class TravleWarrantDkoPrint extends Component {
    render() {
        
        const {data} = this.props
        
        return (
            <div className="p-5 w-full">
            <style type="text/css" media="print">{"\
                @page {\ size: landscape;\ }\
                \ table {\ border-collapse: unset;\}\
            "}</style>
            <div className="text-2xl mb-3">Dokument o Kretanju Otapada</div>
            <table className="dko-table">
                <tbody>
                    <tr className="dko-table-title">
                        <td  colSpan="5">Део А - Подаци о отпаду (попуњава произвођач/власник отпада)</td>
                    </tr>
                    <tr>
                        <td >1.</td>
                        <td >Врста отпада</td>
                        <td  colSpan="3"></td>
                    </tr>
                    <tr >
                        <td >2.</td>
                        <td >Класификација отпада</td>
                        <td  >Индексни број:</td>
                        <td  colSpan="2">Припадност Q листи: </td>
                    </tr>
                    <tr >
                        <td >3.</td>
                        <td >Маса отпада</td>
                        <td  colSpan="3"></td>
                    </tr>
                    <tr >
                        <td >4.</td>
                        <td >Начин паковања отпада</td>
                        <td  colSpan="3"></td>
                    </tr>
                    <tr >
                        <td >5.</td>
                        <td >Извештај о испитивању отпада</td>
                        <td >Број и датум издавања:</td>
                        <td  colSpan="2"></td>
                    </tr>
                    
                
                </tbody>
                
            </table>
            
            <table className="dko-table">
                <tbody>
                        <tr className="dko-table-title">
                            <td  colSpan="5">Део Б - Произвођач/власник отпада</td>
                        </tr>

                        <tr >
                            <td >1.</td>
                            <td >Назив произвођача/власника</td>
                            <td  colSpan="3">{data ? data.legal_entity_name && data.legal_entity_name : ""}</td>
                        </tr>

                        <tr >
                            <td  rowSpan="7">2.</td>
                            <td  rowSpan="7">Адреса произвођача/власника</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Општина</td>
                            <td  colSpan="2">{data ? data.legal_entity_municipality && data.legal_entity_municipality : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Место</td>
                            <td  colSpan="2">{data ? data.legal_entity_city && data.legal_entity_city : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Поштански број</td>
                            <td  colSpan="2">{data ? data.legal_entity_zip_code && data.legal_entity_zip_code : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Улица и број</td>
                            <td  colSpan="2">{data ? data.legal_entity_address && data.legal_entity_address : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Телефон</td>
                            <td  colSpan="2">{data ? data.legal_entity_phone && data.legal_entity_phone : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Телефакс</td>
                            <td  colSpan="2">{data ? data.legal_entity_fax && data.legal_entity_fax : ""}</td>
                        </tr>
                        <tr >
                            <td  rowSpan="6">3.</td>
                            <td  rowSpan="6">Произвођач/власник отпада (означити)</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Произвођач</td>
                            <td  colSpan="2"><input className="mx-auto block" type="checkbox" /></td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Власник</td>
                            <td  colSpan="2"><input className="mx-auto block" type="checkbox" /></td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Оператер постројења за управљање отпадом </td>
                            <td  colSpan="2"><input className="mx-auto block" type="checkbox" /></td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Дозвола Број и датум издавања:</td>
                            <td  colSpan="2"></td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Дозвола Број и датум издавања:</td>
                            <td >
                                <div className="flex flex-col">
                                    <div>Операција</div>
                                    <div className="flex items-center border-t border-gray-200">
                                        Р
                                        <input className="mx-auto block" type="checkbox" />
                                    </div>
                                </div>
                            </td>
                            <td >
                                <div className="flex flex-col">
                                    <div>Операција</div>
                                    <div className="flex items-center border-t border-gray-200">
                                        Д
                                        <input className="mx-auto block" type="checkbox" />
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr >
                            <td  rowSpan="2">4.</td>
                            <td >Датум предаје отпада</td>
                            <td  colSpan="3"></td>
                        </tr>
                        <tr>
                            <td >Потпис</td>
                            <td  colSpan="3"></td>
                        </tr>
                    </tbody>
                </table>
                <div className="dko-table-page-break"></div>
                <table className="dko-table">
                    <tbody>
                        <tr className="dko-table-title">
                            <td colSpan="5">Део Ц - Транспорт отпада</td>
                        </tr>

                        <tr>
                            <td >1.</td>
                            <td >Назив превозника отпада</td>
                            <td  colSpan="3">{data ? data.company_name && data.company_name : ""}</td>
                        </tr>

                        <tr>
                            <td  rowSpan="7">2.</td>
                            <td  rowSpan="7">Адреса превозника отпада </td>
                        </tr>

                        <tr >
                            <td className="hidden"></td>
                            <td >Општина</td>
                            <td  colSpan="2">{data ? data.company_municipality && data.company_municipality : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Место</td>
                            <td  colSpan="2">{data ? data.company_city && data.company_city : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Поштански број</td>
                            <td  colSpan="2">{data ? data.company_zip_code && data.company_zip_code : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Улица и број</td>
                            <td  colSpan="2">{data ? data.company_address && data.company_address : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Телефон</td>
                            <td  colSpan="2">{data ? data.company_phone && data.company_phone : ""}</td>
                        </tr>
                        <tr >
                            <td className="hidden"></td>
                            <td >Телефакс</td>
                            <td  colSpan="2">{data ? data.company_fax && data.company_fax : ""}</td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Начин транспорта</td>
                            <td colSpan="3">{data ? data.travel_warrant_transport_type && data.travel_warrant_transport_type : ""}</td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>Релација</td>
                            <td colSpan="3">{data ? data.travel_warrant_relation && data.travel_warrant_relation : ""}</td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>Рута кретања</td>
                            <td colSpan="3">{data ? data.travel_warrant_route && data.travel_warrant_route : ""}</td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Дозвола за превознике отпада</td>
                            <td>Број и датум издавања:</td>
                            <td colSpan="2">{data ? data.waste_licence && data.waste_licence : ""}</td>
                        </tr>
                        <tr>
                            <td rowSpan="5">7.</td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Датум пријема отпада</td>
                            <td colSpan="3">{data ? data.waste_takeover && data.waste_takeover : ""}</td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Потпис</td>
                            <td colSpan="3"></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Датум предаје отпада</td>
                            <td colSpan="3">{data ? data.waste_delivery && data.waste_delivery : ""}</td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Потпис</td>
                            <td colSpan="3"></td>
                        </tr>
                    </tbody>
                </table>

                <table className="dko-table">
                    <tbody>
                        <tr className="dko-table-title">
                            <td colSpan="5">Део Д - Прималац отпада</td>
                        </tr>

                        <tr>
                            <td>1.</td>
                            <td>Назив примаоца</td>
                            <td colSpan="3"></td>
                        </tr>
                        <tr>
                            <td rowSpan="7">2.</td>
                            <td rowSpan="7">Адреса примаоца</td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Општина</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Место</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Поштански број</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Улица и број</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Телефон</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Телефакс</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Прималац (означити)</td>
                            <td colSpan="3"><input className="mx-auto block" type="checkbox" /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3">4.</td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Датум предаје отпада</td>
                            <td colSpan="3"></td>
                        </tr>
                        <tr>
                            <td className="hidden"></td>
                            <td>Потпис</td>
                            <td colSpan="3"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TravleWarrantDkoPrint
