import React from 'react';
import Select from 'react-select';

// https://react-select.com/
// https://www.npmjs.com/package/react-select

const style = {
  control: (base, state) => ({
    ...base,
    minHeight: 31,
    height: 31,
    boxShadow: state.isFocused ? '0 0 0 2px rgb(24 144 255 / 20%)' : 0,
    borderColor: '#d9d9d9',
    padding: 0,
    borderRadius: 2,
    width: 110,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: () => ({ padding: 0, margin: 0 }),
  valueContainer: (base) => ({
    ...base,
    position: 'static',
    height: '100%',
  }),
  dropdownIndicator: () => ({
    display: 'flex',
    color: '#ccc',
    padding: '0 5px',
  }),
};

const DropdownSelect = ({ name, onChange, value, options, ...props }) => {
  return (
    <Select
      name={name}
      onChange={onChange}
      value={value}
      styles={style}
      options={options}
      {...props}></Select>
  );
};

export default DropdownSelect;
