import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LegalEntities from './LegalEntities';
import LegalEntityDetails from './LegalEntityDetails';

function LegalEntitiesRoutes() {
    const { path } = useRouteMatch();
    

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <LegalEntities />
            </Route>

            <Route exact path={`${path}/:id`}>
                <LegalEntityDetails />
            </Route>
        </Switch>
    )
}

export default LegalEntitiesRoutes
