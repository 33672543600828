import React from 'react';
import { useQuery } from 'react-query';
import { Table } from 'antd';
import { ListHeading } from 'src/components/ListHeading';
import Loader from 'src/utils/Loader';
import { Link } from 'react-router-dom';
import { options } from 'src/utils/tableConfig';
import { getLogs } from '../api';
import usePagination from 'src/hooks/usePagination';
import { SearchOutlined } from '@ant-design/icons';
import List from '../components/List';
import moment from 'moment';
import SearchLogs from '../components/SearchLogs';
import ActionStatus from '../components/ActionStatus';

const columns = [
  {
    title: 'Ime i prezime',
    render: ({ user }) => (user ? user.name : ''),
    width: '20%',
  },
  {
    title: 'Akcija',
    render: (item) => {
      return (
        <ul>
          <li className="mb-1">{item.note}</li>
          <li className="mb-1">
            {moment(item.created_at).format('DD-MM-YYYY HH:mm')}
          </li>
          <li className="mb-1">
            <ActionStatus action={item.action} />
          </li>
        </ul>
      );
    },
  },
  {
    title: 'Vrednosti',
    render: (item) => {
      const values = JSON.parse(item.log_values);
      return <List values={values} />;
    },
  },
  {
    title: '',
    render: (entry) => (
      <Link
        className="align-middle inline-block p-1 text-gray-500"
        to={`/logs/${entry.id}`}>
        <SearchOutlined />
      </Link>
    ),
    width: 50,
  },
];

const Logs = () => {
  const {
    page,
    setPage,
    term,
    setTerm,
    paginate,
    getPaginationProps,
  } = usePagination();

  const listParams = { page, paginate, ...term };

  const { data, isLoading } = useQuery(
    ['logs', listParams],
    () => getLogs(listParams),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );

  const listHeadingClasses = {
    main: "flex flex-col mb-2 lg:flex-row lg:justify-between lg:items-center",
    leftContainer: "flex mb-2 lg:mb-0 flex-col lg:flex-row lg:items-center w-full",
    right: "flex justify-center lg:justify-end w-full lg:w-7/12  ",
    left: "mt-3 lg:ml-4 lg:mt-0 w-full",
    title: "text-2xl min-w-max"
  }

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        title="Istorija akcija"
        classNames={listHeadingClasses}
        left={
          <>
            <SearchLogs setPage={setPage} setTerm={setTerm} />
          </>
        }
      />

      <Table
        {...options}
        columns={columns}
        dataSource={data.data}
        pagination={getPaginationProps({ total: data.total })}
      />
    </>
  );
};

export default Logs;
