import { Empty, Spin } from 'antd'
import React from 'react'
import Loader from 'src/utils/Loader'

function NotFoundLegalEntitiesSelect({loading}) {
    console.log(loading)
    if (loading) return (
        <div className="flex flex-col justify-center items-center w-full p-5"> 
            <Spin size="large"/>
            <div>Učitavanje</div>
        </div>
    )
    return <Empty />
}

export default NotFoundLegalEntitiesSelect
