import { Button, Input as InputGroup, Col, Row } from 'antd';
import {FaPlusCircle, FaMinusCircle} from 'react-icons/fa';
import React, {useRef} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Form, Input, FormDrawer } from 'src/components/Form';
import { queryClient } from 'src/lib/react-query';
import { updateTravelWarrant } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import { SelectElement as Select } from 'src/components/Form/Select';
import { useTravelWarrantFormData } from '../hooks/useTravelWarrantFormData';
import {
  errorHandler,
  requiredFieldIcon,
  requiredFieldInfo,
} from 'src/utils/Error';

const UpdateTravelWarrantForm = ({ travelWarrant, showModal }) => {
  const submitRef = useRef(null);
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    updateTravelWarrant,
  );

  let formMethods = useForm({
    shouldUnregister: false,
    defaultValues: {
      ...travelWarrant,
    }
  });

  const [liquidInputGroups, setLiquidInputGroups] = React.useState(1);

  React.useEffect(() => {
    const { qr_code, ...data } = travelWarrant;
    formMethods.reset({
      ...data,
      crew_ids: travelWarrant.crew.map((e) => e.id),
    });
    setLiquidInputGroups(travelWarrant?.vehicle_liquids.length)
  }, [travelWarrant]);

  const handleSubmit = async (data) => {
    try {
      await mutateAsync({ data, id: travelWarrant.id });
      queryClient.invalidateQueries('travel_warrants');
      queryClient.invalidateQueries('travel_warrant');
      formMethods.reset(travelWarrant);
      openNotificationWithIcon('success');
    } catch (error) {
      errorHandler(error);
    }
  };

  const formName = 'updateTravelWarrant';

  const {
    employeesOptions,
    crewOptions,
    vehiclesOptions,
    routesOptions,
    typeOptions,
    oilOptions,
    municipalityOptions,
    wasteProducersOptions,
    wasteTypesOptions
  } = useTravelWarrantFormData(formMethods);

  return (
    <FormDrawer
      isDone={isSuccess}
      width="90%"
      showModal={showModal}
      triggerButton={<Button className="text-white bg-blue-500 xs:w-2/6 sm:w-2/6 md:w-auto order-3 md:order-4">Izmeni</Button>}
      submitButton={
        <Button
          ref={submitRef}
          form={formName}
          key="submit"
          htmlType="submit"
          loading={isLoading}
          className="text-white bg-blue-500">
          Izmeni
        </Button>
      }
      additionalButton={<Button className="text-white bg-blue-500" 
                                onClick={() => {
                                  formMethods.setValue('status', 'closed');
                                  submitRef.current.click();
                                }}>Zaključi</Button>}
      title="Izmeni podatke">
      <Form id={formName} formMethods={formMethods} onSubmit={handleSubmit}>
        {requiredFieldInfo}

        <input name="status" style={{display: 'none'}}/>

        <InputGroup.Group className="mb-5">
          <Row gutter={[12, 8]}>
            <Col xl={5} lg={5} md={12} sm={12} xs={24}>
            <div className="dropdown-select-wrapper">
              <Select
                name="employee_id"
                label={<>{requiredFieldIcon} Vozač - <span className="text-xs">Ime ili obracunski broj</span></>}
                options={employeesOptions}
                labelInValue
                className="max-w-full"
                selectDisabled={true}
                rules={{
                  required: {
                    value: true,
                    message: 'Obavezno polje',
                  },
                }}
              />
            </div>
            </Col>

            <Col xl={5} lg={5} md={12} sm={12} xs={24}>
              <div className="dropdown-select-wrapper">
              <Select
                name="vehicle_id"
                label={<>{requiredFieldIcon} Vozilo - <span className="text-xs">Registarski ili garazni broj</span></>}
                options={vehiclesOptions}
                labelInValue
                selectDisabled={true}
                rules={{
                  required: {
                    value: true,
                    message: 'Obavezno polje',
                  },
                }}
              />
              </div>
            </Col>

            <Col xl={10} lg={10} md={20} sm={20} xs={24}>
            <div className="dropdown-select-wrapper">
              <Select
                name="route_id"
                label={<>{requiredFieldIcon} Relacija</>}
                options={routesOptions}
                labelInValue
                selectDisabled={true}
                rules={{
                  required: {
                    value: true,
                    message: 'Obavezno polje',
                  },
                }}
              />
            </div>
            </Col>
            
            <Col xl={4} lg={4} md={4} sm={4} xs={10}>
              <Input name="block_number" label="Broj bloka" disabled={true} />
            </Col>

          </Row>
        </InputGroup.Group>
          <InputGroup.Group>
              <Row gutter={[4,8]} className="mb-8">
                <Col  xl={8} lg={8} md={12} sm={12} xs={24}>
                  <div className="dropdown-select-wrapper">
                    <Select 
                      id="municipalities"
                      name="municipality_id"
                      label={<>{requiredFieldIcon} Opština</>}
                      options={municipalityOptions}
                      selectDisabled={true}
                      rules={{
                        required: {
                          value: true,
                          message: 'Obavezno polje',
                        },
                      }}
                    /> 
                  </div>
                </Col>

                <Col  xl={8} lg={8} md={12} sm={12} xs={24} >
                  <div className="dropdown-select-wrapper">
                      <Select 
                        id="wasteProducers"
                        name="waste_producer_id"
                        label={<>{requiredFieldIcon} Proizvođač otpada</>}
                        options={wasteProducersOptions}
                        selectDisabled={true}
                        rules={{
                          required: {
                            value: true,
                            message: 'Obavezno polje',
                          },
                        }}
                      />
                  </div>
                </Col>

                <Col xl={8} lg={8} md={24} sm={24} xs={24} >
                  <div className="dropdown-select-wrapper">
                      <Select 
                        id="wasteTypes"
                        name="waste_type_id"
                        label={<>{requiredFieldIcon} Tip otpada</>}
                        options={wasteTypesOptions}
                        selectDisabled={true}
                        rules={{
                          required: {
                            value: true,
                            message: 'Obavezno polje',
                          },
                        }}
                      />
                  </div>
                </Col>
              </Row>
          </InputGroup.Group>
        {/* --------------------- */}

        <InputGroup.Group>
          <Row gutter={[12,4]}>
            <Col xl={10} lg={10} md={19} sm={19} xs={24}>
              <Select
                name="crew_ids"
                label="Ostali članovi postave"
                options={crewOptions}
                selectDisabled={true}
                labelInValue
                mode="multiple"
              />
            </Col>

            <Col xl={4} lg={4} md={5} sm={5} xs={10}>
              <Select
                name="type"
                label={<>{requiredFieldIcon} Tip</>}
                options={typeOptions}
                selectDisabled={true}
                labelInValue
                rules={{
                  required: {
                    value: true,
                    message: 'Obavezno polje',
                  },
                }}
                allowClear={false}
              />
            </Col>

            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <Input name="transportation_type" label="Tip prevoza" disabled={true} />
            </Col>
        
          </Row>
        </InputGroup.Group>

        {/* --------------------- */}

        <InputGroup.Group>
          <Row gutter={[12,4]} align="bottom">
          
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="start_mileage" label="Kilometraža početno stanje" />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="end_mileage" label="Kilomteraža završno stanje" />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="start_operating_hours" label="Radni sati početno stanje" />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="end_operating_hours" label="Radni sati završno stanje" />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="start_upgrade_hours" label="Radni sati nadogradnje početno stanje" />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="end_upgrade_hours" label="Radni sati nadogradnje završno stanje" />
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                <Input
                  name="responsible"
                  label={<>{requiredFieldIcon} Odgovoran</>}
                  disabled={true}
                  rules={{
                    required: {
                      value: true,
                      message: 'Obavezno polje',
                    },
                  }}
                />
            </Col>
          </Row>
        </InputGroup.Group>

        <p className="text-base font-medium py-3 border-b mb-3">
          Podaci vozila
        </p>

        <InputGroup.Group className="mb-5">
          <Row gutter={[12,4]}>
            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="unique_code" label="Šifra" disabled={true}/>
            </Col>

            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="licence_plate" label="Registarski broj" disabled={true}/>
            </Col>

            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="transportation_type" label="Tip prevoza" disabled={true}/>
            </Col>

            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="office" label="Pogon" disabled={true}/>
            </Col>

            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="connected_dimensions" label="Vrednost dimenzije" disabled={true}/>
            </Col>

            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
              <Input name="load_capacity" label="Nosivost" disabled={true}/>
            </Col>
          </Row>
        </InputGroup.Group>

        <p className="text-base font-medium py-3 border-b mb-3">
          Usute tečnosti
        </p>

        <FaPlusCircle onClick={() => {
            setLiquidInputGroups(prevState => prevState + 1);
            const prevIds = formMethods.watch('liquid_id');
            const newIds = prevIds.push('');
            formMethods.setValue(`liquid_id`, newIds);
          }} style={{marginRight: '20px', display: liquidInputGroups === 0 ? 'block' : 'none'}}
        />

        {Array.apply(null, {length: liquidInputGroups}).map((item, index) => {
          const types = formMethods.watch(`liquid_type`);
          let disabled = [];

          oilOptions.map(option => {
            if (types.includes(option.value)) disabled.push(option.value);
          });

          return (
            <InputGroup.Group className="mb-5" key={`liquidInputGroups-${index}`}>
              <Row gutter={12} align="middle">
                <Col xl={4} lg={10} md={10} sm={10} xs={12}>
                  <Select
                    name={`liquid_type[${index}]`}
                    label={<>Tip</>}
                    options={oilOptions}
                    allowClear={false}
                    disabled={disabled}
                    labelInValue
                  />
                </Col>

                <Col xl={4} lg={10} md={10} sm={10} xs={12}>
                  <Input name={`quantity[${index}]`} 
                          label="Količina" 
                          rules={{
                          required: {
                            value: formMethods.watch(`liquid_type_${index}`) !== undefined && formMethods.watch(`liquid_type_${index}`) !== '',
                            message: "Obavezno polje"
                          }
                          }}/>
                </Col>
                <Input name={`liquid_id[${index}]`} 
                        style={{display: 'none'}}/>

                
                  <Col xl={4} lg={4} md={4} sm={4} xs={24} className="Oil flex justify-center sm:justify-start" style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "20px"
                  }}>
                    <FaPlusCircle onClick={() => {
                      const type = formMethods.watch(`liquid_type`)[index];
                      const prevIds = formMethods.watch(`liquid_id`);

                      let types = formMethods.watch('liquid_type');
                      types[index + 1] = "";

                      let quantity = formMethods.watch('quantity');
                      quantity[index + 1] = "";

                      if(type) {
                        const newIds = prevIds.push('');
                        setTimeout(() => {
                          formMethods.setValue(`liquid_id`, newIds);
                          formMethods.setValue(`liquid_type`, types);
                          formMethods.setValue(`quantity`, quantity);
                        }, 0)
                        setLiquidInputGroups(prevState => prevState + 1);
                      }
                    }} style={{marginRight: '20px',
                              display: index === liquidInputGroups - 1 && liquidInputGroups < 4 ? 'block' : 'none'}}/>
                    <FaMinusCircle onClick={async() => {
                      const prevIds = [...formMethods.watch('liquid_id')];
                      const newIds = [...prevIds].filter(id => id !== prevIds[index]);

                      const prevTypes = formMethods.watch('liquid_type');
                      const newTypes = [...prevTypes].filter(type => type !== prevTypes[index]);

                      const prevQuantity = formMethods.watch('quantity');
                      const newQuantity = [...prevQuantity].filter(quantity => quantity !== prevQuantity[index]);

                      setTimeout(() => {
                        formMethods.setValue('liquid_id', newIds);
                        formMethods.setValue(`liquid_type`, newTypes);
                        formMethods.setValue(`quantity`, newQuantity);
                        
                      }, 0);
                      setLiquidInputGroups(prevState => prevState - 1);
                      
                    }} />
                  </Col>
              </Row>
            </InputGroup.Group>
          );
        })}
      </Form>
    </FormDrawer>
  );
};

export default UpdateTravelWarrantForm;
