import React from 'react';
import { FormProvider } from 'react-hook-form';

export const Form = ({ formMethods, onSubmit, children, ...props }) => {
  return (
    <FormProvider {...formMethods}>
      <form
        className="w-full"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
