const tableHeight = window.innerWidth < 1600 ? 480 : 800;

export const options = {
  size: 'small',
  rowClassName: 'leading-4',
  scroll: { x: 1200, y: tableHeight },
  rowKey: 'id',
  locale: {
    emptyText: 'Tabela je prazna',
  },
};
