import React, {useState} from 'react';
import { ListHeading } from 'src/components/ListHeading';
import UpdateUserAccountForm from '../components/UpdateUserAccountForm';
import { useAuth } from 'src/lib/auth';
import Table from 'src/utils/Table';

const columns = [
  { title: 'Ime', dataIndex: 'name', width: '25%' },
  { title: 'Email', dataIndex: 'email' },
];


const UserAccount = () => {
  const { user } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(true);


  return (
    <>
      <ListHeading
        title={user?.name}
        right={<UpdateUserAccountForm user={user} showModal={isModalVisible} />}
      />

      <Table columns={columns} data={user} />
    </>
  );
};

export default UserAccount;
