import React from 'react';

const style = {
  fontWeight: '600',
  display: 'inline-block',
  marginRight: 5,
};

const List = (props) => {
  return (
    <ul>
      {Object.entries(props.values).map(([key, val]) => {
        return (
          <li key={key} className="pt-0.5 hover:bg-gray-100">
            <span style={style}>{key}:</span>
            {val ?? '/'}
          </li>
        );
      })}
    </ul>
  );
};

List.defaultProps = {
  values: {},
};

export default List;
