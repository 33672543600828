import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Notifications from './Notifications';
import NotificationDetails from './NotificationDetails';

const NotificationsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Notifications />
      </Route>

      <Route path={`${path}/:id`}>
        <NotificationDetails />
      </Route>
    </Switch>
  );
};

export default NotificationsRoutes;
