import React from 'react';
import { Row, Col, Input, Tooltip } from 'antd';
import SearchOptions from 'src/utils/SearchOptions';
import { useStorage } from 'src/hooks/useStorage';
import DropdownSelect from 'src/lib/DropdownSelect';

const sections_options = [
  { value: '', label: 'Sve' },
  { value: 'travel_warrant', label: 'Putni nalog' },
  { value: 'vehicle', label: 'Vozila' },
  { value: 'employee', label: 'Vozač' },
  { value: 'route', label: 'Relacija' },
  { value: 'point', label: 'Punkt' },
  { value: 'user', label: 'Korisnik' },
];

const SearchLogs = ({ setTerm, setPage }) => {
  const { storage, searchTermExist, logsTerm, initialTerm } = useStorage();
  const current_page = parseInt(storage.get('page')) || 1;
  const [data, setData] = React.useState(initialTerm);

  const checkIfEmpty = (data) => {
    let val = true;
    Object.values(data).map((item) => {
      if (item) val = false;
    });
    return val;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkIfEmpty(data)) return;
    setPage(1);
    setTerm({ ...data });
    storage.set('search', JSON.stringify({ ...data }));
  };

  const handleChange = (obj_1, obj_2) => {
    let name;
    let value;

    if (obj_1.hasOwnProperty('target')) {
      name = obj_1.target.name;
      value = obj_1.target.value;
    } else {
      name = obj_2.name;
      value = obj_1.value;
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const clearInputs = () => {
    if (!searchTermExist) return;
    setTerm(logsTerm);
    setData(logsTerm);
    setPage(current_page);
    storage.remove('search');
  };

  const formName = 'logsSearch';

  return (
    <form id={formName} onSubmit={handleSubmit}>
      {/* reqular form insted of antd form due to double requests on page 2 */}
      <Row gutter={[8,4]}>

        <Col  md={7} sm={12} xs={24}>
          <Tooltip title="Ime i prezime" className="w-full" color="#666" >
            <Input
              name="name"
              onChange={handleChange}
              placeholder="Unesite ime i prezime"
              value={data.name || ''}
            />
          </Tooltip>
        </Col>

        <Col md={7} sm={12} xs={24}>
        <Tooltip title="ID" className="w-full" color="#666" >
          <Input
            name="loggable_id"
            onChange={handleChange}
            placeholder="Unesite ID"
            value={data.loggable_id || ''}
          />
        </Tooltip>
        </Col>

        <Col md={7} sm={20} xs={24}>
        <Tooltip title="Tip" className="w-full" color="#666" >
        <div className="dropdown-select-wrapper">
          <DropdownSelect
            name="loggable_type"
            onChange={handleChange}
            value={sections_options.filter((option) => {
              return option.value === data.loggable_type;
            })}
            options={sections_options}
            title
          />
        </div>
        </Tooltip>
        </Col>

        <Col md={3} sm={4} xs={24} className="flex justify-center sm:justify-end md:justify-start">
          <SearchOptions formName={formName} onClear={clearInputs} />
        </Col>
      </Row>
    </form>
  );
};

export default SearchLogs;
