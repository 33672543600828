import React from 'react';
import { useMutation } from 'react-query';
import { queryClient } from 'src/lib/react-query';
import { updateControllerTravelWarrant } from '../api';
import openNotificationWithIcon from 'src/utils/notification';
import { Tag, Select } from 'antd';
import { useAuth } from 'src/lib/auth';
import { errorHandler } from 'src/utils/Error';

const { Option } = Select;
const statusStyle = 'text-sm';

export const useTravelWarrantsUsers = () => {
  const { user } = useAuth();

  const { mutateAsync, isLoading: isStatusLoading } = useMutation((data) =>
    updateControllerTravelWarrant(data.id, { status: data.status }),
  );
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [data, setData] = React.useState({});
  const status = data.status;

  const handleChange = (warrant) => {
    setData(warrant);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries('travel_warrants');
      queryClient.invalidateQueries('travel_warrant');
      openNotificationWithIcon('success');
      setIsModalVisible(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleStatusSelect = (id) => {
    return (
      <Select
        name="status"
        onChange={(val, options) => handleChange({ id, status: val })}
        placeholder="Izmeni status"
        size="small"
        style={{ width: 120 }}>
        <Option value="approved" className="approved">
          Odobren
        </Option>
        <Option value="disapproved" className="disapproved">
          Odbijen
        </Option>
      </Select>
    );
  };

  const getStatus = (data) => {
    let status;
    switch (data) {
      case 'pending':
        status = (
          <Tag className={`${statusStyle} border border-gray-300`}>Nov</Tag>
        );
        break;
      case 'approved':
        status = <Tag className={`${statusStyle} bg-green-300`}>Odobren</Tag>;
        break;
      case 'disapproved':
        status = <Tag className={`${statusStyle} bg-red-300`}>Odbijen</Tag>;
        break;
      case 'canceled':
        status = <Tag className={`${statusStyle} bg-yellow-300`}>Poništen</Tag>;
        break;
      case 'in_progress':
        status = <Tag className={`${statusStyle} bg-blue-300`}>Aktivan</Tag>;
        break;
      case 'finished':
        status = <Tag className={`${statusStyle} bg-gray-300`}>Završen</Tag>;
        break;
      default:
        status = '';
    }
    return status;
  };

  const getType = (data) => {
    let type =
      data === 'regular' ? (
        <Tag className={`${statusStyle} border border-gray-300`}>Redovan</Tag>
      ) : (
        <Tag className={`${statusStyle} bg-red-300`}>Vanredan</Tag>
      );
    return type;
  };

  const showActions = (name, status) => {
    if (name.includes(status)) {
      return false;
    }
    return true;
  };

  const getDataByUserRole = (
    params,
    adminEndpoint,
    controllerEndpoint,
    serviceEndpoint,
  ) => {
    let endpoint = '';
    if (user?.role === 'admin_jkp') {
      endpoint = adminEndpoint(params);
    } else if (user?.role === 'controller') {
      endpoint = controllerEndpoint(params);
    } else if (user?.role === 'service') {
      endpoint = serviceEndpoint(params);
    }

    return endpoint;
  };

  return {
    handleStatusSelect,
    isModalVisible,
    isStatusLoading,
    handleCancel,
    handleOk,
    getStatus,
    getType,
    getDataByUserRole,
    status,
    showActions,
  };
};
