import React from 'react';
import { useQuery } from 'react-query';
import { getLog } from '../api';
import { useParams } from 'react-router-dom';
import Loader from 'src/utils/Loader';
import moment from 'moment';
import { Card } from 'antd';
import List from '../components/List';
import ActionStatus from '../components/ActionStatus';

const LogDetails = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(['log', id], () => getLog(id), {
    select: (res) => res.data,
  });

  if (isLoading) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  const values = JSON.parse(data?.log_values);

  return (
    <Card
      title={
        <>
          <p className="break-words w-full">{data.note}</p>
          <p className="break-words w-full">{moment(data.created_at).format('DD-MM-YYYY HH:mm')}</p>
          <ActionStatus action={data.action} />
        </>
      }>
      <List values={values} />
    </Card>
  );
};

export default LogDetails;
