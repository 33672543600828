import React from 'react';
import openNotificationWithIcon from './notification';

export const errorClassNames =
  'border-red-500 hover:border-red-500 focus:border-red-500 focus:shadow-none';

export const requiredFieldIcon = <i className="text-red-500 font-bold">*</i>;

export const requiredFieldInfo = (
  <p className="font-semibold text-red-500 text-right text-sm">
    * Obavezna polja
  </p>
);

const convertWords = {
  'employee code': 'Obračunski broj',
  'unique code': 'Šifra',
  'licence plate': 'Registarski broj',
};

export const errorHandler = (error, setError, { time = 6 } = {}) => {
  const res = error?.response?.data?.errors;
  let label = res
    ? Object.entries(res).map((r) => {
        let text = '';
        text = r[1]
          .toString()
          .replace(
            /(employee code)|(unique code)|(licence plate)/gi,
            (str) => convertWords[str],
          );

        return text;
      })
    : JSON.stringify(error?.response?.data?.message);

  if (res && setError) setError(Object.keys(res));

  if (error?.response?.status == 403)
    label = 'Niste ovlašćeni za pristup ovoj stranici.';

  openNotificationWithIcon('error', {
    message: `Došlo je do greške - ${label}`,
    duration: time,
  });
};

export const Error = () => {
  return (
    <div className="h-screen w-screen bg-gray-50">
      <div className="pt-20 px-3 max-w-sm m-auto">
        <div className="bg-white border border-gray-50 p-5 shadow-xl">
          <h3 className="pb-2 border-b border-gray-100 font-medium text-lg">
            Došlo je do greške!
          </h3>

          <div className="text-center">
            <a
              href="/"
              className="inline-block bg-blue-500 hover:bg-blue-400 text-white hover:text-white py-2 px-4 mt-5 rounded">
              Nazad na početnu stranu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
