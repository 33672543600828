import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import TravelWarrants from './TravelWarrants';
import TravelWarrantDetails from './TravelWarrantDetails';

const TravelWarrantsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <TravelWarrants />
      </Route>

      <Route path={`${path}/:id`}>
        <TravelWarrantDetails />
      </Route>

    </Switch>
  );
};

export default TravelWarrantsRoutes;
